import { getFutureDateTime } from '@root/utils/dateUtilize';

const setToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token));
  localStorage.setItem(
    'tokenExpires',
    JSON.stringify({
      identify: token,
      expired: getFutureDateTime(12, 'hours'),
    }),
  );
};

const removeToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpires');
};

const getToken = () => {
  try {
    return JSON.parse(localStorage.getItem('token'));
  } catch (err) {
    removeToken();
    return null;
  }
};

const getTokenExpires = () => {
  try {
    const currentToken = getToken();
    const tokenExpires = JSON.parse(localStorage.getItem('tokenExpires'));
    if (!tokenExpires || !currentToken) return null;
    if (tokenExpires.identify !== currentToken) return null;

    return tokenExpires.expired;
  } catch (err) {
    return null;
  }
};

export { setToken, removeToken, getToken, getTokenExpires };
