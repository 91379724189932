import { ConfigProvider } from 'antd';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import moment from 'moment-timezone';
import { Authenticator } from '@aws-amplify/ui-react';
import RootRouter from './router';
import './App.less';

moment.tz.setDefault('Asia/Bangkok');

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Authenticator.Provider>
        <ConfigProvider>
          <Toaster />
          <RootRouter />
        </ConfigProvider>
      </Authenticator.Provider>
    </QueryClientProvider>
  );
}

export default App;
