export const defaultEdgeApplicationFilters = {
  customerName: '',
  edgeId: '',
  solutions: [],
  onlineStatus: '',
  deviceStatus: [],
  statusMaPlan: [],
  customerReference: '',
  note: '',
  page: 1,
  pageSize: 10,
};

export const exportSolarHeaders = [
  { key: 'category', label: 'Category' },
  { key: 'time', label: 'Time' },
  { key: 'kW', label: 'Value' },
];

export const exportAAQHeaders = [
  { key: 'data_type', label: 'Data Type' },
  { key: 'category', label: 'Category' },
  { key: 'time', label: 'Time' },
  { key: 'value', label: 'Value' },
];

export const RAW_CSV_AAQ_HEADERS = [
  { key: 'edge_id', label: 'Edge ID' },
  { key: 'timestamp', label: 'Date' },
  { key: 'device_id', label: 'Device ID' },
  { key: 'type', label: 'Type' },
  { key: 'device_key', label: 'Device key' },
  { key: 'value', label: 'Value' },
  { key: 'unit', label: 'Unit' },
];

export const exportAAFHeaders = [
  { key: 'data_type', label: 'Data Type' },
  { key: 'category', label: 'Category' },
  { key: 'time', label: 'Time' },
  { key: 'value', label: 'Value' },
];
