import { Column } from '@ant-design/charts';
import { PAGE_REFRESHING_ATOM, TRINITY_DASHBOARD_ATOM } from '@root/atoms/dashboard.atom';
import { fetchEdgeHealth } from '@root/services/dashboard.service';
import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { configSensorChart } from '../../chartConfig';
import Styled from './style';

const Sensor = () => {
  const [refresh] = useAtom(PAGE_REFRESHING_ATOM);
  const [params] = useAtom(TRINITY_DASHBOARD_ATOM);
  const { data, isLoading, isFetching } = useQuery(
    ['edge-health', params, refresh.flag],
    () => fetchEdgeHealth(params),
    {
      refetchInterval: refresh.every,
    },
  );

  const sensorData = useMemo(() => {
    if (data) {
      return data?.data;
    }
  }, [data]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <Styled>
        <h4 style={{ textAlign: 'end' }}>Total: {sensorData?.total || 0}</h4>
        <h2>Sensor</h2>
        {sensorData && <Column animation={false} {...configSensorChart(sensorData?.chart)} />}
      </Styled>
    </Spin>
  );
};

export default Sensor;
