import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import { Button, Card, DatePicker, Row, Space } from 'antd';
import AirChart from '@root/components/organisms/charts/airChart';
import { AirInformationDataTypes, AirInformationTitleEnum, AirInformationUnitEnum } from '@services/edgeApplication';
import { FileTextOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { exportAAFHeaders, RAW_CSV_AAQ_HEADERS } from '@root/config/edgeApplicationConfig';
import { useQuery } from 'react-query';
import { fetchRawEdgeApplications } from '@root/services/edge-applications.service';

const dateFormat = 'YYYY-MM-DD';

const AirFlowChartGroup = ({ edgeBarcode }) => {
  const { dataActiveAirFlowStatistic: statistics, getActiveAirFlowStatistic } = useEdgeApplication();

  const [selectedDate, setSelectedDate] = useState(moment());

  const { data: rawData } = useQuery(
    ['raw-data-aaf', edgeBarcode, selectedDate],
    () =>
      fetchRawEdgeApplications({
        actuator: 'activeairflow',
        edge_id: edgeBarcode,
        from: moment(selectedDate).startOf('d').toISOString(),
        to: moment(selectedDate).endOf('d').toISOString(),
        location: 'indoor',
      }),
    {
      retry: false,
    },
  );

  const exportData = useMemo(() => {
    return Object.keys(statistics || []).reduce((prev, current) => {
      return [...prev, ...statistics[current].map((row) => ({ ...row, data_type: AirInformationTitleEnum[current] }))];
    }, []);
  }, [statistics]);

  useEffect(() => {
    if (!selectedDate) return;
    AirInformationDataTypes.forEach((type) => {
      getActiveAirFlowStatistic(edgeBarcode, {
        date: selectedDate.format(dateFormat),
        data_types: type,
      });
    });
  }, [selectedDate]);

  return (
    <Card>
      <Row justify="end" style={{ marginBottom: '15px' }}>
        <Space size={10}>
          <DatePicker value={selectedDate} onChange={setSelectedDate} allowClear={false} />
          <Button disabled={!exportData.find((row) => row.value)}>
            <CSVLink
              filename={`active-air-flow-chart-${selectedDate.format(dateFormat)}.csv`}
              data={exportData}
              headers={exportAAFHeaders}
            >
              <Space size={10}>
                <FileTextOutlined />
                Export
              </Space>
            </CSVLink>
          </Button>
          <Button disabled={!rawData?.data}>
            <CSVLink
              filename={`raw-active-air-flow-chart-${selectedDate.format(dateFormat)}.csv`}
              data={rawData?.data || []}
              headers={RAW_CSV_AAQ_HEADERS}
            >
              <Space size={10}>
                <FileTextOutlined />
                Export raw
              </Space>
            </CSVLink>
          </Button>
        </Space>
      </Row>
      <Row gutter={[16, 32]}>
        {AirInformationDataTypes.map((field, key) => (
          <div key={key} style={{ position: 'relative', display: 'block', width: '100%', marginBottom: 10 }}>
            <AirChart
              items={statistics?.[field]}
              title={AirInformationTitleEnum[field]}
              unit={AirInformationUnitEnum[field]}
            />
            <p style={{ fontWeight: 600, position: 'absolute', color: 'red', bottom: -35, left: 20 }}>
              {AirInformationUnitEnum[field]}
            </p>
          </div>
        ))}
      </Row>
    </Card>
  );
};

AirFlowChartGroup.propTypes = {
  edgeBarcode: PropTypes.string,
};

export default AirFlowChartGroup;
