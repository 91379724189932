import React from "react";
import ErrorCustomer from "./style";

const ErrorCustomerNcc = () => {
  return (
    <ErrorCustomer>
      <div className="wrapErrorCustomer">
        <img src="../icon-ncc-error.svg" />
        <h2>ไม่พบข้อมูลลูกค้า</h2>
        <p>กรุณาค้นหาข้อมูลลูกค้าใหม่อีกครั้งในหน้า Dashboard</p>
      </div>
    </ErrorCustomer>
  )
}

export default ErrorCustomerNcc;