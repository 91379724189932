import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import TextCardBody from './style';
import { Select } from 'antd';
import styled from 'styled-components';
import ModalAddressNcc from '../modalAddress';
import EditIcon from '../icon/edit';

const TextCardBodyNcc = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => setOpenModal(false);
  const handleShow = () => setOpenModal(true);

  const [selectedAddress, setSelectedAddress] = useState();

  const addresses = useMemo(
    () => props?.dataCard?.find((data) => data.title === 'ที่อยู่')?.address || null,
    [props?.dataCard],
  );

  useEffect(() => {
    if (!selectedAddress && addresses?.length) {
      setSelectedAddress(addresses[0]);
    }
  }, [selectedAddress, addresses]);

  const handleOnChangeAddress = (address) => {
    setSelectedAddress(address);
    props.onChangeAddress(address);
    props.refetchCustomer();
  };

  const handleOnChangeEdgeID = (edgeID) => {
    props.onChangeEdgeID(edgeID);
    props.refetchCustomer();
  };

  const hasSCGID = useMemo(() => {
    return props?.dataCard?.some((data) => {
      return data.title === 'SCG ID' && data.description === '-';
    });
  }, [props?.dataCard]);

  return (
    <TextCardBody>
      {props?.dataCard?.map((data, key) => {
        return (
          <div className="grid-data" key={key}>
            <h4>{data?.title}</h4>
            {data?.address ? (
              <div className="grid-address">
                {' '}
                {selectedAddress && (
                  <p>
                    {selectedAddress?.street_address} {selectedAddress?.road} {selectedAddress?.sub_district}{' '}
                    {selectedAddress?.district} {selectedAddress?.province} {selectedAddress?.postal_code}
                  </p>
                )}
                {data?.address?.length > 1 && (
                  <div className="wrapBtnAddress">
                    <button type="button" className="btnAddress" onClick={handleShow}>
                      <EditIcon />
                    </button>
                    <div className="tooptip">
                      <p>เปลี่ยนที่อยู่</p>
                    </div>
                  </div>
                )}
              </div>
            ) : data?.edgeId ? (
              data?.edgeId.length > 1 ? (
                <WrapSelect>
                  <Select onChange={handleOnChangeEdgeID} className="selectStyle" defaultValue={data?.edgeId[0]}>
                    {data?.edgeId.map((item, key) => {
                      return (
                        <Select.Option key={key} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </WrapSelect>
              ) : (
                data?.edgeId.map((item) => {
                  return <p key={item}>{item}</p>;
                })
              )
            ) : data?.monitorStatus ? (
              <p>{data?.monitorStatus}</p>
            ) : data?.solution ? (
              <p>{data?.solution}</p>
            ) : (
              <p>{data?.description}</p>
            )}
          </div>
        );
      })}
      {addresses?.length > 0 && (
        <ModalAddressNcc
          addresses={addresses}
          onShow={openModal}
          onHide={handleClose}
          onChangeAddress={handleOnChangeAddress}
        />
      )}
      {hasSCGID && (
        <div className="warning-no-scgid">
          <span className="warning-no-scgid-text">ลูกค้ายังไม่มีบัญชี SCG ID</span>
          <span className="warning-no-scgid-text">หลังจากลูกค้าสร้างบัญชี ​SCG ID สำเร็จ กรุณากด Refresh page</span>
        </div>
      )}
    </TextCardBody>
  );
};

export default TextCardBodyNcc;

const WrapSelect = styled.div`
  width: 312px;
  height: auto;
  .selectStyle {
    width: 100%;
    height: 40px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 40px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-top: 2px;
  }
`;
