import { PAGE_REFRESHING_ATOM, TRINITY_DASHBOARD_ATOM } from '@root/atoms/dashboard.atom';
import GoogleMapContainer from '@root/components/organisms/googleMapContainer';
import { fetchEdgeApplications } from '@root/services/dashboard.service';
import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const EdgeApplicationMap = () => {
  const [refresh] = useAtom(PAGE_REFRESHING_ATOM);
  const [params] = useAtom(TRINITY_DASHBOARD_ATOM);
  const [markers, setMarkers] = useState();

  const { data, isLoading, isFetching } = useQuery(
    ['edge-application', params, refresh.flag],
    () => fetchEdgeApplications(params),
    {
      refetchInterval: refresh.every,
    },
  );

  const getMarkersFromEdgeApplications = (edgeApplications) => {
    let markerItems = [];
    edgeApplications?.length &&
      edgeApplications?.map((item) => {
        item.addresses
          .filter((address) => address.latitude && address.longitude)
          .map((address) => {
            markerItems.push({
              name: [item.first_name, item.last_name].filter((x) => x).join(' '),
              lat: address.latitude,
              lng: address.longitude,
              status: item.solar_status,
            });
          });
      });
    return markerItems;
  };

  useEffect(() => {
    if (data?.data) {
      setMarkers(getMarkersFromEdgeApplications(data?.data));
      return;
    }
  }, [data?.data]);

  if (isLoading) {
    return (
      <Spin spinning={isFetching || isLoading}>
        <div style={{ width: 900 }}></div>
      </Spin>
    );
  }

  return (
    <div style={{ width: '100%', margin: '40px 0 40px 40px' }}>
      {markers && <GoogleMapContainer markers={markers} />}{' '}
    </div>
  );
};

export default EdgeApplicationMap;
