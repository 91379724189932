import { Line } from '@ant-design/charts';
import { fetchCheckinHistory } from '@root/services/edge-applications.service';
import { DatePicker } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const CheckInHistoryTab = () => {
  const { edge_barcode } = useParams();
  const [date, setDate] = useState(moment(new Date(), DEFAULT_DATE_FORMAT));

  const { data } = useQuery(
    ['checkin-histories', date],
    () => {
      return fetchCheckinHistory({
        edge_id: edge_barcode,
        from: moment(date).format(DEFAULT_DATE_FORMAT),
        to: moment(date).format(DEFAULT_DATE_FORMAT),
      });
    },
    {
      retry: false,
    },
  );

  const config = useMemo(
    () => ({
      data: data || [],
      padding: 'auto',
      xField: 'c',
      yField: 'online_status',
      smooth: true,
      seriesField: 'category',
      label: {
        style: {
          fill: '#aaa',
        },
      },

      xAxis: {
        tickCount: 24,
        label: {
          formatter: (time) => (time.length === 1 ? '0' + time + ':00' : time + ':00'),
        },
      },
      tooltip: {
        formatter: (datum) => ({
          name: 'Online status',
          value: datum?.online_status,
        }),
      },
    }),
    [data],
  );

  return (
    <div style={{ padding: 40 }}>
      <div style={{ display: 'flex', justifyContent: 'end', alignContent: 'center' }}>
        <DatePicker onChange={(e) => setDate(e)} defaultValue={date} format={DEFAULT_DATE_FORMAT} label="Period" />
      </div>
      <div style={{ padding: '40px 0' }}>
        <Title level={5} style={{ textAlign: 'center' }}>
          Edge CheckIn
        </Title>
        <Line style={{ padding: '40px 0' }} {...config} yAxis />
      </div>
    </div>
  );
};
