import React, { useState, useEffect } from 'react';
import { Space } from 'antd';

import TemperatureCard from '@components/atoms/temperatureCard';
import { refact2Decimals } from '@root/utils';

const EnergyMonitor = ({ data }) => {
  const [dataDaily, setDataDaily] = useState('N/A');
  const [dataWeek, setDataWeek] = useState('N/A');
  const [dataMonth, setDataMonth] = useState('N/A');
  const [dataYear, setDataYear] = useState('N/A');
  const [dataLifeTime, setDataLifeTime] = useState('N/A');

  useEffect(() => {
    if (data) {
      const { daily, weekly, monthly, yearly, lifetime } = data;
      if (daily) {
        const { value, unit } = daily || {};
        setDataDaily(`${refact2Decimals(value)} ${unit}`);
      }

      if (weekly) {
        const { value, unit } = weekly || {};
        setDataWeek(`${refact2Decimals(value)} ${unit}`);
      }

      if (monthly) {
        const { value, unit } = monthly || {};
        setDataMonth(`${refact2Decimals(value)} ${unit}`);
      }

      if (yearly) {
        const { value, unit } = yearly || {};
        setDataYear(`${refact2Decimals(value)} ${unit}`);
      }

      if (lifetime) {
        const { value, unit } = lifetime || {};
        setDataLifeTime(`${refact2Decimals(value)} ${unit}`);
      }
    } else {
      setDataDaily('N/A');
      setDataWeek('N/A');
      setDataMonth('N/A');
      setDataYear('N/A');
      setDataLifeTime('N/A');
    }
  }, [data]);

  return (
    <Space size={8} direction="vertical" style={{ width: '100%' }}>
      <TemperatureCard title="Energy" value={`Daily ${dataDaily}`} />
      <TemperatureCard title="Energy" value={`Weekly ${dataWeek}`} />
      <TemperatureCard title="Energy" value={`Monthly ${dataMonth}`} />
      <TemperatureCard title="Energy" value={`Yearly ${dataYear}`} />
      <TemperatureCard title="Energy" value={`Total ${dataLifeTime}`} />
    </Space>
  );
};

export default EnergyMonitor;
