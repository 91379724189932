import React, { useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import { Popover, Tooltip, Button, Form, Input, Row, Space } from 'antd';
import { bool, string } from 'prop-types';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import { ReactComponent as TicketInActive } from '../../../assets/TicketInActive.svg';
import { ReactComponent as TicketActive } from '../../../assets/TicketActive.svg';

const JiraCard = ({ isActive, edgeId, jiraTicket }) => {
  const [form] = Form.useForm();
  const [isOpen, setOpen] = useState(false);
  const [isActiveState, setActive] = useState(isActive);
  const [jiraNumber, setJiraNumber] = useState(jiraTicket || null);
  const { stateNewJiraCard, newJiraCard } = useEdgeApplication();
  const { isLoading, isSuccess } = stateNewJiraCard;

  useEffect(() => {
    setActive(isActive);
    setJiraNumber(jiraTicket);
    handleCancel();
  }, [isActive, edgeId, jiraTicket]);

  useEffect(() => {
    setActive(isActive);
    // if (isSuccess) {
    //   handleCancel();
    // }
  }, []);

  const onFinish = (values) => {
    newJiraCard(edgeId, values);
    setActive(true);
    setJiraNumber(values.card_number);
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const jiraPopoverContent = (
    <Form form={form} onFinish={onFinish} scrollToFirstError layout="vertical">
      <Form.Item
        name="card_number"
        label="Jira Number: "
        rules={[
          { required: true, message: 'Please input Jira number' },
          { max: 70, message: 'Maximum length is 70 characters' },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Row justify="end">
          <Space>
            <Button htmlType="submit" type="primary">
              Open Ticket
            </Button>
            <Button onClick={handleCancel}>Close</Button>
          </Space>
        </Row>
      </Form.Item>
    </Form>
  );

  const jiraInactiveTicket = (
    <Popover content={jiraPopoverContent} trigger="click" open={isOpen} onOpenChange={handleOpenChange}>
      <span>
        {/* <FileAddOutlined style={{ color: '#d9d9d9', fontSize: 2 + 'em' }} /> */}
        <Icon
          component={TicketInActive}
          style={{
            fontSize: '35px',
          }}
        ></Icon>
      </span>
    </Popover>
  );

  const JiraActiveTicket = (
    <Tooltip title={jiraNumber}>
      <span>
        {/* <FileExclamationOutlined style={{ color: '#fa8c16', fontSize: 2 + 'em' }} /> */}
        <Icon
          component={TicketActive}
          style={{
            fontSize: '35px',
          }}
        ></Icon>
      </span>
    </Tooltip>
  );

  if (isActiveState) {
    return JiraActiveTicket;
  } else {
    return jiraInactiveTicket;
  }
};

JiraCard.propTypes = {
  isActive: bool,
  edgeId: string,
  jiraTicket: string,
};

export default JiraCard;
