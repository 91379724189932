import styled from 'styled-components';

export default styled.div`
  .ant-slider-mark-text {
    width: 100%;
  }
  //.ant-switch {
  //  margin-bottom: 28px;
  //}
`;
