import config from '@config';
import { instance } from '@root/utils/axios';
import { HttpAuth } from '@services/http';

const path = `${config.API_ENDPOINT}/users`;

const getListUser = async (params) => {
  return HttpAuth.get(path, {params: params});
};

const newUser = async (form) => {
  return HttpAuth.post(`${path}/operation-portal`, form);
};

const getOneUser = async (id) => {
  return HttpAuth.get(`${path}/${id}`);
};

const editUser = async (id, form) => {
  return HttpAuth.patch(`${path}/updateUser/${id}`, form);
};

const resetUserMFA = async (id) => {
  return instance.patch(`${path}/updateUser/mfa/${id}`);
};

export default {
  getListUser,
  getOneUser,
  newUser,
  editUser,
  resetUserMFA,
};
