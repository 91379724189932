import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@store/auth/hook';
import { hasAccess } from '@root/utils/permission';
const Home = () => {
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    if (!auth.user) {
      history.push('/login');
      return;
    }
    const hasNcc = hasAccess(auth.user, 'call-center-portal', 'read');
    const hasDashboard = hasAccess(auth.user, 'dashboard', 'read');
    if (hasNcc && !hasDashboard) {
      history.push('/dashboard/call-center-portal');
      return;
    }

    if (hasDashboard) {
      history.push('/dashboard');
      return;
    }

    history.push('/dashboard/edge-applications');
  }, []);

  return <></>;
};

export default Home;
