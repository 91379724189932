import styled from "styled-components";

const TextCardHeader = styled.div`
  width: 100%;
  height: ${props => props.$gray ? "45px": "48px"};
  background-image: ${props => props.$gray ? "linear-gradient(to right, rgba(245, 245, 245, 1), rgba(255, 236, 235, 0));" : "linear-gradient(to right, rgba(207, 102, 98, 0.35), rgba(255, 236, 235, 0))"};
  padding: 8px 24px;
  margin-top: ${props => props.$gray ? "40px": "0"};
  h2 {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    margin: 0;
  }
`;

export default TextCardHeader;