import { Column } from '@ant-design/charts';
import { PAGE_REFRESHING_ATOM, TRINITY_DASHBOARD_ATOM } from '@root/atoms/dashboard.atom';
import { fetchDeviceConnectivity } from '@root/services/dashboard.service';
import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { configDeviceConnectivityChart } from '../../chartConfig';
import Styled from './style';

const DeviceConnectivity = () => {
  const [refresh] = useAtom(PAGE_REFRESHING_ATOM);
  const [params] = useAtom(TRINITY_DASHBOARD_ATOM);
  const { data, isLoading, isFetching } = useQuery(
    ['device-connectivity', params, refresh],
    () => fetchDeviceConnectivity(params),
    {
      refetchInterval: refresh.every,
    },
  );

  const deviceData = useMemo(() => {
    if (data) {
      return data?.data;
    }
  }, [data]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <Styled>
        <h3 style={{ textAlign: 'end' }}>Total: {deviceData?.total || 0}</h3>
        <h2>Device connectivity</h2>
        {deviceData && <Column animation={false} {...configDeviceConnectivityChart(deviceData?.chart)} />}
      </Styled>
    </Spin>
  );
};

export default DeviceConnectivity;
