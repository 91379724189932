import styled from "styled-components";

const ErrorCustomer = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapErrorCustomer {
    width: 576px;
    height: 212px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  h2 {
    font-family: 'Sarabun', sans-serif;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    color: #000000;
    margin: 0;
    margin-bottom: 12px;
  }
  p {
    font-family: 'Sarabun', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #000000;
    margin: 0;
  }
  img {
    width: 46px;
    height: auto;
    margin-bottom: 24px;
  }
`;

export default ErrorCustomer;