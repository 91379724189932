import React, { useContext, createContext, useState } from 'react';
import { message } from 'antd';
import { getListCustomer } from '../api';

const customerContext = createContext();

const useListCustomer = () => {
  return useContext(customerContext);
};

const useProvideCustomer = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = async (params, cb = () => {}) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await getListCustomer(params);
    if (res.status === '200') {
      setData(res.data);
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    getData,
    setData,
  };
};

const ContextListCustomer = ({ children }) => {
  const roof = useProvideCustomer();

  return <customerContext.Provider value={roof}>{children}</customerContext.Provider>;
};

export { useListCustomer, ContextListCustomer };
