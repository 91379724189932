import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from '@components/atoms/table';
import moment from 'moment';
import { Typography } from 'antd';

const { Text } = Typography;

const EdgeAssociateDeviceTable = (props) => {
  const { items, loading, onChange, pagination, filters } = props;

  const columns = [
    {
      title: 'Edge ID',
      key: 'edge_id',
      dataIndex: 'edge_id',
      render: (val) => val || '-',
    },
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      key: 'device_id',
      render: (val) => val || '-',
    },
    {
      title: 'Device Type',
      dataIndex: 'device_type',
      key: 'device_type',
      render: (val) => val || '-',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (val) => val || '-',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (val) => val || '-',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      render: (val) => val || '-',
    },
    {
      title: 'Last Seen',
      dataIndex: 'sensor_last_seen',
      key: 'sensor_last_seen',
      render: (val) => {
        if (!val) return '-';
        const diff = 60 * 1000 * 10; // 10 minutes;
        const type = Date.now() - new Date(val) > diff ? 'danger' : '';
        return <Text type={type}>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</Text>;
      },
    },
  ].filter(Boolean);

  const indexedItems = useMemo(() => items.map((item, index) => ({ ...item, index })), [items]);

  return (
    <Table
      columns={columns}
      dataSource={indexedItems}
      rowKey={(record) => record.index}
      loading={{ spinning: loading, tip: 'Loading...' }}
      onChange={(pagination, filters, sorter) => {
        onChange(pagination, sorter);
      }}
      pagination={{ ...pagination, current: filters.page, showSizeChanger: true }}
    />
  );
};

EdgeAssociateDeviceTable.propTypes = {
  items: PropTypes.array,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  pagination: PropTypes.object,
  filters: PropTypes.object,
};

EdgeAssociateDeviceTable.defaultProps = {
  items: [],
  loading: false,
  onChange: () => {},
  pagination: {},
};

export default EdgeAssociateDeviceTable;
