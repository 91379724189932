import * as React from 'react';
const CallCenterPortalLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12.5 8.433v2.834c0 .73-.525 1.357-1.248 1.453a32.296 32.296 0 0 1-8.504 0A1.452 1.452 0 0 1 1.5 11.267V8.433m11 0c.315-.268.5-.674.5-1.107V4.804c0-.72-.512-1.343-1.225-1.45-.75-.112-1.509-.199-2.275-.258m3 5.337c-.13.11-.28.197-.449.253A15.984 15.984 0 0 1 7 9.5c-1.765 0-3.464-.286-5.051-.814a1.343 1.343 0 0 1-.449-.253m0 0A1.454 1.454 0 0 1 1 7.326V4.804c0-.72.512-1.343 1.225-1.45.75-.112 1.509-.199 2.275-.258m5 0V2.5A1.5 1.5 0 0 0 8 1H6a1.5 1.5 0 0 0-1.5 1.5v.596m5 0a32.444 32.444 0 0 0-5 0M7 7.5h.005v.005H7V7.5Z"
    />
  </svg>
);
export default CallCenterPortalLogo;
