import React, { useContext, createContext, useState } from 'react';
import { element } from 'prop-types';

import { message } from 'antd';
import { defaultState } from '../core';
import api from './api';

const userContext = createContext();

const useUser = () => {
  return useContext(userContext);
};

const useProvideUser = () => {
  const [stateEditUser, setStateEditUser] = useState(defaultState);
  const [stateNewUser, setStateNewUser] = useState(defaultState);
  const [stateUser, setStateUser] = useState(defaultState);
  const [listUser, setListUser] = useState([]);

  const getListUser = async (params) => {
    const res = await api.getListUser(params);
    if (res.status === '200') {
      setListUser(res.data);
    } else {
      message.error(res.message || 'Something went wrong!');
    }
  };

  const getOneUser = async (id, cb = () => {}) => {
    setStateUser((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.getOneUser(id);
    if (res.status === '200') {
      setStateUser({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error('Not found!');
      setStateUser({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const newUser = async (form, cb = () => {}) => {
    setStateNewUser((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.newUser(form);
    if (res.status === '200') {
      message.success('Success to new user!');
      setStateNewUser({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateNewUser({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const editUser = async (id, form, cb = () => {}) => {
    setStateEditUser((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.editUser(id, form);
    if (res.status === '200') {
      message.success('Success to update user!');
      setStateEditUser({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateEditUser({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  return {
    listUser,
    stateUser,
    stateNewUser,
    stateEditUser,
    getListUser,
    getOneUser,
    newUser,
    editUser,
  };
};

const ProvideUser = ({ children }) => {
  const val = useProvideUser();

  return <userContext.Provider value={val}>{children}</userContext.Provider>;
};

ProvideUser.propTypes = {
  children: element.isRequired,
};

export { useUser, ProvideUser };
