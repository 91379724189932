import SetupMFAForm from '@root/components/organisms/forms/setupMfaForm';
import React, { useCallback, useState } from 'react';
import BannerLogin from '@root/components/molecules/bannerLogin';
import SetupTOTPForm from '@root/components/organisms/forms/setupTOTPForm';

import Style from './style';

const SetUpMFAPage = () => {
  const [setup, setSetup] = useState('stepupMFA');
  const [credentialMFA, setCredentialMFA] = useState();
  const handleSuccessFormResetMFA = useCallback(({ secretCode, accessToken }) => {
    setSetup('setupTOTP');
    setCredentialMFA({
      accessToken: accessToken,
      secretCode: secretCode,
    });
  }, []);

  const setDefaultFormMFAsetUp = useCallback(() => {
    setSetup('stepupMFA');
  }, []);
  return (
    <Style>
      <div className="side-container">
        <BannerLogin />
      </div>
      <div className="main-container">
        <div className="login-container">
          {setup === 'stepupMFA' && <SetupMFAForm handleSuccessFormResetMFA={handleSuccessFormResetMFA} />}
          {setup === 'setupTOTP' && credentialMFA && (
            <SetupTOTPForm credentialMFA={credentialMFA} setDefaultFormMFAsetUp={setDefaultFormMFAsetUp} />
          )}
        </div>
      </div>
    </Style>
  );
};

export default SetUpMFAPage;
