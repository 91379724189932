import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'antd';

const UserAssignUserGroupTable = ({ items, onSelectUserChange, selectedRowKeys = [] }) => {
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: 150,
    },
  ];

  return (
    <>
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectUserChange,
        }}
        columns={columns}
        dataSource={items}
        rowKey="id"
      />
    </>
  );
};

UserAssignUserGroupTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onSelectUserChange: PropTypes.func,
  selectedRowKeys: PropTypes.array,
};

export default UserAssignUserGroupTable;
