import { Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useMemo } from 'react';
import { SearchSCGID } from './components/Search';
import { TransferSCGID } from './components/TransferSCGID';
import {BindSCGID} from './components/BindSCGID';
// import { AddSCGID } from './components/AddSCGID';
// import { RemoveSCGID } from './components/RemoveSCGID';
// import { CreateAndBind } from './components/CreateAndBind';

const TABS = [
  { key: 'scg-id/read', name: 'Search', Component: <SearchSCGID /> },
  { key: 'scg-id/create', name: 'Bind', Component: <BindSCGID /> },
  // { key: 'scg-id/create', name: 'Create & Bind', Component: <CreateAndBind /> },
  { key: 'scg-id/update', name: 'Transfer', Component: <TransferSCGID /> },
  // { key: 'scg-id/delete', name: 'Unbind', Component: <RemoveSCGID /> },
];

export const ManageSCGID = ({ roles }) => {
  const validRoles = useMemo(() => {
    return roles.filter((role) => role.includes('scg-id/'));
  }, [roles]);

  return (
    <>
      <Title level={4}>Manage SCG ID</Title>
      <Tabs destroyInactiveTabPane>
        {TABS.map(({ key, name, Component }) => {
          if (validRoles.includes(key)) {
            return (
              <Tabs.TabPane tab={name} key={name}>
                {Component}
              </Tabs.TabPane>
            );
          }
          return <></>;
        })}
      </Tabs>
    </>
  );
};
