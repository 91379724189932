import dayjs from 'dayjs';

const EdgeStatusEnum = {
  online: 'online',
  offline: 'offline',
  dataLoss: 'dataloss',
};

const MAStatusEnum = {
  normal: 'normal',
  clean: 'clean',
  delayed: 'delayed',
};

const AirInformationDataTypes = ['temperature', 'pm2_5', 'humidity', 'co2'];

const AirInformationTitleEnum = {
  temperature: 'Temperature',
  pm2_5: 'PM2.5',
  co2: 'CO2',
  humidity: 'Humidity',
};

const AirInformationUnitEnum = {
  temperature: '°C',
  pm2_5: 'µg./m3',
  co2: 'ppm',
  humidity: '%',
};

const getMaStatus = (date) => {
  if (dayjs().add(30, 'day').isBefore(date)) {
    return MAStatusEnum.normal;
  } else if (dayjs().isBefore(date) && dayjs().add(30, 'day').isAfter(date)) {
    return MAStatusEnum.clean;
  } else {
    return MAStatusEnum.delayed;
  }
};

const getEdgeStateFromLastReceivedTime = (lastReceivedTime) => {
  if (!lastReceivedTime) return EdgeStatusEnum.offline;
  const minutesFromLastReceived = dayjs().diff(lastReceivedTime, 'm');
  if (minutesFromLastReceived >= 10)
    return EdgeStatusEnum.offline;
  else if (minutesFromLastReceived > 5)
    return EdgeStatusEnum.dataLoss;
  else
    return EdgeStatusEnum.online;
};

export {
  EdgeStatusEnum,
  MAStatusEnum,
  getMaStatus,
  getEdgeStateFromLastReceivedTime,
  AirInformationTitleEnum,
  AirInformationUnitEnum,
  AirInformationDataTypes,
};
