import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Row, Space } from 'antd';
import {Tabs} from 'antd';
import "antd/dist/antd.css";
import { HttpAuth } from '@services/http';
import config from '@config';
import { Collapse } from 'antd';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const path = `${config.API_ENDPOINT}/edge-applications/bom/get`;
const getOneBomFeatureSpec = async (bomId) => {
  return HttpAuth.get(`${path}/${bomId}`);
};
const DeviceFeatureSpecsModal = (props) => {
  const { visible, onSubmit, onCancel } = props;
  const [state, setState] = React.useState({ bomSpecData: null, bomId: -1, mainSpecData: null, parentArray: null });
  let bomId = props.bomId;
  const latestRequest = React.useRef(null);
  let mainSpecData = {};
  useEffect(() => {
    latestRequest.current = bomId;
    if(bomId > 0){
      const fetchData = async () => {
        let bomSpecData = await getOneBomFeatureSpec(bomId);
        let mainSpecData = {};
        if (latestRequest.current == bomId) {
          setState({ bomSpecData });
          bomSpecData.data.rows.forEach(element => {
            if(mainSpecData[element.Device_Name] == null){
              mainSpecData[element.Device_Name] = {};
            }
            if(mainSpecData[element.Device_Name][element.name] == null){
              mainSpecData[element.Device_Name][element.name] = {};
            }
            mainSpecData[element.Device_Name][element.name][element.Limit_Name] = element;
          });
          let parentArray = [];
          Object.keys(mainSpecData).forEach(deviceName => {
            let subSpecArray = [];
            let l1 = {'deviceName':(deviceName && deviceName!='null') ? deviceName : '', 'specs': subSpecArray};
            Object.keys(mainSpecData[deviceName]).forEach(specName => {
              let limitsArray = [];  
              let l2 = {'specName':(specName && specName != 'null') ? specName : '', 'limits': limitsArray};
              Object.keys(mainSpecData[deviceName][specName]).forEach(limitName => {
                  let l3 = {'limitName':(limitName && limitName != 'null') ? limitName : '', 'limitElement': mainSpecData[deviceName][specName][limitName]};
                  limitsArray.push(l3);
                });
              subSpecArray.push(l2);
            });
            parentArray.push(l1);
          });
          setState({ mainSpecData });
          setState({ parentArray });
        }
      }
      fetchData().catch(console.error);
    }
  }, [bomId]);

  function getMinMaxLimitValue(specLevel, limitElement){
    console.log(specLevel, limitElement);
    let maxValue = '', usedValue=limitElement.Value;
    if(limitElement.Specification_Attribute_Type && limitElement.Specification_Attribute_Type.indexOf('REMAINING_') == 0){
      const attrToSearch = 'NUMBER_OF_'+limitElement.Specification_Attribute_Type.replace('REMAINING_','');
      specLevel.limits.forEach(element => {
        if(element.limitElement.Specification_Attribute_Type == attrToSearch){
          maxValue = element.limitElement.Value;
        }
      });
    }
    if(limitElement.Specification_Attribute_Type && limitElement.Specification_Attribute_Type.indexOf('CONNECTED_') == 0){
      const attrToSearch = 'NUMBER_OF_'+limitElement.Specification_Attribute_Type.replace('CONNECTED_','');
      specLevel.limits.forEach(element => {
        if(element.limitElement.Specification_Attribute_Type == attrToSearch){
          maxValue = element.limitElement.Value;
        }
      });
    }
    return (null != maxValue && '' != maxValue && null != usedValue && '' != usedValue) ? usedValue+'/'+maxValue : (null != usedValue ? usedValue : '');
  }
  
  return (
    <Modal title="Device Feature Specifications" visible={visible} onOk={onSubmit} onCancel={onCancel} footer={null} width={'90%'} bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
      <Tabs type="card">
      {state.parentArray && state.parentArray.map(function(deviceLevel, id1) {
            return deviceLevel && (
                 <TabPane tab={deviceLevel.deviceName} key={deviceLevel.deviceName+id1}>
                  <Collapse accordion>
                  {deviceLevel.specs.map(function(specLevel, id2) {
                    return specLevel && (
                      <Panel header={specLevel.specName ? specLevel.specName : ''} key={specLevel.specName+id2}>
                        {specLevel.limits.map(function(limitsLevel, id3) {
                          return limitsLevel && (
                            <p key={id1+'-'+id2+'-'+id3}><span>{limitsLevel.limitName ? limitsLevel.limitName : ''}</span>: <span>{getMinMaxLimitValue(specLevel, limitsLevel.limitElement)}{limitsLevel.limitElement.Unit ? limitsLevel.limitElement.Unit : ''}</span></p>
                          )
                          })}
                      </Panel>
                      )
                          })}
                  </Collapse>
                </TabPane>
            )
                          })}
      </Tabs>
    </Modal>
  );
};

DeviceFeatureSpecsModal.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

DeviceFeatureSpecsModal.defaultProps = {
  visible: false,
  onSubmit: () => {},
  onCancel: () => {},
};

export default DeviceFeatureSpecsModal;
