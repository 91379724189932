import { instance } from '@root/utils/axios';

export const fetchSCGID = async (id) => {
  const res = await instance.get(`/edge-applications/${id}/scgid`);
  return res.data;
};

export const fetchEdgeID = async (scgId) => {
  const res = await instance.get(`/scg-id/${scgId}`);
  return res.data;
};

export const addSCGID = async ({ edge_id, scg_id }) => {
  const res = await instance.post(`/scg-id/${edge_id}/${scg_id}`);
  return res.data;
};

export const deleteSCGID = async ({ edge_id, scg_id }) => {
  const res = await instance.delete(`/scg-id/${edge_id}/${scg_id}`);
  return res.data;
};

export const transferSCGID = async ({ old_edge_id, new_edge_id, scg_id }) => {
  const res = await instance.put(`/scg-id/${old_edge_id}`, { scg_id, new_edge_id });
  return res.data;
};

export const fetchCustomerByEdgeID = async (edge_barcode) => {
  const res = await instance.get(`/customers/find/byEdge?edge_barcode=${edge_barcode}`);
  return res.data;
};

export const createCustomerByEdgeID = async (payload) => {
  const res = await instance.put(`edge-applications/registerAndBindTuya`, payload);
  return res.data;
};

export const searchEdgeIdNScgId = async (query) => {
  const res = await instance.get(`/edge-applications/check-edge-binding/by-phone`, { params: query });
  return res.data;
};
export const getCustomerDetail = async (query) => {
  const res = await instance.get(`/customers/get/by-phone`, { params: query });
  return res.data;
};
export const getEdgeDetail = async (edge_id) => {
  try {
    const res = await instance.get(`/edge-applications/${edge_id}`);
    return res.data;
  } catch (error) {
    return null;
  }
};

export const bindEdgeIdWithScgId = async (payload) => {
  const res = await instance.put(`/edge-applications/registerAndBindTuya`, payload);
  return res.data;
};
