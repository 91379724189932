/* eslint-disable no-undef */
export default {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  REACT_APP_GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
  REACT_APP_USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  REACT_APP_POLL_WEB_CLIENT_ID: process.env.REACT_APP_POLL_WEB_CLIENT_ID,
  REACT_APP_COGNITO_DOMAIN: process.env.REACT_APP_COGNITO_DOMAIN,
  REACT_APP_MASTER_DATA_DOMAIN: process.env.REACT_APP_MASTER_DATA_DOMAIN,
  REACT_APP_MASTER_DATA_TOKEN: process.env.REACT_APP_MASTER_DATA_TOKEN,
};
