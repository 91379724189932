import React from "react";
import SessionExp from "./style";

const SessionExpNcc = () => {

  return (
    <SessionExp>
      <div className="wrapSessionExp">
        <h2>ไม่สามารถใช้งานหน้านี้ได้ เนื่องจากลิงก์หมดอายุ</h2>
      </div>
    </SessionExp>
  )
}

export default SessionExpNcc;