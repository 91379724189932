/* eslint-disable react/prop-types */
import { HISTORY_COLUMN } from '@root/constants/columns.constant';
import { fetchActionHistory } from '@root/services/customers.service';
import { Modal, Table } from 'antd';
import { useQuery } from 'react-query';

const CustomerLogModal = ({ onClose, customer }) => {
  const { data: history } = useQuery('action-history', () =>
    fetchActionHistory({ type: 'customer', id: customer?.id }),
  );
  return (
    <Modal
      footer={null}
      visible={!!customer}
      onCancel={() => onClose(null)}
      title={`${customer?.first_name ?? ''} - Log`}
      width={700}
    >
      <Table pagination={false} columns={HISTORY_COLUMN} dataSource={history?.data?.items} />
    </Modal>
  );
};

export default CustomerLogModal;
