import React, { useContext, createContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { setToken, getToken, removeToken, getTokenExpires } from '@services/auth';
import { dateDiff } from '@root/utils/dateUtilize';
import api from './api';

const authContext = createContext({
  user: null,
});

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = () => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [isCheckedToken, setIsCheckedToken] = useState(false);
  const sessionExpireTimeout = useRef();

  const login = async ({ username, password }, cb = () => {}) => {
    const form = {
      username,
      password,
    };
    const res = await api.login(form);
    if (res.status === '200') {
      const { access_token } = res.data;
      setToken(access_token);
      getProfile(cb);
    } else {
      message.error('Your username or password has incorrect.');
    }
  };

  const loginCognito = async ({ accessToken, signOut }, cb = () => {}) => {
    const form = {
      accessToken,
    };
    const res = await api.loginCognito(form);
    if (res.status === '200') {
      signOut({ global: true });
      const { access_token } = res.data;
      setToken(access_token);
      getProfile(cb);
    } else {
      signOut({ global: true });
      message.error('Your username or password has incorrect.');
    }
  };

  const logout = async (cb = () => {}) => {
    await api.logout();
    setUser(null);
    removeToken();
    message.success('Logout');
    cb();
  };

  const getProfile = async (cb = () => {}) => {
    const res = await api.getProfile();
    if (res.status === '200') {
      const { data } = res;
      setUser(data);
      cb();
    } else {
      message.error('Your session expired');
      logout(() => {
        history.push('/login');
      });
    }
  };

  const updateFirstPassword = async ({ password }, cb = () => {}) => {
    const form = { password };

    const res = await api.updateFirstPassword(form);
    if (res.status === '200') {
      getProfile();
      message.success('Your authentication setting be success!');
    } else {
      message.error(res.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      getProfile(() => {
        setIsCheckedToken(true);
      });
    } else {
      setIsCheckedToken(true);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      if (sessionExpireTimeout.current) {
        clearTimeout(sessionExpireTimeout.current);
      }
      return;
    }

    const timeExpires = getTokenExpires();
    if (!timeExpires) return;

    const sessionTimeLeft = dateDiff(timeExpires);
    sessionExpireTimeout.current = setTimeout(() => {
      logout();
      message.info('Your session has been expired');
    }, sessionTimeLeft * 1000);
  }, [user]);

  return {
    isCheckedToken,
    user,
    login,
    logout,
    getProfile,
    updateFirstPassword,
    loginCognito,
  };
};

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();

  if (!auth.isCheckedToken) return <div>Loading...</div>;

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
