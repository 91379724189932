import { verifyMFA } from '@root/services/cognito.service';
import { useAuth } from '@root/store/auth/hook';
import { Button, Form, Input, Space, Typography, notification } from 'antd';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

const { Title, Text } = Typography;

const formRules = {
  otp: [
    { required: true, message: 'Please enter your otp' },
    {
      pattern: new RegExp(/^[0-9]+$/),
      message: 'OTP has to be a number',
    },
  ],
};

const SetupTOTPForm = ({ credentialMFA, setDefaultFormMFAsetUp }) => {
  const auth = useAuth();
  const [form] = Form.useForm();
  const [qrcode, setQrcode] = useState('');
  const history = useHistory();
  useEffect(() => {
    const otpauthUrl = `otpauth://totp/${auth.user.username}?secret=${credentialMFA.secretCode}`;
    QRCode.toDataURL(otpauthUrl)
      .then((url) => {
        setQrcode(url);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [credentialMFA]);

  const { mutate, isLoading } = useMutation({
    mutationFn: verifyMFA,
    onSuccess: (res) => {
      auth.getProfile(() => {
        history.push('/dashboard');
      });
      notification.success({ message: 'ตั้งค่า Multi Factor Authentication สำเร็จ' });
    },
    onError: (err) => {
      const message = err?.response?.data?.message;
      if (message === 'Code mismatch') {
        notification.error({ message: 'รหัส OTP ไม่ถูกต้อง' });
      } else if (message === 'Invalid Access Token' || message === 'Access Token has expired') {
        setDefaultFormMFAsetUp();
        notification.error({ message: 'คุณไม่ได้ทำรายการในเวลาที่กำหนด กรุณาทำรายการใหม่อีกครั้ง' });
      } else {
        notification.error({ message: 'Something went wrong. Please try again' });
      }
    },
  });

  const onFinish = async (values) => {
    const payload = {
      username: auth.user.username,
      userCode: values.otp,
      accessToken: credentialMFA.accessToken,
    };
    mutate(payload);
  };
  return (
    <div>
      <Title level={4} style={{ paddingBottom: 30 }}>
        ขั้นตอนการทำ Multi Factor Authentication
      </Title>
      <Space direction="vertical" style={{ paddingBottom: 30 }}>
        <Text>1. ตรวจสอบว่าคุณมีแอพพลิเคชั่น Authentication แล้วหรือยังหากยังไม่มี กรุณาติดตั้งแอพพลิเคชั่น</Text>
        <Text>2. หลังจากติดตั้งแอพพลิเคชั่นเรียบร้อยแล้ว สแกน QR Code ด้านล่าง</Text>
        <div style={{ margin: 'auto' }}>
          <img src={qrcode} />
        </div>
        <Text>3. หลังจากสแกน QR Code แล้ว นำเลข 6 หลักมาใส่ในช่อง OTP</Text>
      </Space>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="otp" label="OTP" rules={formRules.otp}>
          <Input disabled={isLoading} placeholder="โปรดระบุเลข OTP" maxLength={6} minLength={6} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isLoading}>
            ยืนยันรหัส
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetupTOTPForm;
