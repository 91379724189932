import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Space, Input, Select } from 'antd';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import EdgeAssociateDeviceTable from '@components/organisms/tables/edgeAssociateDeviceTable';
import { CSVDownload } from 'react-csv';
import { useAssociateDevice } from '@store/edgeAssociateDevice/hook';
import { exportAssociateDeviceHeaders } from '@root/config/edgeAssociateDeviceConfig';
import { useAuth } from '@store/auth/hook';
import { useUserGroup } from '@store/userGroup/hook';
import { get } from 'lodash';
import Style from './style';

const { Option } = Select;

const EdgeApplication = () => {
  const {
    listAssociateDevices,
    getListAssociateDevices,
    countAssociateDevices,
    getCountAssociateDevices,
    exportAssociateDevices,
  } = useAssociateDevice();
  const { user } = useAuth();
  const { tenants, getTenants } = useUserGroup();
  const { data: tenantsData } = tenants;
  const [exportState, setExportState] = useState({ loading: false, data: null });
  const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [listEdgeApplications, setListEdgeApplications] = useState([]);
  const [filterCriterias, setFilterCriterias] = useState({ page: 1, pageSize: 10 });
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(filterCriterias);
  }, [filterCriterias]);

  useEffect(() => {
    user.roles.length > 0 && getTenants({ role: get(user, 'roles', []).includes('tenant') && 'tenant' });
  }, [user]);

  const prepareFilters = (values = {}) => {
    const filters = { ...filterCriterias, ...values };

    if (isFilterChanged) {
      filters.page = 1;
    }

    return filters;
  };

  const onSearch = (values) => {
    const filters = prepareFilters(values);
    setIsFilterChanged(false);
    setFilterCriterias(filters);
    getListAssociateDevices(filters);
    getCountAssociateDevices(filters);
  };

  const onExport = async () => {
    setExportState((prev) => ({ ...prev, loading: true }));
    const data = await exportAssociateDevices(prepareFilters());
    setExportState((prev) => ({ ...prev, loading: false, data }));
  };

  useEffect(() => {
    if (user) {
      getListAssociateDevices({ ...filterCriterias, tenantId: get(user, 'tenants[0]', '') });
      getCountAssociateDevices({ ...filterCriterias, tenantId: get(user, 'tenants[0]', '') });
    }
  }, [user]);

  useEffect(() => {
    if (!countAssociateDevices.data) return;
    const { total } = countAssociateDevices.data;
    setPagination((prev) => ({ ...prev, total }));
  }, [countAssociateDevices.data]);

  useEffect(() => {
    if (!listAssociateDevices.data) return;
    setListEdgeApplications(listAssociateDevices.data);
  }, [listAssociateDevices.data]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const tableChange = (pagination) => {
    const filters = {
      ...filterCriterias,
      page: pagination.current,
      pageSize: pagination.pageSize,
      // sort: sorter.order ? `${sorter.columnKey}:${sorter.order.slice(0, -3)}` : '',
    };
    setFilterCriterias(filters);
    getListAssociateDevices(filters);
  };

  return (
    <Style>
      <Form
        {...layout}
        style={{ marginBottom: 24 }}
        onFinish={onSearch}
        form={form}
        onValuesChange={() => setIsFilterChanged(true)}
      >
        <Row gutter={[24, 12]} align="middle">
          <Col span={3}>
            <label>Edge ID</label>
          </Col>
          <Col span={5}>
            <Form.Item name="edgeId" noStyle>
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Device ID</label>
          </Col>
          <Col span={5}>
            <Form.Item name="deviceId" noStyle>
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </Form.Item>
          </Col>
          {get(user, 'roles', []).includes('tenant') && (
            <>
              <Col span={3}>
                <label>Tenant</label>
              </Col>
              <Col span={5}>
                <Form.Item name="tenantId" initialValue={+get(user, 'tenants[0]', '')} noStyle>
                  <Select style={{ width: '200px' }}>
                    {tenantsData &&
                      tenantsData.length > 0 &&
                      tenantsData.map((tenant) => (
                        <Option key={tenant.id} value={tenant.id}>
                          {tenant.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={24}>
            <Row justify="end">
              <Space size={10}>
                {exportState.data && !exportState.loading && (
                  <CSVDownload
                    target="_blank"
                    data={exportState.data}
                    asyncOnClick={true}
                    headers={exportAssociateDeviceHeaders}
                  />
                )}
                <Button icon={<FileTextOutlined />} loading={exportState.loading} onClick={() => onExport()}>
                  Export
                </Button>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  Search
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
      <EdgeAssociateDeviceTable
        filters={filterCriterias}
        loading={listAssociateDevices.isLoading}
        pagination={pagination}
        onChange={tableChange}
        items={listEdgeApplications}
      />
    </Style>
  );
};

export default EdgeApplication;
