import styled from 'styled-components';

const style = styled.div`
  width: 70px;

  img {
    width: 100%;
  }
`;

export default style;
