import config from '@config';
import { HttpAuth } from '@services/http';

const path = `${config.API_ENDPOINT}/roles`;

const getListRoles = async () => {
  return HttpAuth.get(path);
};

const newRole = async (form) => {
  return HttpAuth.post(path, form);
};

const getOneRole = async (id) => {
  return HttpAuth.get(`${path}/${id}`);
};

const editRole = async (id, form) => {
  return HttpAuth.patch(`${path}/${id}`, form);
};

export default {
  getListRoles,
  getOneRole,
  newRole,
  editRole,
};
