import React  from 'react';
import { Row, Col, Radio } from 'antd';
import ComfortZoneCard from '@root/components/atoms/comfortZoneCard';
import propTypes from 'prop-types';

const AirInformation = ({
  onChangeAirInformationAreaName,
  airInformationAreaName,
  airData,
  locations = [],
  type,
  isLoading = false,
}) => {

  const onChangeArea = (e) => {
    onChangeAirInformationAreaName(e.target.value);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Radio.Group onChange={onChangeArea} value={airInformationAreaName}>
          {type === 'aaf' ? (
            <>
              <Radio.Button value={'indoor'}>Indoor</Radio.Button>
              <Radio.Button value={'outdoor'}>Outdoor</Radio.Button>
              <Radio.Button value={'attic'}>Attic</Radio.Button>
            </>
          ) : locations.map((v, k) => (
            <Radio.Button key={k} value={v.location_name}>{v.location_name}</Radio.Button>
          ))}
        </Radio.Group>
      </Col>
      {airData.map((v, k) => (
        <Col span={12} key={k} >
          <ComfortZoneCard
            title={v.title}
            value={v.value}
            unit={v.unit}
            level={v.level}
            color={v.color}
            isLoading={isLoading}
          />
        </Col>
      ))}
    </Row>
  );
};

AirInformation.propTypes = {
  onChangeAirInformationAreaName: propTypes.func,
  airInformationAreaName: propTypes.string,
  airData: propTypes.array,
  locations: propTypes.array,
  type: propTypes.string,
  isLoading: propTypes.bool,
};

export default AirInformation;
