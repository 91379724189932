import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import moment from 'moment';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import PowerChart from '@components/organisms/charts/powerChart';
import EnergyMonitor from '@components/molecules/energyMonitor';
import { datePeriod } from '@root/utils';
import { dateByPeriod, formatByPeriod } from '@root/utils/datePeriod';
const { Text, Title } = Typography;
const handleEmptyDate = (val, format = dateFormat) => (val ? moment(val).format(format) : '-');

const handleEmpty = (val) => val || '-';
moment.locale('en', {
  week: {
    dow: 1,
  },
});
const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const installDateTimeFormat = 'DD/MM/YYYY HH:mm';

const SolarRoofTab = () => {
  const {
    dataEdge,
    dataSolarHistory,
    dataSolarCurrentData,
    dataSolarStatistic,
    dataTodayInformation,
    getSolarHistory,
    getSolarCurrentData,
    getSolarStatistic,
    getTodayInformation,
    solarInverter,
    getSolarInverter,
    solarPowerMeter,
    getSolarPowerMeter,
    associateDevice,
    getAssociateDevice,
  } = useEdgeApplication();
  const [energyPeriodChart, setEnergyPeriodChart] = useState(datePeriod.day);
  const [powerPeriodChart, setPowerPeriodChart] = useState(datePeriod.day);
  const [edgeBarcode, setEdgeBarcode] = useState();
  const [selectedDate, setSelectedDate] = useState([moment(), moment()]);
  const [powerChartDateFormat, setPowerChartDateFormat] = useState('DD-MMMM-YYYY');
  const onChangeFilterEnergy = (value) => {
    setEnergyPeriodChart(value);
  };

  const onChangeFilterPower = (value) => {
    setPowerChartDateFormat(formatByPeriod(value));
    setPowerPeriodChart(value);

    const dateParam = (() => {
      if (value === datePeriod.week) {
        return selectedDate[0]?.startOf('week').format('YYYY-MM-DD');
      }
      return selectedDate[0]?.format('YYYY-MM-DD');
    })();
    //console.log('a1');
    getSolarStatistic(edgeBarcode, { period: value, date: dateParam });
  };

  const onChangeFilterPowerRangeDate = (value) => {
    setSelectedDate(value);
    const dateParam = dateByPeriod(powerPeriodChart, value);
    getSolarStatistic(edgeBarcode, { period: powerPeriodChart, date: dateParam });
  };

  useEffect(() => {
    if (dataEdge?.edge_barcode) {
      // setEdgeBarcode('pi_00000000ed3f5b29');
      setEdgeBarcode(dataEdge.edge_barcode);
    }
  }, [dataEdge]);

  useEffect(() => {
    if (!edgeBarcode) return;

    const filter = {
      type: 'energyyield',
      period: energyPeriodChart,
    };
    getSolarHistory(edgeBarcode, filter);
  }, [edgeBarcode, energyPeriodChart]);

  useEffect(() => {
    if (!edgeBarcode) return;

    getSolarCurrentData(edgeBarcode);
    getSolarStatistic(edgeBarcode, {
      period: powerPeriodChart,
      date: moment().format('YYYY-MM-DD'),
    });
    getTodayInformation(edgeBarcode);
  }, [edgeBarcode]);

  useEffect(() => {
    if (!edgeBarcode) return;
    if (!solarPowerMeter.data) getSolarPowerMeter(edgeBarcode);
    if (!solarInverter.data) getSolarInverter(edgeBarcode);
  }, [edgeBarcode]);

  const packageName = useMemo(() => {
    const appList = dataEdge?.application_devices || [];
    return appList.find((app) => app.application_name === 'solar')?.commercial_name;
  }, [dataEdge]);

  const inverterStatus = () => {
    if (!solarInverter.data) return;
    const inverter_status = solarInverter.data.inverter_status;
    //const inverter_last_seen = solarInverter.data.inverter_last_seen;
    const label = inverter_status?.slice(0, 1).toUpperCase() + inverter_status?.slice(1).toLowerCase();
    const type = (() => {
      switch (label) {
        case 'Online':
          return 'success';
        case '-':
          return 'success';
        case 'Offline':
          return 'danger';
        case 'Malfunction':
          return 'danger';
        case 'Standby':
          return 'warning';
      }
    })();
    return <Text type={type}>{label}</Text>;
  };

  //
  //const powerMeterStatus = () => {
  //  if (!associateDevice.data) return;
  //  const powerMeter = associateDevice.data.find((item) => item.device_type === 'power_meter_online');
  //  if (!powerMeter) return;
  //  if (powerMeter.value == 'true') powerMeter.value = '1';
  //  if (powerMeter.value == 'false') powerMeter.value = '0';
  //  const value = parseInt(powerMeter.value || '0');
  //  const [label, type] = value ? ['Online', 'success'] : ['Offline', 'danger'];
  //  return <Text type={type}>{label}</Text>;
  //};

  const powerMeterStatus = () => {
    if (!solarPowerMeter.data) return;
    const power_meter_status = solarPowerMeter.data.power_meter_status;
    //const power_meter_last_seen = solarPowerMeter.data.power_meter_last_seen;
    const label = power_meter_status?.slice(0, 1).toUpperCase() + power_meter_status?.slice(1).toLowerCase();
    const type = (() => {
      switch (label) {
        case 'Online':
          return 'success';
        case '-':
          return 'success';
        case 'Offline':
          return 'danger';
        case 'Malfunction':
          return 'danger';
      }
    })();
    return <Text type={type}>{label}</Text>;
  };

  const getWarrantyEndDate = (installationDate) => {
    if(null != installationDate){
      var instDate = moment(installationDate, "YYYY-MM-DD HH:mm:ss");
      instDate.add(1, 'y');
      return instDate.format(installDateTimeFormat);
    }
    return '';
  }

  return (
    <Row gutter={[16, 32]}>
      <Col span={24}>
        <Row gutter={[16, 32]}>
          <Col span={12}>
            <Title level={5}>{`Package name: ${packageName}`}</Title>
            {inverterStatus() && (
              <Title level={5} style={{ display: 'inline-block' }}>
                Inverter status: {inverterStatus()}
              </Title>
            )}
            &nbsp;<Text strong>{handleEmptyDate(solarInverter?.data?.inverter_last_seen, dateTimeFormat)}</Text>
            <br></br>
            <br></br>
            {powerMeterStatus() && (
              <Title level={5} style={{ display: 'inline-block' }}>
                Power meter status: {powerMeterStatus()}
              </Title>
            )}
            &nbsp;<Text strong>{handleEmptyDate(solarPowerMeter?.data?.power_meter_last_seen, dateTimeFormat)}</Text>
            <br></br>
            <br></br>
            <Title level={5} style={{ display: 'inline-block' }}>
              Installation date: {handleEmptyDate(dataEdge?.installed_date, installDateTimeFormat)}
            </Title>
            <Title level={5} style={{marginTop: "0px"}}>
              Warranty Period: 1 Year {' ('+getWarrantyEndDate(dataEdge?.installed_date) +')'}
            </Title>
          </Col>
          {/* <Col span={12} style={{ textAlign: 'right' }}>
            <Title level={5}>{`Updated at ${handleEmpty(moment(dataEdge?.updated_at).format(dateTimeFormat))}`}</Title>
          </Col> */}

          <Col span={14}>
            <Card title="Today information">
              <Row gutter={[16, 32]}>
                <Col span={8}>Input power Ch1</Col>
                <Col span={16}>
                  <Text strong>
                    {dataTodayInformation?.in_power_ch1?.value
                      ? `${dataTodayInformation?.in_power_ch1?.value} W`
                      : 'N/A'}
                  </Text>
                </Col>

                <Col span={8}>Input power Ch2</Col>
                <Col span={16}>
                  <Text strong>
                    {dataTodayInformation?.in_power_ch2?.value
                      ? `${dataTodayInformation?.in_power_ch2?.value} W`
                      : 'N/A'}
                  </Text>
                </Col>

                <Col span={8}>Input voltage Ch1</Col>
                <Col span={16}>
                  <Text strong>
                    {dataTodayInformation?.in_voltage_ch1?.value
                      ? `${dataTodayInformation?.in_voltage_ch1?.value} V`
                      : 'N/A'}
                  </Text>
                </Col>

                <Col span={8}>Input voltage Ch2</Col>
                <Col span={16}>
                  <Text strong>
                    {dataTodayInformation?.in_voltage_ch2?.value
                      ? `${dataTodayInformation?.in_voltage_ch2?.value} V`
                      : 'N/A'}
                  </Text>
                </Col>

                <Col span={8}>Input current Ch1</Col>
                <Col span={16}>
                  <Text strong>
                    {dataTodayInformation?.in_current_ch1?.value
                      ? `${dataTodayInformation?.in_current_ch1?.value} A`
                      : 'N/A'}
                  </Text>
                </Col>

                <Col span={8}>Input current Ch2</Col>
                <Col span={16}>
                  <Text strong>
                    {dataTodayInformation?.in_current_ch2?.value
                      ? `${dataTodayInformation?.in_current_ch2?.value} A`
                      : 'N/A'}
                  </Text>
                </Col>

                <Col span={8}>Grid power</Col>
                <Col span={16}>
                  <Text strong>
                    {dataTodayInformation?.grid_power?.value ? `${dataTodayInformation?.grid_power?.value} W` : 'N/A'}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={10}>
            <Card>
              <EnergyMonitor data={dataSolarCurrentData} />
            </Card>
          </Col>

          {/*<Col span={24}>*/}
          {/*  <EnergyChart*/}
          {/*    period={energyPeriodChart}*/}
          {/*    onChangeFilterEnergy={onChangeFilterEnergy}*/}
          {/*    items={dataSolarHistory?.data?.energy_yield?.historical || []}*/}
          {/*  />*/}
          {/*</Col>*/}

          <Col span={24}>
            <PowerChart
              selectedDate={selectedDate}
              period={powerPeriodChart}
              onChangeFilterEnergy={onChangeFilterPower}
              onChangeFilterRangeDate={onChangeFilterPowerRangeDate}
              items={dataSolarStatistic || {}}
              dateFormat={powerChartDateFormat}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SolarRoofTab;
