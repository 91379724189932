import config from '@root/config';
import axios from 'axios';

let baseURL = config.REACT_APP_MASTER_DATA_DOMAIN;
const accessToken = config.REACT_APP_MASTER_DATA_TOKEN;

export const fetchProducts = async (params) => {
  const searchParams = new URLSearchParams(params);
  let url = '/api/products';
  if (searchParams.size > 0) {
    url = url + '?' + searchParams.toString() + '&populate=*';
    const res =  await axios.get(`${baseURL}${url}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data?.data;
  } else {
    return [];
  }
};
