import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';
import { Col } from 'antd';

const AirChart = ({ items, title, unit }) => {
  const config = {
    data: items,
    padding: 'auto',
    xField: 'time',
    yField: 'value',
    seriesField: '',
    label: {
      style: {
        fill: '#aaa',
      },
    },
    smooth: true,
    tooltip: {
      formatter: datum => ({ name: title, value: [datum.value || 0, unit].join(' ') }),
    },
  };

  return (
    <>
      <Col span={24}>{title}</Col>
      <Col span={24}>
        <Line {...config} />
      </Col>
    </>
  );
};

AirChart.propTypes = {
  unit: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

AirChart.defaultProps = {
  items: [],
};

export default AirChart;
