import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import EdgeMonitor from '@components/molecules/edgeMonitor';
import GoogleMapContainer from '@components/organisms/googleMapContainer';
import EdgeTable from '@components/organisms/tables/edgeTable';
import { useUserGroup } from '@root/store/userGroup/hook';
import { EdgeStatusEnum, MAStatusEnum } from '@services/edgeApplication';
import { useAuth } from '@store/auth/hook';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import { useGlobalHook } from '@store/global/globalHook';
import { Button, Col, Form, Input, Row, Select, Space, TreeSelect } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CSVDownload } from 'react-csv';
import Style from './style';

const { Option } = Select;

const { SHOW_PARENT } = TreeSelect;

const solutions = [
  {
    title: 'Solar Roof',
    value: 'solar',
    key: 'solar',
  },
  {
    title: 'Deebox',
    value: 'deebox',
    key: 'deebox',
  },
  {
    title: 'Active Air Flow',
    value: 'aaf',
    key: 'aaf',
  },
  {
    title: 'Active Air Quality',
    value: 'aaq',
    key: 'aaq',
  },
  {
    title: 'Active Air Flow & Quality',
    value: 'aafq',
    key: 'aafq',
  },
  {
    title: 'Tuya',
    value: 'tuya',
    key: 'tuya',
  },
];

const solutionsForSSBCallCenter = [
  {
    title: 'Solar Roof',
    value: 'solar',
    key: 'solar',
  },
  {
    title: 'Active Air Flow',
    value: 'aaf',
    key: 'aaf',
  },
  {
    title: 'Active Air Quality',
    value: 'aaq',
    key: 'aaq',
  },
  {
    title: 'Active Air Flow & Quality',
    value: 'aafq',
    key: 'aafq',
  },
];

const onlineStatusFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'Online',
        value: EdgeStatusEnum.online,
        key: EdgeStatusEnum.online,
      },
      {
        title: 'Offline',
        value: EdgeStatusEnum.offline,
        key: EdgeStatusEnum.offline,
      },
    ],
  },
];

const sensorStatusFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'Healthy',
        value: true,
      },
      {
        title: 'Unhealthy',
        value: false,
      },
    ],
  },
];

const monitoringStatusFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'No Monitor',
        value: 'No_Monitor',
      },
      {
        title: 'Monitor',
        value: 'Monitor',
      },
    ],
  },
];

const storageTypeFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'EMMC',
        value: 'EMMC',
      },
      {
        title: 'SD Card',
        value: 'SD Card',
      },
    ],
  },
];

const MAPlanFilterData = [
  {
    title: 'Select All',
    value: 'MAPlan-all',
    key: 'MAPlan-all',
    children: [
      {
        title: 'Normal',
        value: MAStatusEnum.normal,
        key: MAStatusEnum.normal,
      },
      {
        title: 'Time to Clean',
        value: MAStatusEnum.clean,
        key: MAStatusEnum.clean,
      },
      {
        title: 'Delayed',
        value: MAStatusEnum.delayed,
        key: MAStatusEnum.delayed,
      },
    ],
  },
];

const deviceStatusFilterData = [
  {
    title: 'Select All',
    value: 'device-all',
    key: 'device-all',
    children: ['Active', 'To be installed', 'Suspended', 'Inactive'].map((key) => ({
      title: key,
      value: key,
      key,
    })),
  },
];

const deliveryStatusFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'ส่งมอบ',
        value: 'ส่งมอบ',
      },
      {
        title: 'ยังไม่ส่งมอบ',
        value: 'ยังไม่ส่งมอบ',
      },
    ],
  },
];

const virtualEdgeTypeFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'EDGE',
        value: 'EDGE',
      },
      {
        title: 'Virtual EDGE',
        value: 'Virtual EDGE',
      },
    ],
  },
];

const installedByFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'Installation Team',
        value: 'Installation Team',
      },
      {
        title: 'Customer',
        value: 'Customer',
      },
    ],
  },
];

const deploymentStatusFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'Success',
        value: 'Success',
      },
      {
        title: 'Fail',
        value: 'Fail',
      },
      {
        title: 'Queue',
        value: 'Queue',
      },
    ],
  },
];

const EdgeApplication = () => {
  const {
    getEdgeApplicationsPagination,
    stateEdgeApplications,
    getEdgeStatusDevices,
    stateEdgeStatusDevices,
    exportEdgeApplications,
    getEdgeFilterOptions,
    edgeFilterOptions,
    getThingGroupFilterOptions,
    thingGroupFilterOptions,
  } = useEdgeApplication();
  const { user } = useAuth();
  const { tenants, getTenants } = useUserGroup();
  const { data: tenantsData } = tenants;
  const [exportState, setExportState] = useState({ loading: false, data: null });
  const { edgeApplicationFilters, setEdgeApplicationFilters } = useGlobalHook();
  const [valueMonitor, setValueMonitor] = useState({
    total: 0,
    online: 0,
    offline: 0,
  });
  const [displayMap, setDisplayMap] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 10,
  });
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const [listEdgeApplications, setListEdgeApplications] = useState([]);
  const [filterCriterias, setFilterCriterias] = useState(edgeApplicationFilters);
  const [form] = Form.useForm();

  const selectableSolutions = useMemo(
    () => (user?.adminGroup?.name === 'SSB Call Center' ? solutionsForSSBCallCenter : solutions),
    [user],
  );

  useEffect(() => {
    user.roles.length > 0 && getTenants({ role: get(user, 'roles', []).includes('tenant') && 'tenant' });
  }, [user]);

  useEffect(() => {
    form.setFieldsValue(filterCriterias);
    setEdgeApplicationFilters(filterCriterias);
  }, [filterCriterias]);

  const prepareFilters = (values = {}) => {
    const { deviceStatus, statusMaPlan } = values;
    const filters = { ...filterCriterias, ...values };

    if (deviceStatus?.[0] === 'device-all') {
      filters.deviceStatus = ['Active', 'To be installed', 'Suspended', 'Inactive'];
    }
    if (isFilterChanged) {
      filters.page = 1;
    }
    // Only allow SSB Call Center to see Edge Applications from SSB Solutions
    if (
      user?.adminGroup?.name === 'SSB Call Center' &&
      (filters.solutions?.length === 0 || filters.solutions[0] === '')
    ) {
      filters.solutions = ['solar', 'aaf', 'aaq', 'aafq'];
    }

    if (user?.adminGroup?.name === 'SSB Call Center') {
      filters.businessUnit = 'SSB';
    }

    return filters;
  };

  const onSearch = (values) => {
    const filters = prepareFilters(values);
    setIsFilterChanged(false);
    setFilterCriterias(filters);
    getEdgeApplicationsPagination(filters);
    getEdgeStatusDevices(filters);
  };

  const onExport = async () => {
    setExportState((prev) => ({ ...prev, loading: true }));
    const data = await exportEdgeApplications(prepareFilters());
    for (const element of data) {
      if (element.owned_customer[0].id) {
        element.first_name = element.owned_customer[0].first_name;
        element.last_name = element.owned_customer[0].last_name;
        element.telephone = element.owned_customer[0].telephone;
      } else {
        element.first_name = element.customers[0].first_name;
        element.last_name = element.customers[0].last_name;
        element.telephone = element.customers[0].telephone;
      }
    }

    setExportState((prev) => ({ ...prev, loading: false, data }));
  };

  const getMarkersFromEdgeApplications = (edgeApplications) => {
    let markerItems = [];
    edgeApplications.map((item) => {
      item.addresses
        .filter((address) => address.latitude && address.longitude)
        .map((address) => {
          markerItems.push({
            name: [item.first_name, item.last_name].filter((x) => x).join(' '),
            lat: address.latitude,
            lng: address.longitude,
            status: item.solar_status,
          });
        });
    });
    return markerItems;
  };

  const countMAPlan = (maPlans) => {
    let count = 0;
    maPlans.forEach((maPlan) => {
      if (maPlan.planMADate) {
        const today = moment();
        const maPlanDate = moment(maPlan.planMADate);
        const daysDiff = maPlanDate.diff(today, 'days');
        if (daysDiff <= 30 && daysDiff > 0) {
          count += 1;
        }
      }
    });
    return count;
  };
  useEffect(() => {
    getEdgeFilterOptions();
  }, []);
  useEffect(() => {
    getThingGroupFilterOptions();
  }, []);
  useEffect(() => {
    const filters = {
      ...filterCriterias,
      businessUnit: user?.adminGroup?.name === 'SSB Call Center' ? 'SSB' : null,
      solutions: user?.adminGroup?.name === 'SSB Call Center' ? ['solar', 'aaf', 'aaq', 'aafq'] : null,
    };
    const status = location.hash.match(new RegExp('status=([^&]*)'))?.[1];
    if (status && status !== 'all') {
      filters.onlineStatus = status;
      setFilterCriterias(filters);
    }
    filters.tenantId = get(user, 'tenants[0]', '');
    getEdgeApplicationsPagination(filters);
    getEdgeStatusDevices(filters);
  }, []);

  useEffect(() => {
    if (!stateEdgeStatusDevices.data) return;
    const { total, totalItems, online, offline, dataLoss, maPlans } = stateEdgeStatusDevices.data;
    const maPlan = countMAPlan(maPlans);
    setPagination((prev) => ({ ...prev, total: totalItems }));
    setValueMonitor({
      total,
      online,
      offline,
      dataLoss,
      maPlan,
    });
  }, [stateEdgeStatusDevices.data]);

  useEffect(() => {
    if (!stateEdgeApplications.data) return;
    setListEdgeApplications(stateEdgeApplications.data);
  }, [stateEdgeApplications.data]);

  useMemo(() => {
    setMarkers(getMarkersFromEdgeApplications(listEdgeApplications));
  }, [listEdgeApplications]);

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const tableChange = (pagination, sorter) => {
    const filters = {
      ...filterCriterias,
      page: pagination.current,
      pageSize: pagination.pageSize,
      sort: sorter.order ? `${sorter.columnKey}:${sorter.order.slice(0, -3)}` : '',
    };
    if (
      user?.adminGroup?.name === 'SSB Call Center' &&
      (filters.solutions?.length === 0 || filters.solutions[0] === '')
    ) {
      filters.solutions = ['solar', 'aaf', 'aaq', 'aafq'];
    }

    if (user?.adminGroup?.name === 'SSB Call Center') {
      filters.businessUnit = 'SSB';
    }
    setFilterCriterias(filters);
    getEdgeApplicationsPagination(filters);
  };

  return (
    <Style>
      <Form
        {...layout}
        style={{ marginBottom: 24 }}
        onFinish={onSearch}
        form={form}
        onValuesChange={() => setIsFilterChanged(true)}
      >
        <Row gutter={24} align="top" justify="end">
          <Col flex="auto" style={{ marginBottom: 24 }}>
            <EdgeMonitor {...{ ...valueMonitor, onSearch }} />
          </Col>
          <Col flex="100px" style={{ marginBottom: 24 }}>
            <Button danger onClick={() => setDisplayMap(!displayMap)}>
              {displayMap ? 'Hide Map' : 'View Map'}
            </Button>
          </Col>
        </Row>
        <Row gutter={[24, 12]} align="middle">
          <Col span={2}>
            <label>Solution</label>
          </Col>
          <Col span={6}>
            <Form.Item name="solutions" noStyle>
              <TreeSelect
                showArrow
                treeData={[
                  {
                    title: 'Select All',
                    value: '',
                    children: selectableSolutions,
                  },
                ]}
                treeCheckable
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select Solution"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Device Status</label>
          </Col>
          <Col span={5}>
            <Form.Item name="deviceStatus" noStyle>
              <TreeSelect
                showArrow
                treeData={deviceStatusFilterData}
                treeCheckable
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Online Status</label>
          </Col>
          <Col span={5}>
            <Form.Item name="onlineStatus" noStyle>
              <TreeSelect
                showArrow
                treeData={onlineStatusFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Customer First Name</label>
          </Col>
          <Col span={5}>
            <Form.Item name="customerFirstName" noStyle>
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Customer Last Name</label>
          </Col>
          <Col span={5}>
            <Form.Item name="customerLastName" noStyle>
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Edge ID</label>
          </Col>
          <Col span={5}>
            <Form.Item name="edgeId" noStyle>
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Note</label>
          </Col>
          <Col span={5}>
            <Form.Item name="note" noStyle>
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Ref</label>
          </Col>
          <Col span={5}>
            <Form.Item name="customerReference" noStyle>
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Sensor Status</label>
          </Col>
          <Col span={5}>
            <Form.Item name="sensorStatus" noStyle>
              <TreeSelect
                showArrow
                treeData={sensorStatusFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <label>Monitoring Status</label>
          </Col>
          <Col span={5}>
            <Form.Item name="monitoringStatus" noStyle>
              <TreeSelect
                showArrow
                treeData={monitoringStatusFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Developer</label>
          </Col>
          <Col span={5}>
            <Form.Item name="developer" noStyle>
              <TreeSelect
                showArrow
                treeData={edgeFilterOptions.data?.developers}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Project</label>
          </Col>
          <Col span={5}>
            <Form.Item name="project" noStyle>
              <TreeSelect
                showArrow
                treeData={edgeFilterOptions.data?.projects}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Storage Type</label>
          </Col>
          <Col span={5}>
            <Form.Item name="storageType[]" noStyle>
              <TreeSelect
                showArrow
                treeData={storageTypeFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Delivery Status</label>
          </Col>
          <Col span={5}>
            <Form.Item name="deliveryStatus[]" noStyle>
              <TreeSelect
                showArrow
                treeData={deliveryStatusFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Virtual EDGE</label>
          </Col>
          <Col span={5}>
            <Form.Item name="virtualEdge[]" noStyle>
              <TreeSelect
                showArrow
                treeData={virtualEdgeTypeFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Installed By</label>
          </Col>
          <Col span={5}>
            <Form.Item name="installedBy[]" noStyle>
              <TreeSelect
                showArrow
                treeData={installedByFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Deployment Status</label>
          </Col>
          <Col span={5}>
            <Form.Item name="deploymentStatus" noStyle>
              <TreeSelect
                showArrow
                treeData={deploymentStatusFilterData}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={3}>
            <label>Thing Group</label>
          </Col>
          <Col span={5}>
            <Form.Item name="thingGroup" noStyle>
              <TreeSelect
                showArrow
                treeData={thingGroupFilterOptions.data?.thingGroups}
                treeDefaultExpandAll
                showCheckedStrategy={SHOW_PARENT}
                placeholder="Select All"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          {get(user, 'roles', []).includes('tenant') && (
            <>
              <Col span={3}>
                <label>Tenant</label>
              </Col>
              <Col span={5}>
                <Form.Item name="tenantId" initialValue={+get(user, 'tenants[0]', '')} noStyle>
                  <Select style={{ width: '100%' }}>
                    {tenantsData &&
                      tenantsData.length > 0 &&
                      tenantsData.map((tenant) => (
                        <Option key={tenant.id} value={tenant.id}>
                          {tenant.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={24}>
            <Row justify="end">
              <Space size={10}>
                {exportState.data && !exportState.loading && (
                  <CSVDownload
                    target="_blank"
                    data={exportState.data}
                    asyncOnClick={true}
                    filename="edge-applications.csv"
                    headers={[
                      { key: 'id', label: 'ID' },
                      { key: 'edge_barcode', label: 'Edge Barcode' },
                      { key: 'first_name', label: 'First Name' },
                      { key: 'last_name', label: 'Last Name' },
                      { key: 'telephone', label: 'Telephone' },
                      { key: 'address', label: 'Address' },
                      { key: 'status', label: 'Status' },
                      { key: 'online_status', label: 'Online Status' },
                      { key: 'solar_status', label: 'Device Status' },
                      { key: 'sensor_status', label: 'Health Status' },
                      { key: 'installed_date', label: 'Installed Date' },
                      { key: 'last_seen', label: 'Last Seen' },
                      { key: 'storage_type', label: 'Storage Type' },
                      { key: 'customer_reference', label: 'Customer Reference' },
                      { key: 'note', label: 'Note' },
                      { key: 'deployment_status', label: 'Deployment Status' },
                      { key: 'thing_group', label: 'Thing group' },
                      { key: 'jira_card_number', label: 'Jira Ticket' },
                    ]}
                  />
                )}
                <Button icon={<FileTextOutlined />} loading={exportState.loading} onClick={() => onExport()}>
                  Export
                </Button>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                  Search
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Form>
      <div style={{ display: displayMap ? 'flex' : 'none', flexwidth: '100%', height: 440, margin: '40px 0' }}>
        <GoogleMapContainer markers={markers} />
      </div>

      <EdgeTable
        filters={filterCriterias}
        loading={stateEdgeApplications.isLoading}
        pagination={pagination}
        onChange={tableChange}
        items={listEdgeApplications || []}
      />
    </Style>
  );
};

export default EdgeApplication;
