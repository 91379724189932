import React from "react";
import TextcardTable from "./style";

const TextcardTableNcc = (props) => {
  return (
    <TextcardTable>
      <div className="wrapTitleBar">
        <div className="titleBar">
          <h3>ชื่อ</h3>
          <h3>SCG ID</h3>
          <h3>ประเภทสมาชิก</h3>
          <h3>เบอร์โทรศัพท์</h3>
        </div>
        <div className="wrapContentMemeber">
          {props?.memberHouse.map((data, key) => {
            return (
              <div className="contentMemeber" key={key}>
                <p>{data?.name}</p>
                <p>{data?.scgId}</p>
                <p>{data?.status}</p>
                <p>{data?.tel}</p>
              </div>
            )
          })}
        </div>
      </div>
    </TextcardTable>
  )
}

export default TextcardTableNcc;