import { ReloadOutlined } from '@ant-design/icons';
import { PAGE_REFRESHING_ATOM } from '@root/atoms/dashboard.atom';
import { Button, Col, notification, Row, Select, Typography } from 'antd';
import { useAtom } from 'jotai';
import Actuator from './charts/Actuator';
import DeviceConnectivity from './charts/DeviceConectivity';
import EdgeApplicationMap from './charts/EdgeApplicationMap';
import EdgeStatus from './charts/EdgeStatus';
import Sensor from './charts/Sensor';
import SignalStrength from './charts/SignalStrength';
import ControlForm from './form-control/ControlForm';

const { Title } = Typography;
const { Option } = Select;

const TrinityDashboard = () => {
  const [, setRefresh] = useAtom(PAGE_REFRESHING_ATOM);

  const toMinute = (min) => {
    return min * 1000 * 60;
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
        <Title level={2}>Trinity Dashboard</Title>
        <div>
          <Button
            onClick={() => {
              setRefresh((prev) => ({ ...prev, flag: Date.now() }));
              notification['info']({ message: 'Refreshing...' });
            }}
            icon={<ReloadOutlined />}
          >
            Refresh
          </Button>
          <Select
            onChange={(e) => setRefresh((prev) => ({ ...prev, every: toMinute(e) }))}
            style={{ width: 240 }}
            defaultValue={undefined}
            placeholder="Auto refresh after"
          >
            <Option value={null}>Off</Option>
            <Option value={5}>Auto refresh after: 5m</Option>
            <Option value={10}>Auto refresh after: 10m</Option>
            <Option value={15}>Auto refresh after: 15m</Option>
            <Option value={30}>Auto refresh after: 30m</Option>
          </Select>
        </div>
      </div>
      <Row gutter={[40, 0]}>
        <Col span={5}>
          <EdgeStatus />
        </Col>
        <Col span={5}>
          <Actuator />
        </Col>
        <Col span={10}>
          <SignalStrength />
        </Col>
        <Col span={4}>
          <ControlForm />
        </Col>
      </Row>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '40%', gap: '30px 0', margin: '40px 0' }}>
          <Sensor />
          <DeviceConnectivity />
        </div>
        <EdgeApplicationMap />
      </div>
    </div>
  );
};

export default TrinityDashboard;
