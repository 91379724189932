import { nccCallCenterVerifyToken, refreshToken } from '@root/services/ncc.service';

import { useQuery, useMutation } from 'react-query';
import { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderNcc from '@root/components/ncc/header';
import ErrorCustomerNcc from '@root/components/ncc/errorCustomer';
import styled from 'styled-components';
import SessionExpNcc from '@root/components/ncc/errorSessionExpire';

import CustomerWarpper from './components/CustomerWarpper';

export default function NccPage() {
  const { id } = useParams();
  const [accessTokenNcc, setAccessTokenNcc] = useState([]);
  const [isExpired, setIsExpired] = useState(false);

  const { isLoading, error } = useQuery(['nccCallCenterVerifyToken', id], () => nccCallCenterVerifyToken({ id: id }), {
    retry: false,
    select: (res) => {
      return res.data;
    },
    onSuccess(data) {
      setAccessTokenNcc(data);
    },
  });

  const mutation = useMutation({
    mutationFn: (token) => {
      return refreshToken(token);
    },
    onSuccess(data) {
      setAccessTokenNcc(data.data);
    },
  });

  const handleRefeshToken = useCallback(() => {
    mutation.mutate(accessTokenNcc.refreshToken);
  }, [accessTokenNcc.refreshToken]);

  const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  };

  useEffect(() => {
    if (accessTokenNcc) {
      const interval = setInterval(() => {
        const decode = parseJwt(accessTokenNcc.accessToken);
        const expire = decode.exp;
        const now = Math.floor(Date.now() / 1000);
        const diff = expire - now;
        if (diff < 0) {
          setIsExpired(true);
        }
      }, 1000);

      if (isExpired) {
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [accessTokenNcc, isExpired]);

  if (isLoading) {
    return '';
  }

  if (
    (error?.response?.status === 400 && error?.response?.data?.message === 'id invalid.') ||
    error?.response?.status === 401 ||
    mutation?.error?.response.status === 400 ||
    mutation?.error?.response.status === 401 ||
    isExpired
  ) {
    return (
      <LayoutNccBody>
        <HeaderNcc />
        <SessionExpNcc />
      </LayoutNccBody>
    );
  }

  if (!accessTokenNcc?.accessToken) {
    return (
      <LayoutNccBody>
        <HeaderNcc />
        <ErrorCustomerNcc />
      </LayoutNccBody>
    );
  }

  return (
    <LayoutNccBody>
      <HeaderNcc />
      {/* <NccContent accessToken={data.accessToken} /> */}
      <CustomerWarpper accessToken={accessTokenNcc.accessToken} handleRefeshToken={handleRefeshToken} />
    </LayoutNccBody>
  );
}

const LayoutNccBody = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding-bottom: 208px;
  font-family: 'Sarabun', sans-serif;
`;
