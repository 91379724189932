import React, { useEffect, useState } from 'react';
import { Typography, Row, Button, Select, Input, Col } from 'antd';
import UserTable from '@components/organisms/tables/userTable';
import NewUserModal from '@components/organisms/modals/newUserModal';
import { useUser } from '@store/user/hook';
import { useAuth } from '@store/auth/hook';
import { hasCreateAccess } from '@root/utils/permission';

const { Title, Text } = Typography;

const User = () => {
  const { user } = useAuth();
  const { listUser, getListUser } = useUser();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [ searchBy, setSearchBy] = useState(null);
  const [ searchText, setSearchText] = useState('');
  const [ searchStatus, setSearchStatus] = useState(null);

  const onOpenModal = () => {
    setIsVisibleModal(true);
  };

  const onSubmitModal = () => {
    setIsVisibleModal(false);
  };

  const onCancelModal = () => {
    setIsVisibleModal(false);
  };

  const onSearch = async () => {
    await getListUser({'searchBy': searchBy, 'searchText': searchText, 'status': searchStatus});
  };

  useEffect(() => {
    getListUser();
  }, []);

  const userSearchByOptions = [
    { label: 'Search by', value: null },
    { label: 'Username', value: 'username'},
    { label: 'First name', value: 'firstName'},
    { label: 'Last name', value: 'lastName'},
  ];

  const statusOptions = [
    { label: 'Select all', value: null },
    { label: 'Active', value: 'active'},
    { label: 'Inactive', value: 'inactive'},
  ];

  return (
    <>
      <Title level={2}>Users</Title>
      <Row justify="end" gutter={16} align='middle' style={{'marginBottom': '1rem'}}>
        <Col span={2.5}>
          <Select defaultValue={null} options={userSearchByOptions} onChange={setSearchBy}/>
        </Col>
        <Col span={6}>
          <Input placeholder='Type something to search...' onChange={(e) => setSearchText(e.target.value)}/>
        </Col>
        <Col span={1.5}>
          <Text strong>Status</Text>
        </Col>
        <Col span={2.5}>
          <Select defaultValue={null} options={statusOptions} onChange={setSearchStatus} style={{ width: 120 }}/>
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={onSearch}>
            Search
          </Button>
        </Col>
        <Col flex="auto"></Col>
        {hasCreateAccess(user, 'users') && (
          <Col span={2}>
            <Button type="primary" onClick={onOpenModal}>
              New User
            </Button>
          </Col>
        )}
      </Row>

      <UserTable items={listUser} />
      <NewUserModal visible={isVisibleModal} onSubmit={onSubmitModal} onCancel={onCancelModal} />
    </>
  );
};

export default User;
