import React from 'react';
import { string } from 'prop-types';

import Style from './style';

const Logo = ({ className }) => (
  <Style className={className}>
    <img src="logo-wedo.png" alt="logo" />
  </Style>
);

Logo.propTypes = {
  className: string,
};

export default Logo;
