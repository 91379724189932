import { message } from 'antd';
import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';
import { defaultState } from '../core';
import { airTimeline } from '../edgeApplication/mockData';
import api from './api';

const edgeApplicationContext = createContext({ data: null });

export const useEdgeApplication = () => useContext(edgeApplicationContext);

const useProvideEdgeApplication = () => {
  const [isFetching, setIsFetching] = useState({});
  const [dataSolarOverview, setDataSolarOverview] = useState();
  const [dataSolarCurrentData, setDataSolarCurrentData] = useState();
  const [dataSolarHistory, setDataSolarHistory] = useState();
  const [dataSolarStatistic, setDataSolarStatistic] = useState();
  const [dataEdge, setDataEdge] = useState();
  const [dataTodayInformation, setDataTodayInformation] = useState();
  const [dataActiveAirFlowTodayInformation, setDataActiveAirFlowTodayInformation] = useState();
  const [dataActiveAirFlowStatistic, setDataActiveAirFlowStatistic] = useState();
  const [dataActiveAirQualityStatistic, setDataActiveAirQualityStatistic] = useState();
  const [dataActiveAirQualityLocations, setDataActiveAirQualityLocations] = useState();
  const [dataEdgeDeviceLocations, setEdgeDeviceLocations] = useState();
  const [dataActiveAirQualityTodayInformation, setDataActiveAirQualityTodayInformation] = useState();
  const [stateListEdge, setStateListEdge] = useState(defaultState);
  const [stateTokenEdgeApplicationForm, setStateTokenEdgeApplicationForm] = useState(defaultState);
  const [stateEdgeStatusDevices, setStateEdgeStatusDevices] = useState(defaultState);
  const [summaryEdge, setSummaryEdge] = useState({
    all: 0,
    online: 0,
    offline: 0,
    dataLoss: 0,
    maPlan: 0,
  });
  const [edgeDashboard, setEdgeDashboard] = useState();
  const [stateEdgeApplications, setStateEdgeApplications] = useState(defaultState);
  const [countEdgeApplications, setCountEdgeApplications] = useState(defaultState);
  const [edgeComponents, setEdgeComponents] = useState(defaultState);
  const [solarInverter, setSolarInverter] = useState(defaultState);
  const [solarPowerMeter, setSolarPowerMeter] = useState(defaultState);
  const [associateDevice, setAssociateDevice] = useState(defaultState);
  const [listActuator, setListActuator] = useState(defaultState);
  const [edgeFilterOptions, setEdgeFilterOptions] = useState(defaultState);
  const [thingGroupFilterOptions, setThingGroupFilterOptions] = useState(defaultState);
  const [stateNewJiraCard, setStateNewJiraCard] = useState(defaultState);

  const handleIsFetching = (type, value) => {
    setIsFetching((prev) => ({ ...prev, [type]: value }));
  };

  const getListEdgeApplication = async (params, cb = () => {}) => {
    setStateListEdge((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getListEdgeApplication(params);
    if (res.status === '200') {
      setStateListEdge((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateListEdge((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const getEdgeApplicationsPagination = async (params, cb = () => {}) => {
    setStateEdgeApplications((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getEdgeApplicationsPagination(params);
    if (res.status === '200') {
      setStateEdgeApplications((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data.map((e, i) => ({ ...e, index: i })),
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateEdgeApplications((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const exportEdgeApplications = async (params, cb = () => {}) => {
    const res = await api.exportEdgeApplications(params);
    if (res.status === '200') {
      return res.data;
    }
    message.error(res.message || 'Something went wrong!');
    return [];
  };

  const getEdgeStatusDevices = async (params, cb = () => {}) => {
    setStateEdgeStatusDevices((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getEdgeStatusDevices(params);
    if (res.status === '200') {
      setStateEdgeStatusDevices((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateEdgeStatusDevices((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const getCountEdgeApplications = async (params, cb = () => {}) => {
    setCountEdgeApplications((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getCountEdgeApplications(params);
    if (res.status === '200') {
      setCountEdgeApplications((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setCountEdgeApplications((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const getOneEdgeApplication = async (edgeBarcode, cb = () => {}) => {
    const res = await api.getOneEdgeApplication(edgeBarcode);
    if (res.status === '200') {
      setDataEdge(res.data);
    } else {
      message.error('Not found');
    }
  };

  const getSummaryEdgeApplication = async (cb = () => {}) => {
    const res = await api.getSummaryEdgeApplication();
    if (res.status === '200') {
      setSummaryEdge(res.data);
    } else {
      console.error("Can't fetch summary edge devices");
    }
  };

  const getSolarOverview = async (edgeBarcode, cb = () => {}) => {
    const res = await api.getSolarOverview(edgeBarcode);
    if (res.status === '200') {
      setDataSolarOverview(res.data);
    } else {
      console.error("Can't fetch solar overview");
    }
  };

  const getSolarCurrentData = async (edgeBarcode, cb = () => {}) => {
    const res = await api.getSolarCurrentData(edgeBarcode);
    if (res.status === '200') {
      setDataSolarCurrentData(res.data);
    } else {
      console.error("Can't fetch solar current");
    }
  };

  const getSolarHistory = async (edgeBarcode, filter, cb = () => {}) => {
    setDataSolarHistory();

    const res = await api.getSolarHistory(edgeBarcode, filter);
    if (res.status === '200') {
      setDataSolarHistory(res.data);
    } else {
      console.error("Can't fetch solar history");
    }
  };

  const getSolarStatistic = async (edgeBarcode, filter, cb = () => {}) => {
    setDataSolarStatistic();

    const res = await api.getSolarStatistic(edgeBarcode, filter);
    if (res.status === '200') {
      setDataSolarStatistic(res.data);
    } else {
      console.error("Can't fetch solar statistic");
    }
  };

  const getTodayInformation = async (edgeBarcode, cb = () => {}) => {
    setDataTodayInformation();

    const res = await api.getTodayInformation(edgeBarcode);
    if (res.status === '200') {
      setDataTodayInformation(res.data);
    } else {
      console.error("Can't fetch solar today information");
    }
  };

  const getActiveAirFlowTodayInformation = async (edgeBarcode, area, cb = () => {}) => {
    setDataActiveAirFlowTodayInformation();

    handleIsFetching('dataActiveAirFlowTodayInformation', true);
    const res = await api.getActiveAirFlowTodayInformation(edgeBarcode, area);
    handleIsFetching('dataActiveAirFlowTodayInformation', false);
    if (res.status === '200') {
      setDataActiveAirFlowTodayInformation(res.data);
    } else {
      console.error("Can't fetch AAF today information");
    }
  };

  const getActiveAirFlowStatistic = async (edgeBarcode, filter, cb = () => {}) => {
    let filterIndoor = { ...filter, area: 'indoor' };
    let filterOutdoor = { ...filter, area: 'outdoor' };
    let filterAttic = { ...filter, area: 'attic' };
    const resIndoor = await api.getActiveAirFlowStatistic(edgeBarcode, filterIndoor);
    const resOutdoor = await api.getActiveAirFlowStatistic(edgeBarcode, filterOutdoor);
    const resAttic = await api.getActiveAirFlowStatistic(edgeBarcode, filterAttic);
    var result = [...(resIndoor?.data || []), ...(resOutdoor?.data || []), ...(resAttic?.data || [])];
    if (resIndoor.status === '200' || resOutdoor.status === '200' || resAttic.status === '200') {
      setDataActiveAirFlowStatistic((prev) => ({
        ...prev,
        [filter.data_types]: result.length > 0 ? result : airTimeline,
      }));
    } else {
      console.error("Can't fetch AAF statistic");
    }
  };

  const getActiveAirQualityLocations = async (edgeBarcode, cb = () => {}) => {
    setDataActiveAirQualityLocations();

    const res = await api.getActiveAirQualityLocations(edgeBarcode);

    if (res.status === '200') {
      if (res.data.is_afq) setDataActiveAirQualityLocations(res.data.applications.active_airflow.locations);
      else setDataActiveAirQualityLocations(res.data.applications.active_airquality.locations);
    } else {
      console.error("Can't fetch AAQ locations");
    }
  };

  const getEdgeDeviceLocations = async (edgeBarcode, cb = () => {}) => {
    setEdgeDeviceLocations();

    const res = await api.getEdgeDeviceLocations(edgeBarcode);
    if (res.status === '200') {
      setEdgeDeviceLocations(res.data);
    } else {
      console.error("Can't fetch edge device locations");
    }
  };

  const getActiveAirQualityTodayInformation = async (edgeBarcode, area, cb = () => {}) => {
    setDataActiveAirQualityTodayInformation();

    handleIsFetching('dataActiveAirQualityTodayInformation', true);
    const res = await api.getActiveAirQualityTodayInformation(edgeBarcode, area);
    handleIsFetching('dataActiveAirQualityTodayInformation', false);
    if (res.status === '200') {
      setDataActiveAirQualityTodayInformation(res.data);
    } else {
      console.error("Can't fetch AAQ today information");
    }
  };

  const getActiveAirQualityStatistic = async (edgeBarcode, filter, cb = () => {}) => {
    const res = await api.getActiveAirQualityStatistic(edgeBarcode, filter);
    if (res.status === '200') {
      setDataActiveAirQualityStatistic((prev) => ({
        ...prev,
        [filter.data_types]: res.data.length > 0 ? res.data : airTimeline,
      }));
    } else {
      console.error("Can't fetch AAQ statistic");
    }
  };

  const updateTokenEdgeApplication = async (edgeBarcode, form, cb = () => {}) => {
    setStateTokenEdgeApplicationForm((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));

    const res = await api.updateTokenEdgeApplication(edgeBarcode, form);
    if (res.status === '200') {
      message.success('Success to update access token');
      setStateTokenEdgeApplicationForm({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: null,
      });
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateTokenEdgeApplicationForm({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const getEdgeDashboard = async (edgeBarcode, cb = () => {}) => {
    const res = await api.getEdgeDashboard(edgeBarcode);
    if (res.status === '200') {
      setEdgeDashboard({ applications: [], ...res.data });
    } else {
      console.error("Can't fetch edge dashboard");
    }
  };

  const getEdgeComponents = async (edgeBarcode, cb = () => {}) => {
    setEdgeComponents((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));

    const res = await api.getEdgeComponents(edgeBarcode);
    if (res.status === '200') {
      setEdgeComponents({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
    } else {
      setEdgeComponents({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
      console.error("Can't fetch edge components");
    }
  };

  const getSolarInverter = async (edgeBarcode, cb = () => {}) => {
    setSolarInverter((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));

    const res = await api.getSolarInverter(edgeBarcode);
    if (res.status === '200') {
      setSolarInverter({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: {
          inverter_status: res.data.rows[0].inverter_status,
          inverter_last_seen: res.data.rows[0].inverter_last_seen,
        },
      });
    } else {
      setSolarInverter({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
      console.error("Can't fetch solar inverter");
    }
  };

  const getSolarPowerMeter = async (edgeBarcode, cb = () => {}) => {
    setSolarPowerMeter((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));

    const res = await api.getSolarPowerMeter(edgeBarcode);
    if (res.status === '200') {
      setSolarPowerMeter({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: {
          power_meter_status: res.data.rows[0].power_meter_status,
          power_meter_last_seen: res.data.rows[0].power_meter_last_seen,
        },
      });
    } else {
      setSolarPowerMeter({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
      console.error("Can't fetch solar power meter");
    }
  };

  const getAssociateDevice = async (edgeBarcode, params, cb = () => {}) => {
    setAssociateDevice((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));

    const res = await api.getAssociateDevice(edgeBarcode, params);
    if (res.status === '200') {
      setAssociateDevice({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
    } else {
      setAssociateDevice({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
      console.error("Can't fetch associate device");
    }
  };

  const getListActuator = async (params, cb = () => {}) => {
    setListActuator((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getListActuator(params);
    if (res.status === '200') {
      setListActuator((prev) => ({
        ...prev,
        data: res.data,
        isLoading: false,
        isSuccess: true,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setListActuator((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const deleteEdgeApplication = async (edgeBarcode) => {
    const res = await api.deleteEdgeApplication(edgeBarcode);
    if (res.status === '200') {
      message.success('Delete success');
    } else if (res.httpStatus === 400) {
      message.error('Cannot delete edge with active status');
    } else {
      message.error('Not found');
    }
  };

  const formatFilterOptions = async (filterObject) => {
    let developerFilter = {
      title: 'Select All',
      value: '',
      children: [],
    };
    let projectFilter = {
      title: 'Select All',
      value: '',
      children: [],
    };
    let developerOptions = [];
    if (filterObject.developers && filterObject.developers.length) {
      filterObject.developers.forEach((developer) => {
        developerOptions.push({
          title: developer,
          value: developer,
          key: developer,
        });
      });
      developerFilter.children = developerOptions;
    }
    let projectOptions = [];
    if (filterObject.projects && filterObject.projects.length) {
      filterObject.projects.forEach((project) => {
        projectOptions.push({
          title: project,
          value: project,
          key: project,
        });
      });
      projectFilter.children = projectOptions;
    }
    return { developers: [developerFilter], projects: [projectFilter] };
  };

  const getEdgeFilterOptions = async (params, cb = () => {}) => {
    setEdgeFilterOptions((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getEdgeFilterOptions(params);
    if (res.status === '200') {
      let formattedFilters = await formatFilterOptions(res.data);
      setEdgeFilterOptions((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: formattedFilters,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setEdgeFilterOptions((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const formatThingGroupFilterOptions = async (filterObject) => {
    let thingGroupFilter = {
      title: 'Select All',
      value: '',
      children: [],
    };
 
    let thingGroupOptions = [];
    if (filterObject && filterObject.length) {
      filterObject.forEach((thingGroup) => {
        thingGroupOptions.push({
          title: thingGroup.groupName,
          value: thingGroup.groupName,
          key: thingGroup.groupName,
        });
      });
      thingGroupFilter.children = thingGroupOptions;
    }
    
    return { thingGroups: [thingGroupFilter]};
  };

  const getThingGroupFilterOptions = async (params, cb = () => {}) => {
    setThingGroupFilterOptions((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getThingGroupFilterOptions(params);
    if (res.status === '200') {
      let formattedFilters = await formatThingGroupFilterOptions(res.data);
      setThingGroupFilterOptions((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: formattedFilters,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setThingGroupFilterOptions((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const newJiraCard = async (edgeBarcode, form, cb = () => {}) => {
    setStateNewJiraCard((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.newJiraCard(edgeBarcode, {"jira_card_number": form.card_number});
    if (res.status === '200') {
      message.success('Successfully added Jira card!');
      setStateNewJiraCard({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateNewJiraCard({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  return {
    isFetching,
    dataEdge,
    stateListEdge,
    countEdgeApplications,
    stateEdgeApplications,
    summaryEdge,
    dataSolarOverview,
    dataSolarCurrentData,
    dataSolarHistory,
    dataSolarStatistic,
    dataTodayInformation,
    stateTokenEdgeApplicationForm,
    stateEdgeStatusDevices,
    getSolarOverview,
    getSolarCurrentData,
    getSolarHistory,
    getSolarStatistic,
    getListEdgeApplication,
    getOneEdgeApplication,
    getSummaryEdgeApplication,
    getTodayInformation,
    updateTokenEdgeApplication,
    dataActiveAirFlowTodayInformation,
    dataActiveAirFlowStatistic,
    dataActiveAirQualityLocations,
    dataEdgeDeviceLocations,
    dataActiveAirQualityTodayInformation,
    dataActiveAirQualityStatistic,
    getActiveAirFlowTodayInformation,
    getActiveAirFlowStatistic,
    getActiveAirQualityLocations,
    getEdgeDeviceLocations,
    getActiveAirQualityTodayInformation,
    getActiveAirQualityStatistic,
    edgeDashboard,
    getEdgeDashboard,
    getEdgeApplicationsPagination,
    getCountEdgeApplications,
    getEdgeStatusDevices,
    edgeComponents,
    getEdgeComponents,
    solarInverter,
    getSolarInverter,
    solarPowerMeter,
    getSolarPowerMeter,
    associateDevice,
    getAssociateDevice,
    exportEdgeApplications,
    listActuator,
    getListActuator,
    deleteEdgeApplication,
    getEdgeFilterOptions,
    edgeFilterOptions,
    getThingGroupFilterOptions,
    thingGroupFilterOptions,
    newJiraCard,
    stateNewJiraCard
  };
};

export const ProvideEdgeApplication = ({ children }) => {
  const roof = useProvideEdgeApplication();

  return <edgeApplicationContext.Provider value={roof}>{children}</edgeApplicationContext.Provider>;
};

ProvideEdgeApplication.propTypes = {
  children: PropTypes.node,
};

// const EdgeMonitorContext = createContext({});
//
// export const useEdgeMonitor = () => useContext(EdgeMonitorContext);
//
// const useProvideEdgeMonitor = () => {
//   const [edgeMonitorFilter, setEdgeMonitorFilter] = useState();
//
//   return {
//     edgeMonitorFilter,
//     setEdgeMonitorFilter,
//   };
// };
//
// export const EdgeMonitorHook = ({ children }) => {
//   const roof = useProvideEdgeMonitor();
//
//   return <EdgeMonitorContext.Provider value={roof}>{children}</EdgeMonitorContext.Provider>;
// };
//
// EdgeMonitorHook.propTypes = {
//   children: PropTypes.node,
// };
