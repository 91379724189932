import React, { useEffect } from 'react';
import { Breadcrumb, Col, Row, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useEditEdgeWifi } from '@store/edgeWifi/hooks/useEditEdgeWifi';
import EditEdgeWifiForm from '@components/organisms/forms/editEdgeWifiForm';

const CustomerEdit = () => {
  const { id } = useParams();
  const { data, isLoading, getData, updateData } = useEditEdgeWifi();

  const onSubmitForm = (values) => {
    updateData(id, values);
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={2}>Edge Wifi</Typography.Title>
      </Col>

      <Col span={24}>
        <Breadcrumb style={{ marginBottom: 24 }}>
          <Breadcrumb.Item>
            <Link to="/dashboard/edge-wifi">List view</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>ID: {id}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>

      <Col span={24}>
        <EditEdgeWifiForm data={data} isLoading={isLoading} btnCancel={false} onFinish={onSubmitForm} />
      </Col>
    </Row>
  );
};

export default CustomerEdit;
