import React, { useState } from 'react';
import config from '@config';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import defaultMapStyles from './defaultMapStyles.json'
import MarkerGreen from '@assets/marker-green.ico';
import MarkerOrange from '@assets/marker-orange.ico';
import MarkerRed from '@assets/marker-red.ico';
import { EdgeStatusEnum } from '@services/edgeApplication';
import PropTypes from 'prop-types';

const GoogleMapContainer = ({ markers }) => {
  const [showInfoWindow, setShowInfoWindow] = useState({});

  const center = () => {
    return {
      lat: 13.8037211,
      lng: 100.4890963
    };
    /*const [marker] = markers;
    if (!marker) {
      return {
        lat: 13.7251088,
        lng: 100.3529068
      };
    }
    return {
      lat: marker.lat,
      lng: marker.lng,
    };*/
  };

  const markerIcon = (marker) => {
    switch (marker.status.toLowerCase()) {
      case EdgeStatusEnum.online:
        return MarkerGreen;
      case EdgeStatusEnum.offline:
        return MarkerOrange;
      case EdgeStatusEnum.dataLoss:
        return MarkerOrange;
      default:
        return MarkerRed;
    }
  };
  const defaultMapOptions = {
    styles: defaultMapStyles
  };
  return (
    <LoadScript googleMapsApiKey={config.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={center()} zoom={10.31}
      options = {defaultMapOptions}>
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={{
              lat: marker.lat,
              lng: marker.lng,
            }}
            onClick={(e) => {
              setShowInfoWindow((prev) => ({ ...prev, [index]: !prev[index] }));
            }}
            icon={markerIcon(marker)}
            title={marker.name}
          >
            {showInfoWindow[index] && (
              <InfoWindow
                onCloseClick={() => {
                  setShowInfoWindow((prev) => ({ ...prev, [index]: false }));
                }}
              >
                <h1>{marker.name}</h1>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

GoogleMapContainer.propTypes = {
  markers: PropTypes.array,
};

export default GoogleMapContainer;
