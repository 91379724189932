export const userRoleTree = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    children: [
      {
        key: 'dashboard/read',
        title: 'Dashboard',
      },
    ],
  },
  {
    key: 'users',
    title: 'Users',
    children: [
      {
        key: 'users/create',
        title: 'Users Create',
      },
      {
        key: 'users/update',
        title: 'Users Update',
      },
      {
        key: 'users/read',
        title: 'Users Read',
      },
    ],
  },
  {
    key: 'edge-applications',
    title: 'Edge Applications',
    children: [
      {
        key: 'edge-applications/read',
        title: 'Edge Applications Read List',
        // children: [
        //   {
        //     key: 'edge-applications/read/mind',
        //     title: 'Edge Applications Read List - MIND',
        //   },
        //   {
        //     key: 'edge-applications/read/ssb',
        //     title: 'Edge Applications Read List - SSB',
        //   },
        // ],
      },

      {
        key: 'edge-applications/read-child',
        title: 'Edge Applications Read Details',
      },
      {
        key: 'edge-applications/delete',
        title: 'Edge Applications Delete',
      },
      {
        key: 'edge-applications/update',
        title: 'Edge Applications Update',
      },
    ],
  },
  {
    key: 'edge-data',
    title: 'Edge Data',
    children: [
      {
        key: 'edge-data/update',
        title: 'Edge Data Update',
      },

      {
        key: 'edge-data/delete',
        title: 'Edge Data Delete',
      },
    ],
  },
  {
    key: 'edge-associate-devices',
    title: 'Associate Devices',
    children: [
      {
        key: 'edge-associate-devices/read',
        title: 'Associate Devices Read',
      },
    ],
  },
  {
    key: 'customers',
    title: 'Customers',
    children: [
      {
        key: 'customers/create',
        title: 'Customers Create',
      },
      {
        key: 'customers/update',
        title: 'Customers Update',
      },
      {
        key: 'customers/read',
        title: 'Customers Read',
      },
    ],
  },
  {
    key: 'edge-wifi',
    title: 'Edge Wifi',
    children: [
      {
        key: 'edge-wifi/update',
        title: 'Edge Wifi Update',
      },
      {
        key: 'edge-wifi/read',
        title: 'Edge Wifi Read',
      },
    ],
  },
  {
    key: 'user-groups',
    title: 'User Groups',
    children: [
      {
        key: 'user-groups/create',
        title: 'User Groups Create',
      },
      {
        key: 'user-groups/update',
        title: 'User Groups Update',
      },
      {
        key: 'user-groups/read',
        title: 'User Groups Read',
      },
    ],
  },
  {
    key: 'scg-id',
    title: 'SCG ID',
    children: [
      {
        key: 'scg-id/read',
        title: 'SCG ID Read',
      },
      {
        key: 'scg-id/create',
        title: 'SCG ID Create',
      },
      {
        key: 'scg-id/update',
        title: 'SCG ID Transfer',
      },
      {
        key: 'scg-id/delete',
        title: 'SCG ID Remove',
      },
    ],
  },
  {
    key: 'log-management',
    title: 'Log Management',
    children: [
      {
        key: 'log-management/read',
        title: 'Log Management Read',
      },
    ],
  },
  {
    key: 'call-center-portal',
    title: 'Call Center Portal',
    children: [
      {
        key: 'call-center-portal/read',
        title: 'Call Center Portal Read',
      },
    ],
  },
];
