import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Modal, Form, Input, Space, Row, Button } from 'antd';

import { useEdgeApplication } from '@store/edgeApplication/hook';

const EdgeApplicationTokenModal = ({ visible, onSubmit, onCancel, value }) => {
  const [form] = Form.useForm();
  const { edge_barcode } = useParams();

  const { stateTokenEdgeApplicationForm, updateTokenEdgeApplication } = useEdgeApplication();
  const { isLoading, isSuccess } = stateTokenEdgeApplicationForm;

  const onFinish = (values) => {
    updateTokenEdgeApplication(edge_barcode, values);
  };

  useEffect(() => {
    form.setFieldsValue({
      token: value,
    });
  }, [value]);

  useEffect(() => {
    if (isSuccess) {
      onSubmit();
    }
  }, [isSuccess]);

  return (
    <Modal title="Access Token" visible={visible} onOk={onSubmit} onCancel={onCancel} footer={null}>
      <Form form={form} onFinish={onFinish} scrollToFirstError layout="vertical">
        <Form.Item name="token" label="Key">
          <Input />
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={onCancel}>Cancel</Button>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                Submit
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

EdgeApplicationTokenModal.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  value: PropTypes.string,
};

EdgeApplicationTokenModal.defaultProps = {
  visible: false,
  value: null,
  onSubmit: () => {},
  onCancel: () => {},
};

export default EdgeApplicationTokenModal;
