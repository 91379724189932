import axios from 'axios';
import config from '@root/config';

let baseURL = config.API_ENDPOINT;

export const nccCallCenterVerifyToken = async (body) => {
  const res = await axios.post(`${baseURL}` + '/ncc/verify', body);
  return res;
};

export const refreshToken = async (refreshToken) => {
  const res = await axios.post(`${baseURL}` + '/ncc/refresh', null, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
  return res;
};

export const fetchCustomer = (accessToken) => {
  return axios.get(`${baseURL}/ncc/customer`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
