import React, { useEffect, useRef, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import domtoimage from 'dom-to-image';

const QrCodeImg = (props) => {
  const canvasRef = useRef(null);
  const [showQr, setShowQr] = useState(true);
  const { value, ...rest } = props;

  useEffect(() => {
    if (showQr) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      var node = document.getElementById('qrCodeCanvas');

      domtoimage
        .toPng(node, { quality: 0.99, width: 400, height: 400 })
        .then(function (dataUrl) {
          let img = new Image();
          img.src = dataUrl;

          img.onload = function () {
            context.fillStyle = '#fff';
            context.fillRect(0, 0, context.canvas.width, context.canvas.height);
            context.drawImage(img, 0, 0);
            context.font = '24px Arial';
            context.textBaseline = 'top';
            context.fillStyle = '#000';
            context.fillText('Edge Id: ' + (value.edge_barcode ? value.edge_barcode : '-'), 0, 430);
            context.fillText(
              'Customer: ' +
                (value.first_name ? value.first_name : '-') +
                ' ' +
                (value.last_name ? value.last_name : ''),
              0,
              480,
            );

            setShowQr(false);
          };
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
    }
  }, []);

  return (
    <>
      <canvas ref={canvasRef} {...rest}>
        ; Your browser does not support the HTML5 canvas tag.
      </canvas>
      {showQr ? <QRCodeCanvas id="qrCodeCanvas" value={value.edge_barcode} size={400} /> : <></>}
    </>
  );
};

export default QrCodeImg;
