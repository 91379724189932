import { Pie } from '@ant-design/charts';
import { PAGE_REFRESHING_ATOM, TRINITY_DASHBOARD_ATOM } from '@root/atoms/dashboard.atom';
import { fetchActuatorStatus } from '@root/services/dashboard.service';
import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { configActuatorChart } from '../../chartConfig';
import Styled from './style';

const Actuator = () => {
  const [refresh] = useAtom(PAGE_REFRESHING_ATOM);
  const [params] = useAtom(TRINITY_DASHBOARD_ATOM);

  const { data, isLoading, isFetching } = useQuery(
    ['actuator-status', params, refresh],
    () => fetchActuatorStatus(params),
    {
      refetchInterval: refresh.every,
    },
  );

  const dataActuator = useMemo(() => {
    const result = data?.data;
    if (result) {
      return {
        total: result.total,
        data: [
          { type: 'working', value: +result.working },
          { type: 'not working', value: +result['not working'] },
        ],
      };
    }
  }, [data]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <Styled>
        <h2>Actuator</h2>
        {dataActuator && <Pie animation={false} {...configActuatorChart(dataActuator.data)} />}
        <h3>Total: {dataActuator?.total}</h3>
      </Styled>
    </Spin>
  );
};

export default Actuator;
