import CustomerTable from '@components/organisms/tables/customerTable';
import { CUSTOMER_SEARCH_OPTIONS } from '@root/constants/mocks.constant';
import { fetchCustomers, fetchTotalCustomer } from '@root/services/customers.service';
import { hasCreateAccess } from '@root/utils/permission';
import { useAuth } from '@store/auth/hook';
import { Button, Form, Input, Pagination, Row, Select, Typography } from 'antd';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

const { Title } = Typography;
const { Option } = Select;

const Customer = () => {
  const { user } = useAuth();
  const [form] = Form.useForm();

  const [query, setQuery] = useState({
    page: 1,
    pageSize: 10,
    searchBy: '',
    searchValue: '',
    sort: [],
  });

  const { data: total } = useQuery('total-customer', () => fetchTotalCustomer());

  const { isLoading, data: customers } = useQuery(
    ['customers', query],
    () => {
      const { page, pageSize, searchBy, searchValue, sort } = query;
      return fetchCustomers({ page, pageSize, sort }, searchBy, searchValue);
    },
    {
      retry: false,
      onError: () => {
        toast.error('Not found');
        setQuery((prev) => ({ ...prev, searchBy: '', searchValue: '' }));
        form.resetFields();
      },
    },
  );

  const handleSubmitSearch = (values) => {
    const { searchBy, searchValue } = values;
    if (!searchBy && !searchValue) {
      return;
    }
    setQuery((prev) => ({ ...prev, page: 1, searchBy, searchValue }));
  };

  const showClearButton = useMemo(() => {
    const { searchBy, searchValue } = query;
    return searchBy && searchValue;
  }, [query.searchBy, query.searchValue]);

  const handleClear = () => {
    form.resetFields();
    setQuery({ page: 1, pageSize: 10, searchBy: '', searchValue: '', sort: [] });
  };

  return (
    <>
      <Title level={2}>Customers</Title>
      {hasCreateAccess(user, 'customers') && (
        <Form onFinish={handleSubmitSearch} form={form}>
          <Row justify="end" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 9 }}>
            <Form.Item name="searchBy" noStyle>
              <Select defaultValue="Search by" style={{ width: 200 }}>
                {CUSTOMER_SEARCH_OPTIONS.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="searchValue" noStyle>
              <Input style={{ width: 300 }} placeholder="Type something to search..." />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
            <Button onClick={handleClear} hidden={!showClearButton} type="ghost">
              Clear
            </Button>
          </Row>
        </Form>
      )}
      <CustomerTable items={customers?.data || []} loading={isLoading} setQuery={setQuery} />
      <Row style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
        <Pagination
          defaultCurrent={1}
          current={query.page}
          pageSize={query.pageSize}
          total={query.searchBy && customers?.data.length <= 10 ? customers?.data.length : total?.data.results}
          onChange={(e) => setQuery((prev) => ({ ...prev, page: e }))}
        />
      </Row>
    </>
  );
};

export default Customer;
