import { fetchProvinces } from '@root/services/dashboard.service';
import { Button, Form, Input, Row, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { validateNotOnlySpaces } from '@root/utils/validator';

const { Option } = Select;

const EditCustomerForm = (props) => {
  const { data, onFinish, isLoading } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const { isLoading: isGettingProvinces, data: provinces } = useQuery('provinces', () => fetchProvinces());

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFormFinish = (values) => {
    onFinish(values);
  };

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue(data);
  }, [data?.id]);

  return (
    <Spin spinning={isLoading}>
      <Form form={form} onFinish={onFormFinish} scrollToFirstError layout="horizontal" {...layout}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: 10 }}>
          <Form.Item name="reference" label="Ref">
            <Input />
          </Form.Item>
          <Form.Item
            name="telephone"
            label="Mobile no"
            rules={[{ pattern: new RegExp(/^0+\d{9}$/), message: 'No Mobile no. pattern' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="title_name"
            label="Title name"
            rules={[
              { required: true, message: 'Please enter title name' },
              { validator: validateNotOnlySpaces('Please input your title name!') },
              { max: 70, message: 'Maximum length is 70 characters' },
              {
                pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
                message: 'The title name is not in a valid format.',
              },
            ]}
          >
            <Input max={70} />
          </Form.Item>
          <Form.Item
            name="first_name"
            label="First name"
            rules={[
              { required: true, message: 'Please enter first name' },
              { validator: validateNotOnlySpaces('Please input your first name!') },
              { max: 70, message: 'Maximum length is 70 characters' },
              {
                pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
                message: 'The first name is not in a valid format.',
              },
            ]}
          >
            <Input max={70} />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last name"
            rules={[
              { required: true, message: 'Please enter last name' },
              { validator: validateNotOnlySpaces('Please input your last name!') },
              { max: 70, message: 'Maximum length is 70 characters' },
              {
                pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
                message: 'The last name is not in a valid format.',
              },
            ]}
          >
            <Input max={70} />
          </Form.Item>
          <Form.Item
            name="street_address"
            label="Address"
            rules={[
              { max: 150, message: 'Maximum length is 150 characters' },
              {
                pattern: new RegExp(/^[ก-๙a-zA-Z\d\s-_()[\]/.]+$/),
                message: 'text, number and some spacial character(-_()[]./)',
              },
            ]}
          >
            <Input max={150} />
          </Form.Item>
          <Form.Item
            name="road"
            label="Road"
            rules={[
              { max: 70, message: 'Maximum length is 70 characters' },
              {
                pattern: new RegExp(/^[ก-๙a-zA-Z\d\s-_()[\].]+$/),
                message: 'text, number and some spacial character(-_()[].)',
              },
            ]}
          >
            <Input max={70} />
          </Form.Item>
          <Form.Item
            name="sub_district"
            label="Sub-District"
            rules={[
              { max: 70, message: 'Maximum length is 70 characters' },
              {
                pattern: new RegExp(/^[ก-๙a-zA-Z]+$/),
                message: 'text only',
              },
            ]}
          >
            <Input max={70} />
          </Form.Item>
          <Form.Item name="province" label="Province">
            <Select loading={isGettingProvinces} placeholder="Province" style={{ width: '100%' }}>
              {(provinces?.data || []).map((province) => (
                <Option key={province.id} value={province.NameThai}>
                  {province.NameThai}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[
              { max: 70, message: 'Maximum length is 70 characters' },
              {
                pattern: new RegExp(/^[ก-๙a-zA-Z]+$/),
                message: 'text only',
              },
            ]}
          >
            <Input defaultValue="ไทย" max={70} />
          </Form.Item>
          <Form.Item
            name="postal_code"
            label="Postal Code"
            rules={[
              {
                pattern: new RegExp(/^\d{5}$/),
                message: '5 Number only',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="latitude" label="Latitude" rules={[{ required: true, message: 'Please enter latitude' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="longitude" label="Longitude" rules={[{ required: true, message: 'Please enter longitude' }]}>
            <Input type="number" />
          </Form.Item>
        </div>
        <Row style={{ display: 'flex', justifyContent: 'end', gap: 15 }}>
          <Button type="primary" htmlType="submit" style={{ width: 100 }}>
            Save
          </Button>
          <Button onClick={() => history.goBack()} style={{ width: 100 }} type="default">
            Cancel
          </Button>
        </Row>
      </Form>
    </Spin>
  );
};

EditCustomerForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, null]),
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  btnCancel: PropTypes.bool,
};

EditCustomerForm.defaultProps = {
  data: null,
  onFinish: () => {},
  onCancel: () => {},
  isLoading: false,
  btnCancel: true,
};

export default EditCustomerForm;
