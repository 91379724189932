import { instance } from '@root/utils/axios';

export const fetchEdgeStatus = async (params) => {
  return instance.get('dashboard/edge-status', {
    params: {
      ...params,
    },
  });
};

export const fetchEdgeHealth = async (params) => {
  return instance.get('dashboard/edge-health', {
    params: {
      ...params,
    },
  });
};

export const fetchEdgeApplications = async (params) => {
  return instance.get('edge-applications', {
    params: {
      ...params,
    },
  });
};

export const fetchRegions = async () => {
  return instance.get('dashboard/regions');
};

export const fetchProvinces = async (regionId) => {
  return instance.get('dashboard/provinces/', {
    params: {
      regionId,
    },
  });
};

export const fetchDeviceConnectivity = async (params) => {
  return instance.get('dashboard/device-connectivity', {
    params,
  });
};

export const fetchActuatorStatus = async (params) => {
  return instance.get('dashboard/actuator-status', {
    params,
  });
};

export const fetchSignalStrength = async (device, params) => {
  return instance.get(`dashboard/signal/${device}`, {
    params,
  });
};
