import styled from 'styled-components';

export default styled.div `
  .card-container {
    width: 120px;

    .ant-card-body {
      padding: 20px;
    }
  }
  
  .ant-space-item {
    > div {
      width: 8vw;
      > .title {
        white-space: nowrap;
      }
    }
  }
`;