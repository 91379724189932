import React from 'react';
import { Space } from 'antd';
import StatCard from '@components/atoms/statCard';
import PropTypes from 'prop-types';
import Style from './style';

const EdgeMonitor = ({ total, online, offline, dataLoss, maPlan, onSearch }) => {
  const otherCount = total - online - offline - dataLoss;

  const stats = [
    { color: '#3E4276', bgColor: '#E6E8FE', title: 'ALL', value: total, link: 'all' },
    { color: '#14B1A8', bgColor: '#EBF3F1', title: 'ONLINE', value: online, link: 'online' },
    { color: '#D46B3E', bgColor: '#FFE5DF', title: 'OFFLINE', value: offline, link: 'offline' },
    // {color: '#ff4d4f', bgColor: '#FFEDDD', title: 'DATA LOSS', value: dataLoss, link: 'dataloss'},
    // {color: '#6c757d', bgColor: '#dee2e6', title: 'OTHER', value: otherCount},
    // {color: '#AC9C08', bgColor: '#FFF2C8', title: 'MA PLAN', value: maPlan},
  ];

  return (
    <Style>
      <Space size={20} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {stats.map((stat, index) => (
          <StatCard key={index} {...stat} onSearch={onSearch} />
        ))}
      </Space>
    </Style>
  );
};

EdgeMonitor.propTypes = {
  total: PropTypes.number,
  online: PropTypes.number,
  offline: PropTypes.number,
  dataLoss: PropTypes.number,
  maPlan: PropTypes.number,
  onSearch: PropTypes.func,
};

export default EdgeMonitor;
