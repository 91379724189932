import CallCenterPortalCustomerTable from '@root/components/organisms/tables/callCenterPortalCustomerTable';
import { CALL_CENTER_PORTAL_SEARCH_OPTIONS } from '@root/constants/mocks.constant';
import { fetchCustomers } from '@root/services/customers.service';
import { Button, Form, Input, Row, Select, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useAuth } from '@store/auth/hook';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import NoResultIcon from './components/NoResultIcon';
import { LoadingOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

export default function CallCenterPortalPage() {
  let history = useHistory();
  const { user } = useAuth();
  const [form] = Form.useForm();
  const searchQuery = {
    page: 1,
  };
  const [customers, setCustomers] = useState();
  const [isSearching, setIsSearching] = useState(false);

  const handleSubmitSearch = async (values) => {
    const { searchBy, searchValue } = values;
    if (!searchBy || !searchValue) {
      return;
    }
    setIsSearching(true);
    const customers = await fetchCustomers(searchQuery, searchBy, searchValue);
    setCustomers(customers);
    setIsSearching(false);
  };

  const checkRoleUserSSB = () => {
    const roleUser = user?.userRoleKeys.includes('call-center-portal/read');
    if (!roleUser) {
      history.push('/dashboard/edge-applications');
    }
  };

  useEffect(() => {
    checkRoleUserSSB();
  }, [user]);

  return (
    <>
      <Title level={2}>Call Center Portal</Title>
      <Form onFinish={handleSubmitSearch} form={form} style={{ paddingTop: 17, marginBottom: 40, width: 708 }}>
        <Row justify="start" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 9 }}>
          <WrapSelect>
            <Form.Item name="searchBy" initialValue="telephone" noStyle>
              <Select initialvalues="telephone" style={{ width: 223 }}>
                {CALL_CENTER_PORTAL_SEARCH_OPTIONS.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </WrapSelect>
          <Form.Item name="searchValue" noStyle>
            <Input style={{ width: 476, height: 44, borderRadius: 8, fontSize: 16 }} placeholder="Search" />
          </Form.Item>
        </Row>
        <Row
          style={{
            marginTop: 24,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            style={{
              display: 'flex',
              borderRadius: 8,
              padding: '12px 20px',
              alignItems: 'center',
              justifyContent: 'center',
              height: 44,
              width: 103,
              fontSize: 18,
              fontWeight: 'bold',
            }}
          >
            Search
          </Button>
        </Row>
      </Form>
      <Spin spinning={isSearching} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
        {customers?.data.length > 0 && <CallCenterPortalCustomerTable items={customers?.data || []} />}
        {customers?.data.length === 0 && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 50 }}>
            <NoResultIcon />
            <span style={{ marginTop: 21, fontSize: 20, fontWeight: 600 }}>No result found</span>
            <span style={{ fontSize: 20, fontWeight: 600 }}>Please retry again</span>
          </div>
        )}
      </Spin>
    </>
  );
}

const WrapSelect = styled.div`
  .ant-select .ant-select-selector {
    height: 44px;
    border-radius: 8px;
    align-items: center;
    font-size: 16px;
  }
`;
