import { EdgeStatusEnum, MAStatusEnum } from '@services/edgeApplication';

const deviceInformation = {
  invertor: 'ABB',
  maxKW: 3.3,
  spec: 'Passamon',
  solarPanel: 'RS PRO 150W Monocrystalline',
};

const abnormalStatusHistory = [
  {
    id: 'jxcde-fxiwo-101011',
    startedTime: '2021-11-02T00:00:00.477Z',
    endedTime: '2021-11-02T01:30:00.477Z',
    duration: 90,
    status: 'Data loss',
  },
];

const todayInformation = {
  powerCh1: 0.7,
  powerCh2: null,
  volCh1: 1.3,
  volCh2: null,
  currentCh1: 0.01,
  currentCh2: null,
  gridPower: 10,
};

const energySummary = {
  daily: 15,
  weekly: 15,
  monthly: 15,
  yearly: 4.2,
  total: 10.1,
};

const energyTimeline = [
  {
    time: '16.00',
    whz: 14.2,
  },
  {
    time: '17:00',
    whz: 14.8,
  },
  {
    time: '18:00',
    whz: 15.2,
  },
  {
    time: '19.00',
    whz: null,
  },
  {
    time: '20:00',
    whz: null,
  },
  {
    time: '21:00',
    whz: null,
  },
];

const powerTimeline = [
  {
    category: 'p1',
    time: '02.00',
    kw: 2,
  },
  {
    category: 'p1',
    time: '03:00',
    kw: 4,
  },
  {
    category: 'p1',
    time: '04:00',
    kw: 8,
  },
  {
    category: 'p1',
    time: '05.00',
    kw: 6,
  },
  {
    category: 'p1',
    time: '06:00',
    kw: 10,
  },
  {
    category: 'p1',
    time: '07:00',
    kw: 7,
  },
  {
    category: 'p1',
    time: '08:00',
    kw: 5,
  },
  // end category: 'p1',
  {
    category: 'p2',
    time: '02.00',
    kw: 4,
  },
  {
    category: 'p2',
    time: '03:00',
    kw: 5,
  },
  {
    category: 'p2',
    time: '04:00',
    kw: 2,
  },
  {
    category: 'p2',
    time: '05.00',
    kw: 4,
  },
  {
    category: 'p2',
    time: '06:00',
    kw: 7,
  },
  {
    category: 'p2',
    time: '07:00',
    kw: 5,
  },
  {
    category: 'p2',
    time: '08:00',
    kw: 5,
  },
  // end category: 'p2',
  {
    category: 'p3',
    time: '02.00',
    kw: 2,
  },
  {
    category: 'p3',
    time: '03:00',
    kw: 4,
  },
  {
    category: 'p3',
    time: '04:00',
    kw: 3,
  },
  {
    category: 'p3',
    time: '05.00',
    kw: 5,
  },
  {
    category: 'p3',
    time: '06:00',
    kw: 7,
  },
  {
    category: 'p3',
    time: '07:00',
    kw: 9,
  },
  {
    category: 'p3',
    time: '08:00',
    kw: 6,
  },
  // end category: 'p3',
];

export const airTimeline = [
  {
    time: '00:00',
  },
  {
    time: '01:00',
  },
  {
    time: '02:00',
  },
  {
    time: '03:00',
  },
  {
    time: '04:00',
  },
  {
    time: '05:00',
  },
  {
    time: '06:00',
  },
  {
    time: '07:00',
  },
  {
    time: '08:00',
  },
  {
    time: '09:00',
  },
  {
    time: '10:00',
  },
  {
    time: '11:00',
  },
  {
    time: '12:00',
  },
  {
    time: '13:00',
  },
  {
    time: '14:00',
  },
  {
    time: '15:00',
  },
  {
    time: '16:00',
  },
  {
    time: '17:00',
  },
  {
    time: '18:00',
  },
  {
    time: '19:00',
  },
  {
    time: '20:00',
  },
  {
    time: '21:00',
  },
  {
    time: '22:00',
  },
  {
    time: '23:00',
  },
];


export const listEdge = [
  {
    id: Math.random(),
    edge_barcode: '123456',
    address: 'ถนน แขวง เขต จังหวัด 73210 55/8 ไทย',
    customer: {
      id: 32,
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Antonio',
      last_name: 'Last',
      telephone: '02554698',
      email: null,
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: 59,
        application_name: 'solar',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
      {
        id: 61,
        application_name: 'deebox',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 151,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.online,
    installed_date: '2021-11-19',
    clean_date: '2021-10-05',
    ma_plan_date: '2022-05-19',
    int_id: '7867822',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',

    // controller_board: null,
    // note: null,
    // token: null,
    // display_name: 'Tan T - aaq, solar, aaf',
    // application_name: null,
    // last_seen: null,
    // deployment_enable: false,
  },

  {
    id: Math.random(),
    edge_barcode: 'EXECSSSe',
    address: 'Rd Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Bekka',
      last_name: 'OP',
      telephone: '027778888',
      email: 'test@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'aaf',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.offline,
    installed_date: '2021-02-05',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-11-30',
    int_id: '1928171',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'kieow1239',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Zamual',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'aafq',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.offline,
    installed_date: '2021-05-21',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-01-27',
    int_id: '7219283',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'deebox',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'deebox',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'deebox',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'aafq',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'aafq',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'deebox',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'aaf',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'aaf',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'japan209',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Noland',
      last_name: 'OOPD',
      telephone: '0782921722',
      email: 'test-2@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [
      {
        id: Math.random(),
        application_name: 'aaq',
        installed_date: '2021-11-19',
        devices: [
          {
            id: 150,
            device_type: 'Temp and Humid',
            location: 'Indoor',
            controller_type: 'sc_indoor',
            set: 1,
            zone: 1,
            device_barcode: '14252424',
            room_name: 'Main',
          },
        ],
      },
    ],
    status: EdgeStatusEnum.dataLoss,
    installed_date: '2021-03-02',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-05-20',
    int_id: '7898872',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },

  {
    id: Math.random(),
    edge_barcode: 'gpk2002',
    address: 'Rd Lorem ipsum Lorem ipsum 29999',
    customer: {
      id: Math.random(),
      created_at: '2021-11-19T07:45:18.477Z',
      updated_at: '2021-11-19T08:24:22.061Z',
      first_name: 'Ore',
      last_name: 'Poyo',
      telephone: '0782921722',
      email: 'test-5@mail.com',
      note: null,
      addresses: [],
      lineId: '@lineID',
    },
    application_devices: [],
    status: EdgeStatusEnum.online,
    installed_date: '2021-03-07',
    clean_date: '2021-10-05',
    ma_plan_date: '2021-12-20',
    int_id: '6767221',

    deviceInformation,
    abnormalStatusHistory,
    todayInformation,
    energySummary,
    energyTimeline,
    powerTimeline,
    created_at: '2021-11-19T07:42:17.454Z',
    updated_at: '2021-11-19T07:45:28.052Z',
  },
];
