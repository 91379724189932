import { hasUpdateAccess } from '@root/utils/permission';
import { useAuth } from '@store/auth/hook';
import { Button, Space, Table, Tag, Typography } from 'antd';
import PropTypes, { arrayOf, bool, object } from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomerLogModal from './components/CustomerLog';
import { TitleSort } from './titleSort';

const CustomerTable = ({ items, loading, setQuery }) => {
  const { user } = useAuth();
  const [data, setData] = useState(items);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currentSort, setCurrentSort] = useState('');

  useEffect(() => {
    let test = items.map((item) => {
      // item.edge = item?.edge?.replace('{', '').replace('}', '') || '';
      item.edge_id = item?.edge_id?.map((edge_id) => edge_id.edge_barcode);
      item.applications = item?.applications?.map((name) => name.application_name);
      item.updated_at =
        item?.updated_at
          ?.substring(0, item?.updated_at?.lastIndexOf('.') + 1)
          .replace('T', ' ')
          .replace('.', '') || '';
      return item;
    });
    setData(test);
  }, [items]);

  const handleChangeQuery = (orderBy, key) => {
    setCurrentSort(key);
    if (orderBy === 'asc') {
      setQuery((prev) => ({ ...prev, sort: [{ [`${key}`]: 'asc' }] }));
      return;
    }
    if (orderBy === 'desc') {
      setQuery((prev) => ({ ...prev, sort: [{ [`${key}`]: 'desc' }] }));
      return;
    }
    setQuery((prev) => ({
      ...prev,
      sort: [],
    }));
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: () => {
        return (
          <TitleSort
            isResetSort={currentSort !== 'reference'}
            onChange={(orderBy) => {
              handleChangeQuery(orderBy, 'reference');
            }}
            title="Ref"
          />
        );
      },
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Title Name',
      dataIndex: 'title_name',
      key: 'title_name',
    },
    {
      title: () => {
        return (
          <TitleSort
            isResetSort={currentSort !== 'first_name'}
            onChange={(orderBy) => {
              handleChangeQuery(orderBy, 'first_name');
            }}
            title="First Name"
          />
        );
      },
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: () => {
        return (
          <TitleSort
            isResetSort={currentSort !== 'last_name'}
            onChange={(orderBy) => {
              handleChangeQuery(orderBy, 'last_name');
            }}
            title="Last Name"
          />
        );
      },
      dataIndex: 'last_name',
      key: 'last_name',
    },
    // {
    //   title: 'Active',
    //   dataIndex: 'isActive',
    //   key: 'isActive',
    //   width: 100,
    //   render: (val) => {
    //     let color = 'red';
    //     let text = 'Inactive';

    //     if (val) {
    //       color = 'green';
    //       text = 'Active';
    //     }

    //     return <Tag color={color}>{text}</Tag>;
    //   },
    // },
    {
      title: 'Solutions',
      dataIndex: 'applications',
      key: 'applications',
      render: (applications) => (
        <Space size="small" direction="vertical">
          {applications.map((element, index) => {
            return <Tag key={index}>{element}</Tag>;
          })}
        </Space>
      ),
    },
    {
      title: 'Edge ID',
      dataIndex: 'edge_id',
      key: 'edge_id',
      render: (edge_id) => (
        <Space direction="vertical" size={5} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {edge_id.map((element, index) => {
            return <Tag key={index}>{element}</Tag>;
          })}
        </Space>
      ),
    },
    {
      title: 'Address',
      dataIndex: 'street_address',
      key: 'street_address',
    },
    {
      title: 'Province',
      dataIndex: 'province',
      key: 'province',
    },
    {
      title: 'Phone',
      dataIndex: 'telephone',
      key: 'telephone',
    },
    {
      title: () => {
        return (
          <TitleSort
            isResetSort={currentSort !== 'updated_at'}
            onChange={(orderBy) => {
              handleChangeQuery(orderBy, 'updated_at');
            }}
            title="Last update"
          />
        );
      },
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    hasUpdateAccess(user, 'customers') && {
      title: 'Action',
      key: 'action',
      // width: 80,
      // fixed: 'right',
      render: (_, record) => <Link to={`/dashboard/customers/${record.id}`}>Edit</Link>,
    },
    {
      title: 'Log',
      render: (record) => (
        <Button onClick={() => setSelectedCustomer(record)} type="primary">
          Show
        </Button>
      ),
    },
  ].filter((x) => x);

  return (
    <>
      <CustomerLogModal customer={selectedCustomer} onClose={setSelectedCustomer} />
      <Typography.Text type="secondary">Found {data.length} records.</Typography.Text>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data}
        rowKey="id"
        loading={{
          spinning: loading,
          tip: 'Loading...',
        }}
      />
    </>
  );
};

CustomerTable.propTypes = {
  items: arrayOf(object),
  loading: bool,
  setQuery: PropTypes.func,
};

CustomerTable.defaultProps = {
  items: [],
  loading: false,
  setQuery: () => {},
};

export default CustomerTable;
