import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Row, Space, Select, Tree } from 'antd';
import UserAssignUserGroupTable from '@components/organisms/tables/userAssignUserGroupTable';
import { definingRoles } from '@root/utils';
import { useUserGroup } from '@root/store/userGroup/hook';
import { userRoleTree } from '@root/config/userRoleConfig';
import StyledModal from './style';

const { Option } = Select;

const NewUserGroupModal = (props) => {
  const { data, visible, onSubmit, onCancel, listUser } = props;
  const [form] = Form.useForm();
  const { stateNewUserGroup, newUserGroup, getListUserGroups, tenants, getTenants } = useUserGroup();
  const { data: tenantsData } = tenants;
  const { isLoading, isSuccess } = stateNewUserGroup;

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [role, setRole] = useState('');

  const onFinish = (values) => {
    delete values.confirm;
    values.roleKeys = checkedKeys;
    values.selectedUsers = selectedUsers;
    values.tenants = [values.tenant];
    newUserGroup(values);
  };

  useEffect(() => {
    if (isSuccess) {
      getListUserGroups();
      form.resetFields();
    }
  }, [isSuccess]);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onSelectUserChange = (lstSelectedUsers) => {
    setSelectedUsers(lstSelectedUsers);
  };

  const handleChangeRole = (value) => {
    setRole(value);
    if (value !== 'scg') {
      getTenants({ role: value });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setCheckedKeys(null);
    setSelectedUsers(null);
    onCancel();
  };

  return (
    <StyledModal title="New User Group" visible={visible} onOk={onSubmit} onCancel={handleCancel} footer={null}>
      <Form form={form} onFinish={onFinish} scrollToFirstError layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please input name' },
            { max: 70, message: 'Maximum length is 70 characters' },
            {
              pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
              message: 'The group name is not in a valid format.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className={role === 'tenant' && 'role'}>
          <div className="gutter-col">
            <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please select role' }]}>
              <Select
                style={{ width: '100%' }}
                onChange={handleChangeRole}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="scg">SCG</Option>
                <Option value="tenant">Tenant</Option>
              </Select>
            </Form.Item>
          </div>
          {role === 'tenant' && (
            <div className="gutter-col">
              {data.id && (
                <Form.Item name="tenant" label="Tenant">
                  <Select
                    style={{ width: '100%' }}
                    onChange={() => {}}
                    defaultValue={+data.tenants || ''}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {tenantsData &&
                      tenantsData.map((tenant) => (
                        <Option key={tenant.id} value={tenant.id}>
                          {tenant.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          )}
        </div>
        <Form.Item name="fullRole" label="Solutions" rules={[{ required: true, message: 'Please select solutions' }]}>
          <Select
            mode="multiple"
            allowClear
            placeholder="Please select"
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {definingRoles.map((role, index) => (
              <Option key={index} value={role.value}>
                {role.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={userRoleTree}
          />
        </Form.Item>
        <Form.Item>
          <UserAssignUserGroupTable
            selectedRowKeys={selectedUsers}
            items={listUser}
            onSelectUserChange={onSelectUserChange}
          />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                Submit
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

NewUserGroupModal.propTypes = {
  data: PropTypes.object,
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  listUser: PropTypes.array,
};

NewUserGroupModal.defaultProps = {
  data: null,
  visible: false,
  onSubmit: () => {},
  onCancel: () => {},
  listUser: [],
};

export default NewUserGroupModal;
