import { Result } from 'antd';
import React from 'react';

const NotFound = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f0f0f0',
    },
  };
  return (
    <div style={styles.container}>
      <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />
    </div>
  );
};

export default NotFound;
