import { fetchEdgeID, fetchSCGID } from '@root/services/scg-id';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Form, Input, Select } from 'antd';

const { Option } = Select;

export const SearchSCGID = () => {
  const [searchVal, setSearchVal] = useState('');
  const [searchBy, setSearchBy] = useState('scg-id');

  const { data, refetch, isLoading, isFetching } = useQuery(
    ['search-scg-id'],
    () => {
      if (searchBy === 'scg-id') {
        return fetchEdgeID(searchVal);
      }
      return fetchSCGID(searchVal);
    },
    {
      enabled: false,
    },
  );

  const handleSeatch = async () => {
    if (!searchVal) return;
    refetch();
  };

  return (
    <>
      <div style={{ width: 900, display: 'flex', gap: 20 }}>
        <Form.Item label="Search by">
          <Select onChange={(e) => setSearchBy(e)} defaultValue="scg-id">
            <Option value="scg-id">SCG ID</Option>
            <Option value="edge-id">EDGE ID</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Input onChange={(e) => setSearchVal(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isLoading || isFetching}
            onClick={handleSeatch}
            style={{ width: 200 }}
            type="primary"
            htmlType="submit"
          >
            Search
          </Button>
        </Form.Item>
      </div>
      <ul>
        {(data || []).map((it) => (
          <li key={it}>{it}</li>
        ))}
      </ul>
    </>
  );
};
