export const CUSTOMER_SEARCH_OPTIONS = [
  {
    value: 'first_name',
    name: 'First Name',
  },
  {
    value: 'last_name',
    name: 'Last Name',
  },
  {
    value: 'customer_id',
    name: 'Customer Id',
  },
  {
    value: 'edge_id',
    name: 'Edge ID',
  },
  {
    value: 'tenant_id',
    name: 'Tenant',
  },
  {
    value: 'customer_reference',
    name: 'Reference',
  },
  {
    value: 'solution_name',
    name: 'Solutions',
  },
];

export const CALL_CENTER_PORTAL_SEARCH_OPTIONS = [
  {
    value: 'telephone',
    name: 'Phone No.',
  },
  {
    value: 'first_name',
    name: 'First Name',
  },
  {
    value: 'last_name',
    name: 'Last Name',
  },
];

export const UNIT = { day: 'W', week: 'W', month: 'W', year: 'W', lifetime: 'W' };
