import { fetchSCGID } from '@root/services/edge-applications.service';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { message, Row, Col } from 'antd';

export const SCGIDTab = () => {
  const { edge_barcode } = useParams();

  const { data } = useQuery(['scg-id'], () => fetchSCGID(edge_barcode.toString()));

  const copy = (it) => {
    navigator.clipboard.writeText(it);
    message.success('Copied');
  };

  const List = styled.div`
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    margin-top: 24px;
    cursor: pointer;
    text-align: center;

    &:hover {
      /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border: none;
    }
  `;

  return (
    <Row gutter={[32, 32]}>
      {(data || []).map((it) => (
        <Col span={4}>
          <List
            style={{ fontSize: 21 }}
            key={it}
            onClick={() => {
              copy(it);
            }}
          >
            {it}
          </List>
        </Col>
      ))}
    </Row>
  );
};
