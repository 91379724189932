import React from "react";
import TextCardHeader from "./style";

const TextCardHeaderNcc = (props) => {
  return (
    <TextCardHeader $gray={props.color}>
      <h2>{props.title}</h2>
    </TextCardHeader> 
  )
};

export default TextCardHeaderNcc;