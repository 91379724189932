import { useState } from 'react';

const useDialog = (initialMode = false) => {
  const [isVisible, setVisible] = useState(initialMode);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  return { isVisible, open, close };
};

export { useDialog };
