import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, Space, Button } from 'antd';

const EditCustomerInstallationForm = (props) => {
  const { data, onFinish, onCancel, isLoading, btnCancel } = props;
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFormFinish = (values) => {
    delete values.username;
    onFinish(values);
  };

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue(data);
  }, [data?.id]);

  return (
    <Form form={form} onFinish={onFormFinish} scrollToFirstError layout="horizontal" {...layout}>
      <Col span={18} offset={2}>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="solution" label="Solution Name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="package" label="Package Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="installDate" label="Installation Date">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="inverterBrand" label="Inverter Brand">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="inverterSpec" label="Inverter Spec">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="inverterMaxCapKW" label="Inverter Max Cap(kW)">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="inverterModel" label="Inverter Model">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="solarPanel" label="Solar Panel(Model)">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="warrantyYear" label="Warranty Year">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="edgeBarcode" label="Edge ID">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="warrantyFinishDate" label="Warranty End-Date">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="images" label="Attachment">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={12} offset={12}>
            <Row justify="end">
              <Space>
                {btnCancel && <Button onClick={onCancel}>Cancel</Button>}
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Submit
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

EditCustomerInstallationForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, null]),
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  btnCancel: PropTypes.bool,
};

EditCustomerInstallationForm.defaultProps = {
  data: null,
  onFinish: () => {},
  onCancel: () => {},
  isLoading: false,
  btnCancel: true,
};

export default EditCustomerInstallationForm;
