/* eslint-disable react/prop-types */
import { Line } from '@ant-design/charts';
import { FileTextOutlined } from '@ant-design/icons';
import { exportSolarHeaders } from '@root/config/edgeApplicationConfig';
import { UNIT } from '@root/constants/mocks.constant';
import { datePeriod } from '@root/utils';
import { Button, Card, DatePicker, Radio, Row, Space, Typography } from 'antd';
import { CSVLink } from 'react-csv';

const { Title } = Typography;
const { RangePicker } = DatePicker;

// /**
//  * @param {string} period
//  * @param {{category: string, kW: number, time: string}[]} items
//  * @param onChangeFilterRangeDate
//  * @param onChangeFilterEnergy
//  * @param {moment.Moment} selectedDate
//  * @param dateFormat
//  * @returns {JSX.Element}
//  * @constructor
//  */
const PowerChart = ({
  period,
  items,
  onChangeFilterRangeDate,
  onChangeFilterEnergy,
  selectedDate,
  dateFormat = 'DD-MMMM-YYYY',
}) => {
  const config = {
    padding: 'auto',
    xField: 'time',
    yField: 'kW',
    seriesField: 'category',
    label: {
      style: {
        fill: '#aaa',
      },
    },
    smooth: true,
    tooltip: {
      formatter: (datum) => ({
        name: datum.category,
        value:
          datum?.kW > 1000
            ? `${(datum?.kW / 1000).toFixed(3)} kW${period !== 'day' ? 'h' : ''}`
            : `${datum?.kW} W${period !== 'day' ? 'h' : ''}`,
      }),
    },
  };

  const onChangeFilterDate = (e) => {
    onChangeFilterEnergy(e.target.value);
  };

  const onChangeRangeDate = (value) => {
    if (!value) return;
    onChangeFilterRangeDate(value);
  };

  const formatFilenameDate = (date) => {
    if (Array.isArray(date)) {
      return `solar-chart-${date[0].format(dateFormat)}.csv`;
    }
    return `solar-chart-${date.format(dateFormat)}.csv`;
  };

  return (
    <>
      <Card>
        <Row justify="space-between" style={{ marginBottom: '15px' }}>
          <Title level={5}>Solar and Consumption Info</Title>
          <Radio.Group onChange={onChangeFilterDate} value={period}>
            <Radio.Button value={datePeriod.day}>Day</Radio.Button>
            <Radio.Button value={datePeriod.week}>Week</Radio.Button>
            <Radio.Button value={datePeriod.month}>Month</Radio.Button>
            <Radio.Button value={datePeriod.year}>Year</Radio.Button>
            <Radio.Button value={datePeriod.lifetime}>Lifetime</Radio.Button>
          </Radio.Group>
        </Row>
        <Row justify="end" style={{ marginBottom: '15px' }}>
          <Space size={10}>
            {period !== 'lifetime' && period !== 'day' && (
              <DatePicker
                value={selectedDate[0]}
                picker={period}
                format={dateFormat}
                onChange={(val) => onChangeRangeDate(val)}
              />
            )}
            {period === 'day' && <RangePicker value={selectedDate} onChange={(val) => onChangeRangeDate(val)} />}
            <Button disabled={!items?.solarAndConsumptionInfo?.find((row) => row.kW)}>
              <CSVLink
                filename={formatFilenameDate(selectedDate)}
                data={items?.solarAndConsumptionInfo || []}
                headers={exportSolarHeaders}
              >
                <Space size={10}>
                  <FileTextOutlined />
                  Export
                </Space>
              </CSVLink>
            </Button>
          </Space>
        </Row>
        <div>
          <Line {...config} data={items?.solarAndConsumptionInfo || []} />
          <p style={{ bottom: -30, left: 15 }}>{UNIT[period]}</p>
        </div>
      </Card>
      <Card>
        <Row justify="space-between" style={{ marginBottom: '15px' }}>
          <Title level={5}>Surplus </Title>
        </Row>
        <Row justify="end" style={{ marginBottom: '15px' }}>
          <Space size={10}>
            <Button disabled={!items?.surplus?.find((row) => row.kW)}>
              <CSVLink
                filename={formatFilenameDate(selectedDate)}
                data={items?.surplus || []}
                headers={exportSolarHeaders}
              >
                <Space size={10}>
                  <FileTextOutlined />
                  Export
                </Space>
              </CSVLink>
            </Button>
          </Space>
        </Row>
        <div>
          <Line {...config} data={items.surplus || []} />
          <p style={{ bottom: -30, left: 15 }}>{UNIT[period]}</p>
        </div>
      </Card>
    </>
  );
};

// PowerChart.propTypes = {
//   period: PropTypes.string,
//   onChangeFilterRangeDate: PropTypes.func,
//   onChangeFilterEnergy: PropTypes.func,
//   selectedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
//   dateFormat: PropTypes.string,
//   items: PropTypes.arrayOf(
//     PropTypes.shape({
//       time: PropTypes.string,
//       whz: PropTypes.number,
//     }),
//   ),
// };

// PowerChart.defaultProps = {
//   items: [],
// };

export default PowerChart;
