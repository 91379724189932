import {
  fetchDeviceConnectivity,
  fetchEdgeHealth,
  fetchEdgeStatus,
  fetchActuatorStatus,
  fetchSignalStrength,
} from '@root/services/dashboard.service';
import { message } from 'antd';
import { createContext, useContext, useState } from 'react';
import { useAuth } from '@store/auth/hook';
import { get } from 'lodash';
import { defaultState } from '../core';

const DashBoardContext = createContext();

const useDashBoard = () => {
  return useContext(DashBoardContext);
};

const useProvideDashBoard = () => {
  const { user } = useAuth();
  const [edgeStatus, setEdgeStatus] = useState(defaultState);
  const [edgeHealth, setEdgeHealth] = useState(defaultState);
  const [deviceConnectivity, setDeviceConnectivity] = useState(defaultState);
  const [actuatorStatus, setActuatorStatus] = useState(defaultState);
  const [wifiSignal, setWifiSignal] = useState(defaultState);
  const [zigbeeSignal, setZigbeeSignal] = useState(defaultState);

  const getEdgeStatus = async (params) => {
    setEdgeStatus((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await fetchEdgeStatus(params);
    if (res.status === 200) {
      setEdgeStatus((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data[0],
        isError: false,
      }));
      return;
    }
    message.error(res.message || 'Something went wrong!');
    setEdgeStatus((prev) => ({
      ...prev,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }));
  };

  const getEdgeHealth = async (params) => {
    setEdgeHealth((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await fetchEdgeHealth(params);
    if (res.status === 200) {
      setEdgeHealth((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data,
        isError: false,
      }));
      return;
    }
    message.error(res.message || 'Something went wrong!');
    setEdgeHealth((prev) => ({
      ...prev,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }));
  };

  const getDeviceConnectivity = async () => {
    setDeviceConnectivity((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await fetchDeviceConnectivity({ tenant_id: get(user.tenants, '[0]', null) });
    if (res.status === 200) {
      setDeviceConnectivity((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data,
        isError: false,
      }));
      return;
    }
    message.error(res.message || 'Something went wrong!');
    setDeviceConnectivity((prev) => ({
      ...prev,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }));
  };

  const getActuatorStatus = async () => {
    setActuatorStatus((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await fetchActuatorStatus({ tenant_id: get(user.tenants, '[0]', null) });
    if (res.status === 200) {
      setActuatorStatus((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data,
        isError: false,
      }));
      return;
    }
    message.error(res.message || 'Something went wrong!');
    setActuatorStatus((prev) => ({
      ...prev,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }));
  };

  const getSignalDevice = async (device) => {
    if (device === 'wifi') {
      await handleGetSignalDevice(setWifiSignal, device);
      return;
    }
    await handleGetSignalDevice(setZigbeeSignal, device);
  };

  const handleGetSignalDevice = async (cb, device) => {
    cb((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await fetchSignalStrength(device, { tenant_id: get(user.tenants, '[0]', null) });
    if (res.status === 200) {
      cb((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: true,
        data: res.data,
        isError: false,
      }));
      return;
    }
    message.error(res.message || 'Something went wrong!');
    cb((prev) => ({
      ...prev,
      isLoading: false,
      isSuccess: false,
      isError: true,
    }));
  };

  return {
    edgeStatus,
    getEdgeStatus,
    edgeHealth,
    getEdgeHealth,
    deviceConnectivity,
    getDeviceConnectivity,
    actuatorStatus,
    getActuatorStatus,
    wifiSignal,
    zigbeeSignal,
    getSignalDevice,
  };
};

const ProviderDashBoard = ({ children }) => {
  const roof = useProvideDashBoard();

  return <DashBoardContext.Provider value={roof}>{children}</DashBoardContext.Provider>;
};

export { useDashBoard, ProviderDashBoard };
