import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Typography, Tag, Space } from 'antd';
import { useAuth } from '@store/auth/hook';
import { hasUpdateAccess } from '@root/utils/permission';
import UserResetMfa from '../../modals/userResetMFA';

const UserTable = ({ items }) => {
  const { user } = useAuth();
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      width: 150,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: 150,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: 150,
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      width: 100,
      render: (val) => {
        let color = 'red';
        let text = 'Inactive';

        if (val) {
          color = 'green';
          text = 'Active';
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    hasUpdateAccess(user, 'users') && {
      title: 'Action',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <Space>
          <Link to={`/dashboard/users/${record.id}`}>Edit</Link>
          <UserResetMfa user={record} />
        </Space>
      ),
    },
  ].filter((x) => x);

  return (
    <>
      <Typography.Text type="secondary">Found {items.length} records.</Typography.Text>
      <Table columns={columns} dataSource={items} rowKey="id" />
    </>
  );
};

UserTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default UserTable;
