import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useEdgeApplication } from '@store/edgeApplication/hook';

const { Text } = Typography;
const handleEmpty = (val) => val || '-';

const EdgeCustomerInfo = ({ currentTab }) => {
  const { dataEdge } = useEdgeApplication();

  const [customer, setCustomer] = useState({ fullName: '-' });

  useEffect(() => {
    let owner = '';
    let ownerId = null;
    let members = [];

    if (dataEdge?.owned_customer) {
      ownerId = dataEdge.owned_customer.id;
      owner = `${dataEdge?.owned_customer?.title_name ?? ''} ${dataEdge?.owned_customer?.first_name ?? ''} ${dataEdge?.owned_customer?.last_name ?? ''}`;
    }

    if (dataEdge?.customers?.length > 0) {
      for (let member of dataEdge?.customers) {
        if (member.id != ownerId) {
          members.push(`${member?.title_name ?? ''} ${member?.first_name ?? ''} ${member?.last_name ?? ''}`);
        }
      }
    }
    
    setCustomer({ owner, member: members.join(', '), ...(dataEdge?.owned_customer ?? {}) });
  }, [dataEdge]);

  return (
    <Card title="Customer Information">
      <Row gutter={[16, 32]}>
        <Col span={8}>Owner Name</Col>
        <Col span={16}>
          <Text strong>{customer?.owner}</Text>
        </Col>

        <Col span={8}>Member Name</Col>
        <Col span={16}>
          <Text strong>{customer?.member}</Text>
        </Col>

        <Col span={8}>Address</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(dataEdge?.address)}</Text>
        </Col>

        <Col span={8}>Reference</Col>
        <Col span={16}>
          <Text strong>{handleEmpty(customer.reference)}</Text>
        </Col>
      </Row>
    </Card>
  );
};

EdgeCustomerInfo.propTypes = {
  currentTab: PropTypes.string,
};

export default EdgeCustomerInfo;
