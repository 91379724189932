import React from 'react';
import { useState } from 'react';
import ModalAddress from './style';
import { Radio, Space } from 'antd';
import styled from 'styled-components';

const ModalAddressNcc = ({ addresses, onShow, onHide, onChangeAddress }) => {
  const [value, setValue] = useState();
  const onChange = (e) => {
    console.log('radio change: ', e.target.value);
    setValue(e.target.value);
  };

  const handleChangeAddress = () => {
    onChangeAddress(value);
    onHide();
  };

  return (
    <ModalAddress className={onShow ? 'activeModal' : ''}>
      <div className="wrapModalBox">
        <h2>เปลี่ยนที่อยู่</h2>
        <p>กรุณาเลือกที่อยู่ที่คุณต้องการดูข้อมูล</p>
        <div className="wrapRadio">
          <Radio.Group onChange={onChange} value={value} defaultValue={addresses?.[0]}>
            <Space direction="vertical">
              {addresses?.map((address, i) => {
                return (
                  <Radio key={i} value={address}>
                    {address?.street_address} {address?.road} {address?.sub_district} {address?.district}{' '}
                    {address?.province} {address?.postal_code}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </div>
        <div className="wrapBtnModal">
          <button type="button" className="btnModal btnCancle" onClick={onHide}>
            ยกเลิก
          </button>
          <button type="button" className="btnModal btnApply" onClick={handleChangeAddress}>
            ยืนยัน
          </button>
        </div>
      </div>
    </ModalAddress>
  );
};

export default ModalAddressNcc;
