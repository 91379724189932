import { allSolutions } from '@root/utils/definingRoles';

/**
 * @param {{userRoleKeys: string[]}|{}} user
 * @param {string} scope
 * @param {'create' | 'update' | 'read' | 'read-child'} action
 * @returns {boolean}
 */
export const hasAccess = (user, scope, action) => {
  return !scope || [scope, `${scope}/${action}`].some((role) => user.userRoleKeys.includes(role));
  // return (
  //   !scope ||
  //   [scope, `${scope}/${action}`, `${scope}/${action}/mind`, `${scope}/${action}/ssb`].some((role) =>
  //     user.userRoleKeys.includes(role),
  //   )
  // );
};

/**
 * @param {{userRoleKeys: string[]}|{}} user
 * @param {string} scope
 * @returns {boolean}
 */
export const hasReadAccess = (user, scope) => {
  // return !scope || user.userRoleKeys.find(role => role.split('/')[0] === scope);
  return hasAccess(user, scope, 'read');
};

/**
 * @param {{userRoleKeys: string[]}|{}} user
 * @param {string} scope
 * @returns {boolean}
 */
export const hasCreateAccess = (user, scope) => {
  return hasAccess(user, scope, 'create');
};

/**
 * @param {{userRoleKeys: string[]}|{}} user
 * @param {string} scope
 * @returns {boolean}
 */
export const hasUpdateAccess = (user, scope) => {
  return hasAccess(user, scope, 'update');
};

/**
 * @param {{userSolutions: string[]}|{}} user
 * @param {'solar' | 'aaf' | 'aaq' | 'aafq'} scopes
 * @returns {boolean}
 */
export const canViewEdgeAppSolution = (user, ...scopes) => {
  return scopes.some((scope) => !allSolutions.includes(scope) || user.userSolutions.includes(scope));
};

/**
 * @param {{userSolutions: string[]}|{}} user
 * @param {'solar' | 'aaf' | 'aaq' | 'aafq' | 'deebox' | 'tuya'} scope
 * @returns {boolean}˝
 */
export const hasSolutionAccess = (user, scope) => {
  return user.userSolutions.includes(scope);
};
