import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const getFutureDateTime = (duration, type, currentDate) => {
  const currentDateTime = currentDate ? dayjs(currentDate) : dayjs();
  const futureDateTime = currentDateTime.add(duration, type);
  return futureDateTime;
};

export const dateDiff = (startDate, endDate = new Date(), type = 'seconds') => {
  const date1 = dayjs(startDate);
  const date2 = dayjs(endDate);
  return date1.diff(date2, type);
};
