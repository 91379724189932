import { Tag } from 'antd';
import moment from 'moment';

export const HISTORY_COLUMN = [
  {
    title: 'Username',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'Customer ID',
    dataIndex: 'entityId',
    key: 'entityId',
  },
  {
    title: 'Date time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => <p>{moment(date).format('DD-MM-YYYY HH:mm')}</p>,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (action) => <Tag>{action}</Tag>,
  },
];
