import { useQuery } from 'react-query';
import ErrorCustomerNcc from '@root/components/ncc/errorCustomer';
import SessionExpNcc from '@root/components/ncc/errorSessionExpire';
import TextCardNcc from '@root/components/ncc/textcard';
import IconRefresh from '@root/components/ncc/icon/refresh';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchCustomer } from '@root/services/ncc.service';
import NccContent from './NccContent';

export default function CustomerWarpper({ accessToken, handleRefeshToken }) {
  const { data, isFetching, error } = useQuery(
    ['nccCallCenterCustomer', accessToken],
    () => fetchCustomer(accessToken),
    {
      keepPreviousData: true,
      retry: false,
      select: (data) => {
        return data.data;
      },
    },
  );

  if (error?.response?.status === 400 || error?.response?.status === 401) {
    return (
      <LayoutNccBody>
        <SessionExpNcc />
      </LayoutNccBody>
    );
  }

  const topicFirst = 'ข้อมูลลูกค้า';
  const topicDataNSCGID = [
    {
      title: 'ชื่อลูกค้า',
      description: `${data?.customer?.title_name || ''} ${data?.customer?.first_name || ''} ${
        data?.customer?.last_name || ''
      }`,
    },
    {
      title: 'เบอร์โทรศัพท์',
      description: data?.customer?.telephone || '-',
    },
    {
      title: 'Email',
      description: data?.customer?.email || '-',
    },
    {
      title: 'SCG ID',
      description: '-',
    },
    {
      title: 'ประเภทสมาชิกในบ้าน',
      description: '-',
    },
  ];

  if (!data?.status && data?.code === 'CUSTOMER_NOT_FOUND') {
    return (
      <LayoutNccBody>
        <ErrorCustomerNcc />
      </LayoutNccBody>
    );
  } else if ((!data?.status && data?.code === 'SCGID_NOT_FOUND') || (data?.status && !data?.customer?.scg_id?.scg_id)) {
    return (
      <Spin spinning={isFetching} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
        <Container>
          <BtnRefreshPage onClick={() => handleRefeshToken()}>
            <IconRefresh />
            Refresh page
          </BtnRefreshPage>
          <TextCardNcc title={topicFirst} className="mt-10" dataCard={topicDataNSCGID} />
        </Container>
      </Spin>
    );
  } else if (data?.status && data?.customer) {
    return (
      <Spin spinning={isFetching} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
        <NccContent accessToken={accessToken} dataNcc={data.customer} refetchCustomer={handleRefeshToken} />
      </Spin>
    );
  }

  return null;
}

const Container = styled.div`
  width: 100%;
  height: auto;
  max-width: 1126px;
  margin: 0 auto;
  .mt-10 {
    margin-top: 2.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
`;

const LayoutNccBody = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #f0f0f0;
  padding-bottom: 208px;
  font-family: 'Sarabun', sans-serif;
`;

const BtnRefreshPage = styled.button`
  width: 168px;
  height: 44px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #d93832;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  float: right;
  margin: 40px 0;
  font-family: 'Noto Sans Thai', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #2a2d2f;
  cursor: pointer;
`;
