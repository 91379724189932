import React, { useContext, createContext, useState } from 'react';
import { message } from 'antd';
import { getOneEdgeWifi, updateOneEdgeWifi } from '../api';

const edgeWifiContext = createContext();

const useEditEdgeWifi = () => {
  return useContext(edgeWifiContext);
};

const useProvideEdgeWifi = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = async (id) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await getOneEdgeWifi(id);
    if (res.status === '200') {
      setData(res.data);
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  const updateData = async (id, form) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await updateOneEdgeWifi(id, form);
    if (res.status === '200') {
      message.success('Updated successful');
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    getData,
    updateData,
  };
};

const ContextEditEdgeWifi = ({ children }) => {
  const roof = useProvideEdgeWifi();

  return <edgeWifiContext.Provider value={roof}>{children}</edgeWifiContext.Provider>;
};

export { useEditEdgeWifi, ContextEditEdgeWifi };
