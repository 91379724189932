import styled from 'styled-components';

const TextCardBody = styled.div`
  width: 100%;
  height: auto;
  padding: 24px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  .grid-data {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 160px 1fr;
    gap: 24px 80px;
  }
  .grid-address {
    width: 100%;
    height: auto;
    display: flex;
    gap: 16px;
    align-items: center;
  }
  h4,
  p {
    margin: 0;
    padding: 0;
  }
  h4 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #40647b;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #333333;
  }
  .wrapBtnAddress {
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
  }
  .tooptip {
    visibility: hidden;
    width: 88px;
    height: 34px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px 16px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    left: -50%;
    top: 40px;
    transform: translateX(-15%);
  }
  .wrapBtnAddress:hover .tooptip {
    visibility: visible;
  }
  .tooptip p {
    color: #ffffff;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Sarabun', sans-serif;
    font-weight: 400;
  }
  .btnAddress {
    width: 32px;
    height: 32px;
    background-color: #40647b;
    border-radius: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .textCapitalize {
    text-transform: capitalize;
  }
  .activeModal {
    display: flex !important;
  }

  .warning-no-scgid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 24px;
  }

  .warning-no-scgid-text {
    display: flex;
    justify-content: center;
    color: #CA7713;
    font-size: 16px;
    font-weight: 600;
  }
`;

export default TextCardBody;
