import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import moment from 'moment';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import AirQualityChartGroup from '@root/components/molecules/airQualityChartGroup';
import AirInformation from '@root/components/molecules/airInformation';
const { Title } = Typography;

const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'DD/MM/YYYY HH:mm';
const handleEmpty = (val) => val || '-';
const handleEmptyDate = (val, format = dateFormat) => (val ? moment(val).format(format) : '-');
moment.locale('en', {
  week: {
    dow: 1,
  },
});

const ActiveAirQualityTab = () => {
  const {
    isFetching,
    dataEdge,
    dataActiveAirQualityTodayInformation,
    dataEdgeDeviceLocations,
    getActiveAirQualityTodayInformation,
    getActiveAirQualityStatistic,
    getEdgeDashboard,
  } = useEdgeApplication();
  const [airInformationAreaName, setAirInformationAreaName] = useState('');
  const [edgeBarcode, setEdgeBarcode] = useState();
  const [airData, setAirData] = useState([]);

  const locations = useMemo(() => {
    const isAFQ = dataEdgeDeviceLocations?.is_afq ? 'active_airflow' : 'active_airquality';
    return dataEdgeDeviceLocations?.applications?.[isAFQ]?.locations || [];
  }, [dataEdgeDeviceLocations]);

  const onChangeAirInformationAreaName = (value) => {
    setAirInformationAreaName(value);
  };

  useEffect(() => {
    if (locations.length) {
      const unique_room_name = [...new Set(locations.map((item) => item.location_name))];
      setAirInformationAreaName(unique_room_name[0]);
    }
  }, [locations]);

  useEffect(() => {
    if (dataEdge?.edge_barcode) {
      setEdgeBarcode(dataEdge.edge_barcode);
    }
  }, [dataEdge]);

  useEffect(() => {
    if (!edgeBarcode || !airInformationAreaName) return;
    getActiveAirQualityTodayInformation(edgeBarcode, airInformationAreaName);
  }, [edgeBarcode, airInformationAreaName]);

  useEffect(() => {
    if (dataActiveAirQualityTodayInformation && dataActiveAirQualityTodayInformation.data) {
      let zoneDataArr = [];
      for (var [title, data] of Object.entries(dataActiveAirQualityTodayInformation.data)) {
        zoneDataArr.push({
          title: title,
          value: data ? data['value'] : 'No Data',
          unit: data ? data['unit'] : '',
          level: data ? data['comfort_level']['level'] : '',
          color: data ? data['comfort_level']['color'] : '',
        });
      }
      setAirData(zoneDataArr);
    }
  }, [dataActiveAirQualityTodayInformation]);

  useEffect(() => {
    airData &&
      airInformationAreaName &&
      airData.forEach((v) => {
        getActiveAirQualityStatistic(edgeBarcode, {
          date: moment().format(dateFormat),
          data_types: v.title,
          area: airInformationAreaName,
        });
      });
  }, [airData, airInformationAreaName]);

  useEffect(() => {
    if (edgeBarcode) {
      getEdgeDashboard(edgeBarcode);
    }
  }, [edgeBarcode]);

  const packageName = useMemo(() => {
    const appList = dataEdge?.application_devices || [];
    const findName = (name) => appList.find((app) => app.application_name === name)?.commercial_name;
    return findName('aaq') || findName('aafq');
  }, [dataEdge]);

  const getWarrantyEndDate = (installationDate) => {
    if(null != installationDate){
      var instDate = moment(installationDate, "YYYY-MM-DD HH:mm:ss");
      instDate.add(1, 'y');
      return instDate.format(dateTimeFormat);
    }
    return '';
  }
  
  return (
    <Row gutter={[16, 32]}>
      <Col span={12}>
        <Title level={5}>Package name: {packageName}</Title>
        <Title level={5} style={{marginTop: "0px"}}><span>Installation date: {handleEmptyDate(dataEdge?.installed_date, dateTimeFormat)}</span></Title>
        <Title level={5} style={{marginTop: "0px"}}>
              Warranty Period: 1 Year {' ('+getWarrantyEndDate(dataEdge?.installed_date) +')'}
            </Title>
      </Col>
      {/* <Col span={12} style={{ textAlign: 'right' }}>
        <Title level={5}>
          {`Updated at ${handleEmpty(moment(dataEdge?.updated_at).format(dateTimeFormat))}`}
        </Title>
      </Col> */}

      {(dataActiveAirQualityTodayInformation || isFetching.dataActiveAirQualityTodayInformation) && (
        <Col span={24}>
          <Card title="Today information">
            <AirInformation
              onChangeAirInformationAreaName={onChangeAirInformationAreaName}
              airInformationAreaName={airInformationAreaName}
              airData={airData}
              locations={locations}
              type={'aaq'}
              isLoading={isFetching.dataActiveAirQualityTodayInformation}
            />
          </Card>
        </Col>
      )}

      <AirQualityChartGroup edgeBarcode={edgeBarcode} locations={locations} />
    </Row>
  );
};

export default ActiveAirQualityTab;
