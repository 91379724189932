import React, { useEffect, useState } from 'react';
import { Typography, Row, Col } from 'antd';
import EdgeWifiTable from '@components/organisms/tables/edgeWifiTable';
import SearchEdgeWifiForm from '@components/organisms/forms/searchEdgeWifiForm';
import { useListEdgeWifi } from '@store/edgeWifi/hooks/useListEdgeWifi';
import { get } from 'lodash';
import { useAuth } from '@store/auth/hook';
import { useUserGroup } from '@store/userGroup/hook';

const { Title } = Typography;

const EdgeWifi = () => {
  const { user } = useAuth();
  const { tenants, getTenants } = useUserGroup();
  const { data: tenantsData } = tenants;

  const { data, countEdgeWifi, isLoading, getData, getCountEdgeWifi } = useListEdgeWifi();
  const [pagination, setPagination] = useState({ total: 10 });
  const [filterCriteria, setFilterCriteria] = useState({
    edgeId: '',
    page: 1,
    pageSize: 10,
  });

  const onSearch = (values) => {
    const filters = { ...filterCriteria, ...values };
    setFilterCriteria(filters);
    getData(filters);
    getCountEdgeWifi(filters);
  };

  useEffect(() => {
    if (user) {
      getData({ ...filterCriteria, tenantId: get(user, 'tenants[0]', '') });
      getCountEdgeWifi({ ...filterCriteria, tenantId: get(user, 'tenants[0]', '') });
    }
  }, [user]);

  useEffect(() => {
    user.roles.length > 0 && getTenants({ role: get(user, 'roles', []).includes('tenant') && 'tenant' });
  }, [user]);

  useEffect(() => {
    if (!countEdgeWifi.data) return;
    const total = countEdgeWifi.data.results;
    setPagination((prev) => ({ ...prev, total }));
  }, [countEdgeWifi.data]);

  const tableChange = (val) => {
    const filters = { ...filterCriteria, page: val.current, pageSize: val.pageSize };
    setFilterCriteria(filters);
    getData(filters);
  };

  return (
    <>
      <Title level={2}>Edge Wifi</Title>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <SearchEdgeWifiForm
            onFinish={onSearch}
            initialValue={+get(user, 'tenants[0]', '')}
            tenantsData={tenantsData}
            user={user}
          />
        </Col>
        <Col span={24}>
          <EdgeWifiTable items={data || []} loading={isLoading} pagination={pagination} onChange={tableChange} />
        </Col>
      </Row>
    </>
  );
};

export default EdgeWifi;
