import './style.css';
// import Style from './style';

export default function InfoItems({
    title = 'House no. & Soi',
    value = 'หมู่บ้าน Granada กรานาดา ปิ่นเกล้า-เพชรเกษม 1399 ',
    classes = '',
    style
  }) {
    return (
      <div className={`info-item ${classes}`} style={style}>
        <div className="label-info ">{title}</div>
        <div className="value-info ">{value}</div>
      </div>
    );
  }