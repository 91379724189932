import React, { useEffect } from 'react';
import { Breadcrumb, Tabs, Col, Row, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import EditCustomerForm from '@components/organisms/forms/editCustomerForm';
import EditCustomerInstallationForm from '@components/organisms/forms/editCustomerInstallationForm';
import EditCustomerMAPlanForm from '@components/organisms/forms/editCustomerMAPlanForm';
import { useEditCustomer } from '@store/customer/hooks/useEditCustomer';

const CustomerEdit = () => {
  const { id } = useParams();
  const { data, isLoading, getData, updateData } = useEditCustomer();

  const onSubmitForm = (values) => {
    const { longitude, latitude, sub_district } = values;
    updateData(id, { ...values, longitude: +longitude, latitude: +latitude, district: sub_district });
  };

  useEffect(() => {
    getData(id);
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={2}>Edit Customer</Typography.Title>
      </Col>

      <Col span={24}>
        <Breadcrumb style={{ marginBottom: 24 }}>
          <Breadcrumb.Item>
            <Link to="/dashboard/customers">List view</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>ID: {id}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>

      <Col span={24}>
        <Tabs>
          <Tabs.TabPane tab="Customer Detail" key="1">
            <EditCustomerForm data={data} isLoading={isLoading} onFinish={onSubmitForm} btnCancel />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Installation Detail" key="2">
            <EditCustomerInstallationForm />
          </Tabs.TabPane> */}
          {/* <Tabs.TabPane tab="MA Plan" key="3">
            <EditCustomerMAPlanForm />
          </Tabs.TabPane> */}
        </Tabs>
      </Col>
    </Row>
  );
};

export default CustomerEdit;
