import React, { useEffect } from 'react';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import { useParams } from 'react-router-dom';
import { Button, Col, Row, Slider, Switch, Typography } from 'antd';
import PropTypes from 'prop-types';
import Style from '@components/organisms/tables/actuatorTable/style';
import moment from 'moment';

const ActuatorTable = () => {
  const { listActuator, getListActuator } = useEdgeApplication();
  const { edge_barcode: edgeId } = useParams();
  const items = listActuator.data || [];
  const loading = listActuator.isLoading;

  useEffect(() => {
    if (!listActuator.data) getListActuator({ edgeId });
  }, []);

  return (
    <>
      <Row justify="end">
        <Button loading={loading} onClick={() => getListActuator({ edgeId })}>
          Refresh
        </Button>
      </Row>
      {items.map((item, index) => (
        <Row gutter={[16, 16]} key={index} align="middle">
          <Col span={5}>
            {'Device name'}
            <Typography.Text strong>{item.device_name}</Typography.Text>
            <br />
            {'Device ID'}
            <Typography.Text strong>{item.device_id}</Typography.Text>
            <br />
            {'Last seen '}
            <Typography.Text strong>{moment(item.last_seen).format('YYYY-MM-DD HH:mm:ss')}</Typography.Text>
          </Col>
          <DeviceTypeRender item={item} />
          <Col span={14} />
          {index !== items.length - 1 && <Col span={24} />}
        </Row>
      ))}
    </>
  );
};

export default ActuatorTable;

const DeviceTypeRender = ({ item }) => {
  const value = parseInt(item.value);

  switch (item.device_type) {
    case 'relay': {
      return (
        <Col span={5}>
          <Style>
            <Switch checkedChildren="on" unCheckedChildren="off" checked={value} />
          </Style>
        </Col>
      );
    }
    case 'pwm': {
      const marks = {
        0: 'turn off',
        33: 'speed 1',
        66: 'speed 2',
        100: 'speed 3',
      };

      return (
        <Col span={5}>
          <Style>
            <Slider marks={marks} step={null} value={value} onChange={() => {}} />
          </Style>
        </Col>
      );
    }
    default:
      return <></>;
  }
};

DeviceTypeRender.propTypes = {
  item: PropTypes.object,
};
