import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Row, Space } from 'antd';
import { validateNotOnlySpaces } from '@root/utils/validator';
import { useUser } from '@store/user/hook';

const NewUserModal = (props) => {
  const { visible, onSubmit, onCancel } = props;
  const [form] = Form.useForm();
  const { stateNewUser, newUser, getListUser } = useUser();
  const { isLoading, isSuccess } = stateNewUser;

  const onFinish = async (values) => {
    delete values.confirm;
    await newUser(values);
  };

  useEffect(() => {
    if (isSuccess) {
      getListUser();
      form.resetFields();
    }
  }, [isSuccess]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  return (
    <Modal title="New User" open={visible} onOk={onSubmit} onCancel={handleCancel} footer={null}>
      <Form form={form} onFinish={onFinish} scrollToFirstError layout="vertical">
        <Form.Item
          name="username"
          label="Username"
          rules={[
            {
              required: true,
              message: 'Please input your username',
            },
            {
              pattern: new RegExp(/^[a-zA-Z\d]*([-_.][a-zA-Z\d]+)?@([a-zA-Z\d-]+.)+\w{2,}$/),
              message: 'Please input your username',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            { required: true, message: 'Please input your first name!' },
            { validator: validateNotOnlySpaces('Please input your first name!') },
            { max: 70, message: 'Maximum length is 70 characters' },
            {
              pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
              message: 'The first name is not in a valid format.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            { required: true, message: 'Please input your last name!' },
            { validator: validateNotOnlySpaces('Please input your last name!') },
            { max: 70, message: 'Maximum length is 70 characters' },
            {
              pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
              message: 'The last name is not in a valid format.',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button htmlType="submit" type="primary" loading={isLoading}>
                Submit
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

NewUserModal.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

NewUserModal.defaultProps = {
  visible: false,
  onSubmit: () => {},
  onCancel: () => {},
};

export default NewUserModal;
