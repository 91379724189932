const defaultPieChartConfig = {
  width: 180,
  height: 180,
  appendPadding: 5,
  angleField: 'value',
  colorField: 'type',
  radius: 0.9,
  label: {
    type: 'inner',
    offset: '-30%',
    content: ({ value }) => value,
    style: {
      fontSize: 10,
      textAlign: 'center',
      fill: '#000000',
    },
    autoRotate: false,
  },
  interactions: [
    {
      type: 'element-active',
    },
  ],
  legend: {
    position: 'bottom',
  },
};

export const configOnOffChart = (data) => {
  return {
    ...defaultPieChartConfig,
    data,
    color: ({ type }) => {
      if (type === 'offline') {
        return '#FB7B78';
      }
      return '#56DD8A';
    },
  };
};

export const configSensorChart = (data) => {
  return {
    data,
    isStack: true,
    xField: 'sensor',
    yField: 'value',
    seriesField: 'type',
    legend: false,
    color: ({ type }) => {
      if (type === 'unhealthy') {
        return '#FB7B78';
      }
      return '#56DD8A';
    },
    label: {
      style: {
        fontSize: 10,
        textAlign: 'center',
        fill: '#000000',
      },
    },
  };
};

export const configDeviceConnectivityChart = (data) => {
  return {
    data,
    isStack: true,
    xField: 'sensor',
    yField: 'value',
    seriesField: 'type',
    legend: false,
    color: ({ type }) => {
      if (type === 'disconnected') {
        return '#FB7B78';
      }
      return '#56DD8A';
    },
    label: {
      position: 'middle',
      style: {
        fontSize: 10,
        textAlign: 'center',
        fill: ({ type }) => {
          if (type === 'disconnected') {
            return '#FFFFFF';
          }
          return '#000000';
        },
      },
    },
  };
};

export const configActuatorChart = (data) => {
  return {
    ...defaultPieChartConfig,
    data,
    color: ({ type }) => {
      if (type === 'not working') {
        return '#FB7B78';
      }
      return '#56DD8A';
    },
  };
};

export const configSignalWifiChart = (data) => {
  return {
    ...defaultPieChartConfig,
    data,
    color: ({ type }) => {
      if (type === 'weak') {
        return '#FB7B78';
      }
      return '#56DD8A';
    },
  };
};

export const configSignalZigbeeChart = (data) => {
  return {
    ...defaultPieChartConfig,
    data,
    color: ({ type }) => {
      if (type === 'weak') {
        return '#FB7B78';
      }
      return '#56DD8A';
    },
  };
};
