import styled from 'styled-components';
import { Modal } from 'antd';

export default styled(Modal)`
  .role {
    display: flex;
    justify-content: space-between;

    .gutter-col {
      width: 49%;

      & .ant-col {
        margin: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;
