import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Space, Button } from 'antd';
import CustomerMAPlanTable from '@components/organisms/tables/customerMAPlanTable';

const EditCustomerMAPlanForm = (props) => {
  const { data, onFinish } = props;
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFormFinish = (values) => {
    delete values.username;
    onFinish(values);
  };

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue(data);
  }, [data?.id]);

  return (
    <Form form={form} onFinish={onFormFinish} scrollToFirstError layout="horizontal" {...layout}>
      <Col span={18} offset={2}>
        <Row gutter={[12, 24]}>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={8}>Next MA Plan</Col>
              <Col span={16}>2022-12-13</Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={8}>Day Left</Col>
              <Col span={16}>11 months 23 days</Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row gutter={8}>
              <Col span={8}>MA Status</Col>
              <Col span={16}>2022-12-13</Col>
            </Row>
          </Col>
          <Col span={12}></Col>

          <Col span={12}>
            <Row gutter={8}>
              <Col span={8}>Actions</Col>
              <Col span={16}>
                <Space>
                  <Button>Re-Plan</Button>
                  <Button type="primary">Confirm</Button>
                </Space>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <CustomerMAPlanTable />
          </Col>
        </Row>

        <Row></Row>
      </Col>
    </Form>
  );
};

EditCustomerMAPlanForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, null]),
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  btnCancel: PropTypes.bool,
};

EditCustomerMAPlanForm.defaultProps = {
  data: null,
  onFinish: () => {},
  onCancel: () => {},
  isLoading: false,
  btnCancel: true,
};

export default EditCustomerMAPlanForm;
