import React, { useEffect, useState } from 'react';
import BannerLogin from '@components/molecules/bannerLogin';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@store/auth/hook';
import { useDialog } from '@root/utils/useModal';
import { Button, Divider, Modal } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { signInWithRedirect, confirmResetPassword, fetchAuthSession, signIn } from 'aws-amplify/auth';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { logModifyPassword, logLoginError } from '@root/services/log-management';

import '@aws-amplify/ui-react/styles.css';
import Style from './style';

const RenderLoginContent = () => {
  const { isVisible, open, close } = useDialog();
  const history = useHistory();
  const { toForgotPassword } = useAuthenticator();
  const [isLoginVendor, setisLoginVendor] = useState(false);
  const gotoLogin = () => {
    close();
    history.push('/login');
    window.location.reload();
  };
  if (isLoginVendor) {
    return (
      <>
        <Authenticator
          hideSignUp
          components={{
            SignIn: {
              Header() {
                return <div style={{ padding: '0 32px', fontWeight: 600, fontSize: 25 }}>เข้าสู่ระบบสำหรับ Vendor</div>;
              },
              Footer() {
                return (
                  <>
                    <Button type="link" block onClick={toForgotPassword}>
                      ลืมรหัสผ่าน
                    </Button>
                    <Button
                      type="link"
                      block
                      onClick={() => {
                        signInWithRedirect({ provider: { custom: 'scg-azure-ad' } });
                      }}
                    >
                      เข้าสู่ระบบสำหรับพนักงาน SCG
                    </Button>
                  </>
                );
              },
            },
            ForgotPassword: {
              Header() {
                return <div style={{ fontWeight: 600, fontSize: 25 }}>เปลี่ยนรหัสผ่าน</div>;
              },
            },
            ConfirmResetPassword: {
              Header() {
                return (
                  <>
                    <div style={{ fontWeight: 600, fontSize: 25 }}>เปลี่ยนรหัสผ่าน</div>
                    <div>
                      <p style={{ fontWeight: 600, fontSize: 16 }}>รหัสผ่านต้องประกอบไปด้วย</p>
                      <ul style={{ color: '#4b4b4b', fontSize: 16 }}>
                        <li>จำนวนอย่างน้อย 8 ตัว</li>
                        <li>อักษรพิมพ์ใหญ่ (A-Z) อย่างน้อย 1 ตัว</li>
                        <li>อักษรพิมพ์เล็ก (a-z) อย่างน้อย 1 ตัว</li>
                        <li>ตัวเลข (0-9) อย่างน้อย 1 ตัว</li>
                        <li>อักขระพิเศษ (!@#$%&) อย่างน้อย 1 ตัว</li>
                      </ul>
                    </div>
                  </>
                );
              },
            },
          }}
          formFields={{
            forgotPassword: {
              username: {
                label: 'อีเมล',
                placeholder: 'กรุณาระบุอีเมลที่ใช้ในการลงชื่อเข้าใช้',
              },
            },
            confirmResetPassword: {
              confirmation_code: {
                label: 'รหัสยืนยันจากอีเมล',
                order: 3,
                placeholder: 'ระบุรหัสยืนยัน',
                isRequired: true,
              },
              password: {
                label: 'รหัสผ่านใหม่',
                order: 1,
                placeholder: 'กรุณาระบุ',
                type: 'password',
                isRequired: true,
              },
              confirm_password: {
                label: 'ยืนยันรหัสผ่านใหม่',
                order: 2,
                placeholder: 'กรุณาระบุ',
                type: 'password',
                isRequired: true,
              },
            },
          }}
          services={{
            async handleForgotPasswordSubmit(params) {
              const { username, confirmationCode, newPassword } = params;
              return confirmResetPassword({ username, confirmationCode, newPassword })
                .then((res) => {
                  logModifyPassword(params, true);
                  open();
                })
                .catch((error) => {
                  logModifyPassword(params, false);
                  throw error;
                });
            },
            async handleSignIn({ username, password }) {
              try {
                return await signIn({
                  username,
                  password,
                  options: {
                    authFlowType: 'USER_PASSWORD_AUTH',
                  },
                });
              } catch (error) {
                logLoginError(username);

                switch (error.name) {
                  case 'UserLambdaValidationException': {
                    let [, customErrorText] = error.message.split('PreAuthentication failed with error');
                    throw new Error(customErrorText);
                  }
                  case 'ForbiddenException': {
                    if (error.message === 'Request not allowed due to WAF block.') {
                      history.push('/blocked');
                      throw new Error(error.message);
                    }
                    throw error;
                  }
                  default: {
                    let customErrorText = 'Incorrect username or password.';
                    throw new Error(customErrorText);
                  }
                }
              }

              // return await signIn({
              //   username,
              //   password,
              //   options: {
              //     authFlowType: 'USER_PASSWORD_AUTH',
              //   },
              // }).catch((error) => {
              //   // logLoginError(username);
              //   console.log(1111, error);
              //   throw error;
              // });
            },
          }}
        />
        <Modal open={isVisible} onCancel={close} footer={null} closable={false}>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
            <CheckCircleFilled style={{ fontSize: 90, color: '#6bb915' }} />
          </div>
          <Title style={{ textAlign: 'center', fontSize: 25 }} level={4}>
            เปลี่ยนรหัสผ่านสำเร็จ
          </Title>
          <p style={{ textAlign: 'center', fontSize: 18, color: '#4b4b4b' }}>คุณได้ทำการเปลี่ยนรหัสผ่านเรียบร้อยแล้ว</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                backgroundColor: '#1890ff',
                color: '#fff',
                fontSize: 18,
                padding: '1.5rem 2rem',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
              }}
              onClick={gotoLogin}
              type="default"
            >
              เข้าสู่ระบบ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
  return (
    <>
      <Button
        onClick={() => {
          signInWithRedirect({ provider: { custom: 'scg-azure-ad' } });
        }}
        type="primary"
        block
        size="large"
      >
        สำหรับพนักงาน SCG
      </Button>
      <Divider></Divider>
      <Button
        onClick={() => {
          setisLoginVendor(true);
        }}
        block
        size="large"
      >
        สำหรับ Vendor
      </Button>
    </>
  );
};

const LogIn = () => {
  const history = useHistory();
  const auth = useAuth();
  useEffect(() => {
    if (auth.user) {
      history.push('/');
      return;
    }
  }, []);
  const { user, signOut, authStatus } = useAuthenticator((context) => [context.user]);

  useEffect(async () => {
    const session = await fetchAuthSession();
    if (authStatus === 'authenticated') {
      if (user && session.tokens.idToken && session.tokens.accessToken) {
        auth.loginCognito({ accessToken: session.tokens.idToken.toString(), signOut }, () => {
          history.push('/');
        });
      }
    }
  }, [authStatus, signOut, user]);

  return (
    <Style>
      <div className="side-container">
        <BannerLogin />
      </div>
      <div className="main-container">
        <div className="login-container">
          <RenderLoginContent />
        </div>
      </div>
    </Style>
  );
};

export default LogIn;
