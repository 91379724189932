import React from 'react';
import Logo from '@components/atoms/logo';
import Style from './style';

const BannerLogin = () => {
  return (
    <Style>
      <Logo className="logo" />
      
      <div className="banner-content">
        <div className="banner-content-title">
          Trinity operation<br/>
          list portal
        </div>
        <div className="banner-content-img">
          <img src="login-frame.png" alt='login-frame' />
        </div>
      </div>
    </Style>
  );
};

export default BannerLogin;
