import React, { useContext, createContext, useState } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { defaultState } from '../core';
import api from './api';

const AssociateDeviceContext = createContext({});

export const useAssociateDevice = () => useContext(AssociateDeviceContext);

const useProvideValue = () => {
  const [listAssociateDevices, setListAssociateDevices] = useState(defaultState);
  const [countAssociateDevices, setCountAssociateDevices] = useState(defaultState);

  const getListAssociateDevices = async (params, cb = () => {}) => {
    setListAssociateDevices((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getListAssociateDevices(params);
    if (res.status === '200') {
      setListAssociateDevices((prev) => ({
        ...prev,
        data: res.data,
        isLoading: false,
        isSuccess: true,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setListAssociateDevices((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const getCountAssociateDevices = async (params, cb = () => {}) => {
    setCountAssociateDevices((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await api.getCountAssociateDevices(params);
    if (res.status === '200') {
      setCountAssociateDevices((prev) => ({
        ...prev,
        data: res.data,
        isLoading: false,
        isSuccess: true,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setCountAssociateDevices((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  const exportAssociateDevices = async (params, cb = () => {}) => {
    const res = await api.exportAssociateDevices(params);
    if (res.status === '200') {
      return res.data;
    }
    message.error(res.message || 'Something went wrong!');
    return [];
  };

  return {
    listAssociateDevices,
    getListAssociateDevices,
    countAssociateDevices,
    getCountAssociateDevices,
    exportAssociateDevices,
  };
};

export const ProvideAssociateDevice = ({ children }) => (
  <AssociateDeviceContext.Provider value={useProvideValue()}>{children}</AssociateDeviceContext.Provider>
);

ProvideAssociateDevice.propTypes = {
  children: PropTypes.node,
};
