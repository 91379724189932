import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { I18n } from 'aws-amplify/utils';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';

I18n.setLanguage('en');
I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'เข้าสู่ระบบ',
  Username: 'Email',
  Password: 'Password',
  'Forgot your password?': 'เปลี่ยนรหัสผ่าน',
  'Enter your Password': ' ',
  'Enter your Username': ' ',
  'Confirm TOTP Code': 'ระบุรหัส OTP',
  Code: 'ระบุรหัส OTP',
  'Code *': 'OTP',
  'Send code': 'ส่งรหัสยืนยัน',
  'Back to Sign In': 'กลับสู่หน้าเข้าสู่ระบบ',
  'Submit': 'ยืนยัน',
  'Resend Code': 'ส่งรหัสยืนยันอีกครั้ง',
});

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.REACT_APP_USER_POOL_ID,
      userPoolClientId: config.REACT_APP_POLL_WEB_CLIENT_ID,
      region: 'ap-southeast-1',
      authenticationFlowType: 'USER_PASSWORD_AUTH',
      loginWith: {
        oauth: {
          domain: config.REACT_APP_COGNITO_DOMAIN,
          scopes: ['openid', 'email', 'profile'],
          redirectSignIn: [`${window.location.origin.toString()}/login`],
          redirectSignOut: [`${window.location.origin.toString()}/login`],
          responseType: 'code',
        },
      },
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
