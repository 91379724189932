import config from '@root/config';
import { getToken } from '@root/services/auth';
import axios from 'axios';
import { get } from 'lodash';
import querystring from 'query-string';

let baseURL = config.API_ENDPOINT;

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (param) => querystring.stringify(param, { skipNull: true, arrayFormat: 'bracket' }),
});

instance.interceptors.request.use(async (config) => {
  if (['get', 'delete'].includes(config.method || '')) {
    config.data = get(config, 'data', {});
  }
  return {
    ...config,
    headers: {
      ...get(config, 'headers', {}),
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  };
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { instance };
