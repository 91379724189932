export const definingRoles = [
  // {
  //   value: 'all-solution',
  //   title: 'All Solution',
  // },
  {
    value: 'solar',
    title: 'Solar',
  },
  {
    value: 'aaf',
    title: 'AAF',
  },
  {
    value: 'aaq',
    title: 'AAQ',
  },
  {
    value: 'aafq',
    title: 'AAFQ',
  },
  // {
  //   value: 'deebox',
  //   title: 'Debox',
  // },
  // {
  //   value: 'tuya',
  //   title: 'Tuya',
  // },
  // {
  //   value: 'ev',
  //   title: 'EV',
  // },
  // {
  //   value: 'smart_living',
  //   title: 'Smart Living',
  // },
  // {
  //   value: 'smart_tracker',
  //   title: 'Smart Tracker',
  // },
];

export const allSolutions = definingRoles.map(role => role.value);
