import React from "react";
import TextCardTableDevice from "./style";
import TextCardTableDeviceBodyNcc from "../textcardTableDeviceBody";

const TextCardTableDeviceNcc = (props) => {
  return (
    <TextCardTableDevice>
      <div className="wrapTextCardTableDevice">
        <div className="titleTableDevice">
          <div className="titleTableDeviceL">
            <h4>ชื่ออุปกรณ์</h4>
            <h4>รุ่น</h4>
            <h4>ประเภทของอุปกรณ์</h4>
            <h4>สัญญาน</h4>
            <h4>สถานะอุปกรณ์</h4>
            <h4>ความเร่งด่วน</h4>
          </div>
          <div className="titleTableDeviceR">
            <div className="titleTableDeviceRL">
              <div className="titleTableDeviceInRL">
                <h4>แบตเตอรี่</h4>
              </div>
              <div className="titleTableDeviceInRLDown">
                <h4>ประเภท</h4>
                <h4>คงเหลือ</h4>
                <h4>จำนวน</h4>
              </div>
            </div>
            <div className="titleTableDeviceRL">
              <div className="titleTableDeviceInRL">
                <h4>การรับประกัน</h4>
              </div>
              <div className="titleTableDeviceInRLDownB">
                <h4>ช่วงเวลา</h4>
                <h4>คงเหลือ</h4>
              </div>
            </div>
          </div>
        </div> {/* end titleTableDevice */}
        <TextCardTableDeviceBodyNcc deviceHome={props?.deviceHome} />
      </div>
    </TextCardTableDevice>
  )
}

export default TextCardTableDeviceNcc;