import styled from 'styled-components';

export const ModalStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .title-block {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        margin-bottom: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      max-width: 250px;
      text-align: center;
    }
    .des {
      text-align: center;
      max-width: 315px;
      font-size: 16px;

    }
  }
`;

export default styled.div`
  .form-bind {
    margin-left: 90px;
    margin-top: 40px;
    max-width: 780px;
  }

  .divide-card {
    background: #eff2f5;
    height: 20px;
    width: 100%;
    left: 0;
    position: absolute;
    margin-top: 40px;
  }
  .layout-card-data {
    margin-top: 60px;
    padding: 50px 26px 26px 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .text-not-found {
      font-size: 20px;
      font-weight: 600;
      white-space: pre-line;
      width: 400px;
      text-align: center;
      margin-top: 21px;
    }
    .layout-info {
      width: 100%;
      padding-left: 64px;
      padding-right: 64px;
      .customer-info-detail {
        display: flex;
        /* justify-content: space-between; */
        gap: 200px;
        .chip-status {
          background: gray;
          width: max-content;
          font-size: 12px;
          padding: 1px 10px;
          border-radius: 10px;
          &.success {
            background: #e1fcef;
            color: #1e7d34;
          }
          &.error {
            background: #faf0f3;
            color: #e41b17;
          }
        }
      }
    }
    .layout-button {
      display: flex;
      justify-content: end;
      gap: 40px;
    }
  }
`;
