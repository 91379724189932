import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Typography, Tag } from 'antd';
import { definingRoles } from '@root/utils';
import {useAuth} from '@store/auth/hook';
import {hasUpdateAccess} from '@root/utils/permission';
const UserGroupTable = ({ items }) => {
  const { user } = useAuth();
  const columns = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      width: 50,
    },
    {
      title: 'User group name',
      dataIndex: 'name',
      key: 'name',
      width: 150,
    },
    {
      title: 'Full role',
      dataIndex: 'fullRole',
      key: 'fullRole',
      width: 150,
      render: val => val.split(',').map((role, index) => (
        <Tag key={index} color="red">{definingRoles.find((el) => role === el.value)?.title}</Tag>
      )),
    },
    hasUpdateAccess(user, 'user-groups') && {
      title: 'Action',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => <Link to={`/dashboard/user-groups/${record.id}`}>Edit</Link>,
    },
  ].filter(x => x);

  return (
    <>
      <Typography.Text type="secondary">Found {items.length} records.</Typography.Text>
      <Table columns={columns} dataSource={items} rowKey="id" />
    </>
  );
};

UserGroupTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};

export default UserGroupTable;
