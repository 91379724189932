import { instance } from '@root/utils/axios';

export const fetchRawEdgeApplications = async (params) => {
  return instance.get('/edge-applications/raw-actuator', { params });
};

export const transferEdgeData = async (body) => {
  return instance.put('/edge-data/data/transfer_aggregate_key', body);
};

export const deleteEdgeData = async (body) => {
  return instance.delete('/edge-data/data/clear_aggregate_data', { data: body });
};

export const fetchCheckinHistory = async (params) => {
  const { edge_id, from, to } = params;
  const res = await instance.get(`/edge-applications/${edge_id}/checkin-histories`, { params: { from, to } });
  return res.data;
};

export const fetchRawEdgeApplicationsPagination = async (params) => {
  const res = await instance.get('/edge-applications/pagination', { params });
  return res.data;
};

export const fetchEdgeStatusDevice = async (params) => {
  const res = await instance.get('/edge-applications/edge-status-devices-2', { params });
  return res.data;
};

export const fetchSCGID = async (id) => {
  const res = await instance.get(`/edge-applications/${id}/scgid`);
  return res.data;
};

export const fetchEdgeInformation = async (edgeID) => {
  try {
    const res = await instance.get(`/edge-applications/${edgeID}`);
    return res.data;
  } catch (error) {
    console.log('error fetch edge info from EP', error);
    return [];
  }
};

export const fetchEdgeDevicesInformationFromTuya = async (edgeID) => {
  try {
    const res = await instance.get(`/edge-applications/tuya/edge/${edgeID}/devices`);
    return res.data;
  } catch (error) {
    console.log('error fetch edge devices info from Tuya', error);
    return [];
  }
};
