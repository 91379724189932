import React, { useContext, createContext, useState } from 'react';
import { message } from 'antd';
import { getOneCustomer, editCustomer } from '../api';

const customerContext = createContext();

const useEditCustomer = () => {
  return useContext(customerContext);
};

const useProvideCustomer = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = async (id) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await getOneCustomer(id);
    if (res.status === '200') {
      setData(res.data);
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  const updateData = async (id, form) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await editCustomer(id, form);
    if (res.status === '200') {
      message.success('Update successful');
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    getData,
    updateData,
  };
};

const ContextEditCustomer = ({ children }) => {
  const roof = useProvideCustomer();

  return <customerContext.Provider value={roof}>{children}</customerContext.Provider>;
};

export { useEditCustomer, ContextEditCustomer };
