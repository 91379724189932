import { instance } from '@root/utils/axios';
import { map } from 'lodash';

export const fetchCustomers = async (params, searchBy, searchValue) => {
  const sort = map(params.sort, (type, index) => {
    // eslint-disable-next-line prefer-destructuring
    const key = Object.keys(type)[0];
    if (index === params.sort.length - 1) {
      return `${key}:${type[key]}`;
    }
    return `${key}`;
  }).join();

  return instance.get(`customers?${searchBy}=${searchValue}`, { params: { ...params, sort } });
};

export const fetchTotalCustomer = async () => {
  return instance.get('customers/count');
};

export const fetchActionHistory = async ({ type, id }) => {
  return instance.get('actions', { params: { type, id } });
};

export const getCustomerByPhone = async (phone_no) => {
  return instance.get(`customers/get/by-phone?phone_no=${phone_no}`);
};

export const getCustomerByID = async (id) => {
  return instance.get(`customers/get/by-id?id=${id}`);
};
