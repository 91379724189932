import config from '@config';
import { HttpAuth } from '@services/http';

const path = `${config.API_ENDPOINT}/edge-associate-devices`;

const getListAssociateDevices = async (params = {}) => {
  return HttpAuth.get(path, { params });
};

const getCountAssociateDevices = async (params = {}) => {
  return HttpAuth.get(`${path}/count`, { params });
};

const exportAssociateDevices = async (params = {}) => {
  return HttpAuth.get(`${path}/export`, { params });
};

export default {
  getListAssociateDevices,
  getCountAssociateDevices,
  exportAssociateDevices,
};
