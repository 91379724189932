import React, { useContext, createContext, useState } from 'react';
import { element } from 'prop-types';

import { message } from 'antd';
import { defaultState } from '../core';
import api from './api';

const roleContext = createContext();

const useRole = () => {
  return useContext(roleContext);
};

const useProvideRole = () => {
  const [stateEditRole, setStateEditRole] = useState(defaultState);
  const [stateNewRole, setStateNewRole] = useState(defaultState);
  const [stateRole, setStateRole] = useState(defaultState);
  const [listRoles, setListRoles] = useState([]);

  const getListRoles = async (cb = () => {}) => {
    const res = await api.getListRoles();
    if (res.status === '200') {
      setListRoles(res.data);
    } else {
      message.error(res.message || 'Something went wrong!');
    }
  };

  const getOneRole = async (id, cb = () => {}) => {
    setStateRole((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.getOneRole(id);
    if (res.status === '200') {
      setStateRole({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error('Not found!');
      setStateRole({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const newRole = async (form, cb = () => {}) => {
    setStateNewRole((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.newRole(form);
    if (res.status === '200') {
      message.success('Success to new role!');
      setStateNewRole({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateNewRole({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const editRole = async (id, form, cb = () => {}) => {
    setStateEditRole((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.editRole(id, form);
    if (res.status === '200') {
      message.success('Success to update user!');
      setStateEditRole({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateEditRole({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  return {
    listRoles,
    stateRole,
    stateNewRole,
    stateEditRole,
    getListRoles,
    getOneRole,
    newRole,
    editRole,
  };
};

const ProvideRole = ({ children }) => {
  const val = useProvideRole();

  return <roleContext.Provider value={val}>{children}</roleContext.Provider>;
};

ProvideRole.propTypes = {
  children: element.isRequired,
};

export { useRole, ProvideRole };
