import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Table from '@components/atoms/table';

const EdgeWifiLogTable = ({ items }) => {
  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: val => {
        if (!val) return '-';
        const { firstName, lastName } = val;
        return [firstName, lastName].filter(name => name).join(' ');
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (val) => dayjs(val).format('YYYY-MM-DD hh:mm:ss'),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={items}
      rowKey="id"
      pagination={{ total: items.length, pageSize: 5 }}
    />
  );
};

EdgeWifiLogTable.propTypes = {
  items: PropTypes.array,
};

EdgeWifiLogTable.defaultProps = {
  items: [],
};

export default EdgeWifiLogTable;
