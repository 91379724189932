import styled from 'styled-components';

const Style = (ComponentName) => styled(ComponentName)`
  .ant-badge-status-dot {
    width: 15px;
    height: 15px;
  }
`;

export default Style;
