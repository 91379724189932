import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function CallCenterPortalCustomerTable({ items }) {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title_name',
      key: 'title_name',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Phone No.',
      dataIndex: 'telephone',
      key: 'telephone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'SCGID',
      dataIndex: 'scg_id',
      key: 'scg_id',
    },
    {
      title: 'Action',
      render: (record) => (
        <Link to={`/dashboard/call-center-portal/${record.id}`}>
          <span style={{ fontWeight: 'bold' }}>View</span>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Typography.Text type="primary" style={{ fontSize: 20 }} strong>
        Search result found {items.length} lists.
      </Typography.Text>
      <WrapTable>
        <Table
          pagination={false}
          columns={columns}
          dataSource={items}
          rowKey="id"
          style={{ marginTop: 24 }}
          sticky={true}
        />
      </WrapTable>
    </div>
  );
}

const WrapTable = styled.div`
  .ant-table-thead .ant-table-cell {
    background-color: #deedf7;
    color: #696969;
    font-size: 14px;
    font-weight: 600;
  }
`;
