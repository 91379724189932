import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select } from 'antd';
import { get } from 'lodash';

const { Option } = Select;

const SearchEdgeWifiForm = ({ onFinish, isLoading, initialValue, tenantsData, user = [] }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} onFinish={onFinish} layout="inline">
      <Form.Item name="edgeId">
        <Input placeholder="Edge Barcode" />
      </Form.Item>
      {get(user, 'roles', []).includes('tenant') && (
        <Form.Item name="tenantId" initialValue={initialValue}>
          <Select style={{ width: '200px' }}>
            {tenantsData &&
              tenantsData.length > 0 &&
              tenantsData.map((tenant) => (
                <Option key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

SearchEdgeWifiForm.propTypes = {
  onFinish: PropTypes.func,
  isLoading: PropTypes.bool,
  initialValue: PropTypes.number,
  tenantsData: PropTypes.array,
  user: PropTypes.arrayOf(PropTypes.object),
};

SearchEdgeWifiForm.defaultProps = {
  onFinish: () => {},
  isLoading: false,
};

export default SearchEdgeWifiForm;
