import React from 'react';
import { array } from 'prop-types';
import dayjs from 'dayjs';
import { Typography } from 'antd';

import Table from '@components/atoms/table';

const { Text } = Typography;

const AbnormalStatusHistoryTable = (props) => {
  const { items } = props;

  const columns = [
    {
      title: 'Start time',
      dataIndex: 'startedTime',
      key: 'startedTime',
      render: (val) => dayjs(val).format('YYYY-MM-DD'),
    },
    {
      title: 'End time',
      dataIndex: 'endedTime',
      key: 'endedTime',
      render: (val) => dayjs(val).format('YYYY-MM-DD'),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (val) => <Text type="warning">{val}</Text>,
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={items} rowKey="id" pagination={false} />
    </div>
  );
};

AbnormalStatusHistoryTable.propTypes = {
  items: array,
};

AbnormalStatusHistoryTable.defaultProps = {
  items: [],
};

export default AbnormalStatusHistoryTable;
