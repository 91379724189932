import styled from 'styled-components';

const Style = styled.div`
  .custom-header {
    display: flex;
    justify-content: space-between;
  }

  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    button {
      height: 100%;

      span {
        text-transform: capitalize;
      }
    }
  }

  .trigger:hover {
    color: #1890ff;
  }

  .logo {
    height: 32px;
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
    position: relative;
  }

  .layout-content {
    margin: 24px 16px;
    padding: 24px;
    min-height: 280;
  }
`;

export default Style;
