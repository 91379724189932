import { Pie } from '@ant-design/charts';
import { PAGE_REFRESHING_ATOM, TRINITY_DASHBOARD_ATOM } from '@root/atoms/dashboard.atom';
import { fetchEdgeStatus } from '@root/services/dashboard.service';
import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { configOnOffChart } from '../../chartConfig';
import Styled from './style';

const EdgeStatus = () => {
  const [refresh] = useAtom(PAGE_REFRESHING_ATOM);
  const [params] = useAtom(TRINITY_DASHBOARD_ATOM);
  const { data, isLoading, isFetching } = useQuery(
    ['edge-status', params, refresh.flag],
    () => fetchEdgeStatus(params),
    {
      refetchInterval: refresh.every,
    },
  );

  const dataEdge = useMemo(() => {
    const result = data?.data[0];
    return {
      total: result?.total,
      data: [
        { type: 'online', value: result?.online },
        { type: 'offline', value: result?.offline },
      ],
    };
  }, [data]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <Styled>
        <h2>Edge Status</h2>
        <Pie animation={false} {...configOnOffChart(dataEdge?.data)} />
        <h3>Total: {dataEdge?.total}</h3>
      </Styled>
    </Spin>
  );
};

export default EdgeStatus;
