import React from 'react';
import { bool, arrayOf, object, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Typography } from 'antd';
import { hasUpdateAccess } from '@root/utils/permission';
import { useAuth } from '@store/auth/hook';

const EdgeWifiTable = ({ items, loading, onChange, pagination }) => {
  const { user } = useAuth();
  const columns = [
    {
      title: 'Edge Barcode',
      dataIndex: 'edge_barcode',
      key: 'edge_barcode',
      render: (val) => val || '-',
    },
    {
      title: 'SSID',
      dataIndex: 'ssid',
      key: 'ssid',
      render: (val) => val || '-',
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      render: (val) => val || '-',
    },
    hasUpdateAccess(user, 'edge-wifi') && {
      title: 'Action',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => <Link to={`/dashboard/edge-wifi/${record.id}`}>Edit</Link>,
    },
  ].filter(x => x);

  return (
    <>
      <Typography.Text type="secondary">Found {items.length} records.</Typography.Text>
      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        loading={{
          spinning: loading,
          tip: 'Loading...',
        }}
        onChange={onChange}
        pagination={{...pagination, showSizeChanger: true }}
      />
    </>
  );
};

EdgeWifiTable.propTypes = {
  items: arrayOf(object),
  loading: bool,
  onChange: func,
  pagination: object,
};

EdgeWifiTable.defaultProps = {
  items: [],
  loading: false,
  onChange: () => {},
  pagination: {},
};

export default EdgeWifiTable;
