import { instance } from '@root/utils/axios';

export const fetchLogManagement = async (queries) => {
  const res = await instance.get('/actions', {
    params: { ...queries },
  });
  const items = res.data.items.map((log) => {
    return {
      ...log,
      payload: log.payload ? JSON.stringify(log.payload) : '-',
      result: log.result ? JSON.stringify(log.result) : '-',
    };
  });
  const payloads = res.data.items.map((log) => {
    return {
      ...log,
      payload: log.payload ? JSON.stringify(log.payload).replaceAll(',', '|') : '-',
      result: log.result ? JSON.stringify(log.result).replaceAll(',', '|') : '-',
    };
  });
  res.data.items = items;
  res.data.payloads = payloads;
  return res.data;
};

export const exportLogManagement = async (query, searchBy) => {
  const res = await instance.get('/actions', { params: { pageNumber: 1, pageSize: 0, query, searchBy } });
  const items = res.data.items.map((log) => {
    return {
      ...log,
      payload: log.payload ? JSON.stringify(log.payload).replaceAll(',', '|') : '-',
      result: log.result ? JSON.stringify(log.result).replaceAll(',', '|') : '-',
      entityId: log.entityId || log.entityId !== '' ? log.entityId : '-',
    };
  });
  return items;
};

export const logModifyPassword = async (form, success) => {
  const res = await instance.post('/auth/modify-password', {
    email: form.username,
    isSuccess: success
  });
};

export const logLoginError = async (username) => {
  const res = await instance.post('/auth/login-fail', {
    email: username,
  });
};

