import styled from 'styled-components';

export default styled.div`
  label {
    font-weight: bold;
  }
  .ant-table-cell {
    ul {
      padding-left: 15px;
    }
  }
`;