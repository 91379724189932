import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Card, Typography } from 'antd';
import moment from 'moment';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import AirFlowChartGroup from '@root/components/molecules/airFlowChartGroup';
import AirInformation from '@root/components/molecules/airInformation';
const {Text, Title } = Typography;
const handleEmptyDate = (val, format = dateFormat) => (val ? moment(val).format(format) : '-');

const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'DD/MM/YYYY HH:mm';
const handleEmpty = (val) => val || '-';
moment.locale('en', {
  week: {
    dow: 1,
  },
});

const ActiveAirFlowTab = () => {
  const {
    isFetching,
    dataEdge,
    dataActiveAirFlowTodayInformation,
    getActiveAirFlowTodayInformation,
    getActiveAirFlowStatistic,
    getEdgeDashboard,
  } = useEdgeApplication();
  const [airInformationAreaName, setAirInformationAreaName] = useState('indoor');
  const [edgeBarcode, setEdgeBarcode] = useState();
  const [airData, setAirData] = useState([]);

  const onChangeAirInformationAreaName = (value) => {
    setAirInformationAreaName(value);
  };

  useEffect(() => {
    if (dataEdge?.edge_barcode) {
      setEdgeBarcode(dataEdge.edge_barcode);
    }
  }, [dataEdge]);

  useEffect(() => {
    if (!edgeBarcode) return;
    getActiveAirFlowTodayInformation(edgeBarcode, { area: airInformationAreaName });
  }, [edgeBarcode, airInformationAreaName]);

  useEffect(() => {
    if (dataActiveAirFlowTodayInformation && dataActiveAirFlowTodayInformation.data) {
      let zoneDataArr = [];
      for (var [title, data] of Object.entries(dataActiveAirFlowTodayInformation.data)) {
        zoneDataArr.push({
          title: title,
          value: data ? data['value'] : 'No Data',
          unit: data ? data['unit'] : '',
          level: data ? data['comfort_level']['level'] : '',
          color: data ? data['comfort_level']['color'] : '',
        });
      }
      setAirData(zoneDataArr);
    }
  }, [dataActiveAirFlowTodayInformation]);

  useEffect(() => {
    airData &&
      airData.forEach((v) => {
        getActiveAirFlowStatistic(edgeBarcode, {
          date: moment().format(dateFormat),
          data_types: v.title,
        });
      });
  }, [airData]);

  useEffect(() => {
    if (edgeBarcode) {
      getEdgeDashboard(edgeBarcode);
    }
  }, [edgeBarcode]);

  const packageName = useMemo(() => {
    const appList = dataEdge?.application_devices || [];
    const findName = (name) => appList.find((app) => app.application_name === name)?.commercial_name;
    return findName('aaf') || findName('aafq');
  }, [dataEdge]);

  const getWarrantyEndDate = (installationDate) => {
    if(null != installationDate){
      var instDate = moment(installationDate, "YYYY-MM-DD HH:mm:ss");
      instDate.add(1, 'y');
      return instDate.format(dateTimeFormat);
    }
    return '';
  }

  return (
    <Row gutter={[16, 32]}>
      <Col span={12}>
        <Title level={5}>Package name: {packageName}</Title>
        <Title level={5} style={{marginTop: "0px"}}><span>Installation date: {handleEmptyDate(dataEdge?.installed_date, dateTimeFormat)}</span></Title>
        <Title level={5} style={{marginTop: "0px"}}>
              Warranty Period: 1 Year {' ('+getWarrantyEndDate(dataEdge?.installed_date) +')'}
            </Title>
      </Col>
      {/* <Col span={12} style={{ textAlign: 'right' }}>
        <Title level={5}>
          {`Updated at ${handleEmpty(moment(dataEdge?.updated_at).format(dateTimeFormat))}`}
        </Title>
      </Col> */}
      <Col span={24}>
        <Card title="Today information">
          {(dataActiveAirFlowTodayInformation || isFetching.dataActiveAirFlowTodayInformation) && (
            <AirInformation
              onChangeAirInformationAreaName={onChangeAirInformationAreaName}
              airInformationAreaName={airInformationAreaName}
              airData={airData}
              type={'aaf'}
              isLoading={isFetching.dataActiveAirFlowTodayInformation}
            />
          )}
        </Card>
      </Col>

      <AirFlowChartGroup edgeBarcode={edgeBarcode} />
    </Row>
  );
};

export default ActiveAirFlowTab;
