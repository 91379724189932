import styled from 'styled-components';

export default styled.div`
  padding: 10px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  background-color: rgb(250, 250, 250);
  box-shadow: rgb(220 220 220 / 50%) 0px 4px 4px 2px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
`;

export const CardContainer = styled.div`
  height: 320px;
  width: 100%;
  display: flex;
  padding: 10px;
  text-align: center;
  flex-direction: column;
  background-color: rgb(250, 250, 250);
  box-shadow: rgb(220 220 220 / 50%) 0px 4px 4px 2px;
`;
