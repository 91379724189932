import EditUserGroupForm from '@components/organisms/forms/editUserGroupForm';
import { useUser } from '@root/store/user/hook';
import { useUserGroup } from '@root/store/userGroup/hook';
import { Breadcrumb, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const { Title } = Typography;

const UserGroupEdit = () => {
  const { id } = useParams();
  const { stateUserGroup, stateEditUserGroup, getOneUserGroup, editUserGroup } = useUserGroup();
  const { listUser, getListUser } = useUser();
  const [data, setData] = useState({});

  const onFinish = (values) => {
    editUserGroup(id, values);
  };

  useEffect(() => {
    getOneUserGroup(id);
  }, [id]);

  useEffect(() => {
    if (stateUserGroup.isSuccess && !stateUserGroup.isLoading) {
      setData(stateUserGroup.data);
    }
  }, [stateUserGroup.isLoading]);

  useEffect(() => {
    if (stateEditUserGroup.isSuccess && !stateEditUserGroup.isLoading) {
      setData(stateEditUserGroup.data);
    }
  }, [stateEditUserGroup.isLoading]);

  useEffect(() => {
    getListUser();
  }, []);

  return (
    <div>
      <Title level={2}>User Group Edit</Title>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item>
          <Link to="/dashboard/user-groups">User groups</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ID: {id}</Breadcrumb.Item>
      </Breadcrumb>
      <EditUserGroupForm
        data={data}
        listUser={listUser}
        onFinish={onFinish}
        isLoading={stateUserGroup.isLoading}
        isSaving={stateEditUserGroup.isLoading}
        btnCancel={false}
      />
    </div>
  );
};

export default UserGroupEdit;
