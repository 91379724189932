import styled from "styled-components";

const SessionExp = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 64px);
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .wrapSessionExp {
    width: 544px;
    height: auto;
    padding: 24px 16px;
    background-color: #FFFFFF;
    border-radius: 4px;
  }
  h2 {
    font-family: 'Sarabun', sans-serif;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    margin: 0;
  }
  .wrapBtnSession {
    width: 100%;
    height: auto;
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .btnSession {
    width: 108px;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: #40647B;
    color: #FFFFFF;
    font-family: 'Sarabun', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    cursor: pointer;
  }
`;

export default SessionExp;