import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #f7f7f7;
  position: relative;

  width:  100%;
  height: 100%;

  .logo {
    position: absolute;
    top: 70px;
    left: 65px;
  }

  .banner-content {
    padding: 80px;
    .banner-content-title {
      font-size: 18px;
      font-weight: bold;
      color: #6d6e70;
      text-align: center;
      
      padding-bottom: 12px;
    }

    .banner-content-img {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
`;