import config from '@config';
import QueryString from 'query-string';

import { HttpAuth } from '@services/http';
const path = `${config.API_ENDPOINT}/edge-wifis`;

const getListEdgeWifi = async (filter) => {
  const query = QueryString.stringify(filter, { skipEmptyString: true });

  return HttpAuth.get(`${path}?${query}`);
};

const getEdgeWifiPagination = async (params = {}) => {
  return HttpAuth.get(`${path}/pagination`, { params });
};

const getEdgeWifiCount = async (params = {}) => {
  return HttpAuth.get(`${path}/count`, { params });
};

const getOneEdgeWifi = async (id) => {
  return HttpAuth.get(`${path}/${id}`);
};

const updateOneEdgeWifi = async (id, form) => {
  return HttpAuth.patch(`${path}/${id}`, form);
};

export {
  getListEdgeWifi, getEdgeWifiPagination, getEdgeWifiCount, getOneEdgeWifi, updateOneEdgeWifi,
};
