import { element } from 'prop-types';
import { createContext, useContext, useState } from 'react';

import { message } from 'antd';
import { useAuth } from '../auth/hook';
import { defaultState } from '../core';
import api from './api';

const userGroupContext = createContext();

const useUserGroup = () => {
  return useContext(userGroupContext);
};

const useProvideUserGroup = () => {
  const { getProfile } = useAuth();
  const [stateEditUserGroup, setStateEditUserGroup] = useState(defaultState);
  const [stateNewUserGroup, setStateNewUserGroup] = useState(defaultState);
  const [stateUserGroup, setStateUserGroup] = useState(defaultState);
  const [tenants, setTenants] = useState(defaultState);
  const [listUserGroups, setListUserGroups] = useState([]);

  const getListUserGroups = async (cb = () => {}) => {
    const res = await api.getListUserGroups();
    if (res.status === '200') {
      setListUserGroups(res.data);
    } else {
      message.error(res.message || 'Something went wrong!');
    }
  };

  const getOneUserGroup = async (id, cb = () => {}) => {
    setStateUserGroup((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.getOneUserGroup(id);
    if (res.status === '200') {
      setStateUserGroup({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error('Not found!');
      setStateUserGroup({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const newUserGroup = async (form, cb = () => {}) => {
    setStateNewUserGroup((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.newUserGroup(form);
    if (res.status === '200') {
      message.success('Success to new user group!');
      setStateNewUserGroup({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateNewUserGroup({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const editUserGroup = async (id, form, cb = () => {}) => {
    setStateEditUserGroup((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.editUserGroup(id, form);
    if (res.status === '200') {
      message.success('Success to update user!');
      setStateEditUserGroup({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
      getProfile();
      cb();
    } else {
      message.error(res.message || 'Something went wrong!');
      setStateEditUserGroup({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  const getTenants = async (params) => {
    setTenants((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const res = await api.getTenants(params);
    if (res.status === '200') {
      setTenants({
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: res.data,
      });
    } else {
      message.error('Not found!');
      setTenants({
        isLoading: false,
        isSuccess: false,
        isError: true,
        data: null,
      });
    }
  };

  return {
    listUserGroups,
    stateUserGroup,
    stateNewUserGroup,
    stateEditUserGroup,
    getListUserGroups,
    getOneUserGroup,
    newUserGroup,
    editUserGroup,
    tenants,
    getTenants,
  };
};

const ProvideUserGroup = ({ children }) => {
  const val = useProvideUserGroup();

  return <userGroupContext.Provider value={val}>{children}</userGroupContext.Provider>;
};

ProvideUserGroup.propTypes = {
  children: element.isRequired,
};

export { useUserGroup, ProvideUserGroup };
