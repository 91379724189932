import styled from 'styled-components';
import TextCardNcc from '@root/components/ncc/textcard';
import { useEffect, useState } from 'react';
import { fetchProducts } from '@root/services/master-data.service';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { fetchEdgeDevicesInformationFromTuya, fetchEdgeInformation } from '@root/services/edge-applications.service';

const Loading = () => {
  return <div>Loading</div>;
};

export default function NCCContentForCallCenter({ data }) {
  const [addresses, setAddresses] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [edges, setEdges] = useState();
  const [selectedEdgeID, setSelectedEdgeID] = useState();
  const [selectedEdgeMembers, setSelectedEdgeMembers] = useState([]);
  const [selectedEdgeDevices, setSelectedEdgeDevices] = useState([]);
  const [spinning, setSpinning] = useState(false);

  const getAddress = () => {
    let addressList = [];
    data?.edge_applications.map((dataAddress) => {
      addressList.push(dataAddress.address);
    });

    const addressResult = addressList.reduce((item, i) => {
      if (!item.some((obj) => obj?.latitude === i?.latitude && obj?.longitude === i?.longitude)) {
        item.push(i);
      }
      return item;
    }, []);
    setAddresses(addressResult);
    setSelectedAddress(addressResult[0]);
  };

  const getEdgeList = () => {
    const edgeList = [];
    data?.edge_applications.map((item) => {
      if (
        item.address?.latitude === selectedAddress?.latitude &&
        item.address?.longitude === selectedAddress?.longitude
      ) {
        edgeList.push(item.edge_barcode);
      }
    });
    setEdges(edgeList);
    setSelectedEdgeID(edgeList[0]);
  };

  const getSelectedEdgeInfo = async () => {
    setSpinning(true);

    const [edgeInfoFromEP, edgeDevicesInfoFromTuya] = await Promise.all([
      fetchEdgeInformation(selectedEdgeID),
      fetchEdgeDevicesInformationFromTuya(selectedEdgeID),
    ]);
    const edgeDevicesInfoFromEP = edgeInfoFromEP.application_devices.flatMap((application) => application.devices);

    let productsBarcodesFromEP = [];
    let productIDsFromEP = [];

    edgeDevicesInfoFromEP.forEach((deviceEP) => {
      if (deviceEP.device_barcode) {
        productsBarcodesFromEP.push(deviceEP.device_barcode);
      }
      if (deviceEP.reference) {
        productIDsFromEP.push(deviceEP.reference);
      }
    });

    let productBarcodesFromTuya = [];
    let productIDsFromTuya = [];

    edgeDevicesInfoFromTuya.forEach((deviceTuya) => {
      if (deviceTuya.id) {
        productBarcodesFromTuya.push(deviceTuya.id);
      }
      if (deviceTuya.product_id) {
        productIDsFromTuya.push(deviceTuya.product_id);
      }
    });

    const mergedProductBarcodes = [...new Set(productsBarcodesFromEP.concat(productBarcodesFromTuya))];
    const mergedProductIDs = [...new Set(productIDsFromEP.concat(productIDsFromTuya))];

    const edgeDevicesInfoFromMasterData = await getDevicesInfoFromMasterData(mergedProductIDs);

    const edgeDevicesMerged = mergedProductBarcodes.map((barcode) => {
      const ep = edgeDevicesInfoFromEP?.find((deviceEP) => deviceEP.device_barcode === barcode);
      const tuya = edgeDevicesInfoFromTuya?.find((deviceTuya) => deviceTuya.id === barcode);
      const master = edgeDevicesInfoFromMasterData?.find(
        (deviceMaster) => deviceMaster.product_id === tuya?.product_id || deviceMaster.product_id === ep?.reference,
      );

      return {
        name: master?.product_name || tuya?.name || ep?.device_type,
        model: master?.model || tuya?.model || ep?.model,
        signal: formatSignalType(tuya?.connection_protocol || master?.input_protocal),
        online: tuya?.online,
        device_status_update_at: tuya?.device_status_update_at,
        device_sensitivity: master?.device_sensitivity,
        battery_type: master?.battery?.battery_type || tuya?.batterty_type,
        battery_amount: master?.battery?.battery_qty || tuya?.battery_amout, // 'amout' is not a typo
        battery_remain: tuya?.battery_remain,
        installed_date: ep?.installed_date,
        warranty_expiry_date: ep?.warranty_expiry_date,
        device_type: ep?.device_type,
        device_barcode: barcode,
      };
    });
    setSelectedEdgeDevices(edgeDevicesMerged);

    const members = edgeInfoFromEP.customers
      .map((customer) => {
        return {
          name: `${customer?.title_name || ''} ${customer?.first_name} ${customer?.last_name}`,
          scgId: customer?.scg_id_detail?.scg_id,
          status: edgeInfoFromEP?.owned_customer?.id === customer.id ? 'Owner' : 'Member',
          tel: customer?.telephone,
        };
      })
      .sort(sortHouseMembersFunction);

    setSelectedEdgeMembers(members);
    setSpinning(false);
  };

  const sortHouseMembersFunction = (a, b) => {
    if (a.status === 'Owner' && b.status === 'Member') {
      return -1;
    } else if (a.status === 'Member' && b.status === 'Owner') {
      return 1;
    } else {
      return 0;
    }
  };

  const formatSignalType = (signal) => {
    switch (signal) {
      case 'zigbee':
        return 'Zigbee';

      case 'wifi':
        return 'Wi-Fi';

      default:
        return signal;
    }
  };

  const getDevicesInfoFromMasterData = async (productIDs) => {
    try {
      const masterDataSearchParams = new URLSearchParams(
        productIDs.map((product_id, index) => {
          return [`filters[product_id][$in][${index}]`, product_id];
        }),
      );
      return await fetchProducts(masterDataSearchParams);
    } catch (error) {
      console.log('error fetch products from master data', error);
      return [];
    }
  };

  const checkMonitoringStatus = () => {
    const selectedEdge = data?.edge_applications?.find((edge) => edge.edge_barcode === selectedEdgeID);
    return selectedEdge?.monitoring_status.replace('_', ' ');
  };

  const getSolution = () => {
    const selectedEdge = data?.edge_applications?.find((edge) => edge.edge_barcode === selectedEdgeID);
    let applicationList = [];

    selectedEdge?.application_devices?.forEach((application) => {
      applicationList.push(application.application_name);
    });

    const solutionList = [...new Set(applicationList)];

    return solutionList.join(', ');
  };

  const checkOwner = () => {
    return data?.owned_edge_applications.some((edge) => edge.edge_barcode === selectedEdgeID) ? 'Owner' : 'Member';
  };

  const handleOnChangeAddress = (selectedAddress) => {
    setSelectedAddress(selectedAddress);
  };

  const handleOnChangeEdgeID = (selectedEdgeID) => {
    setSelectedEdgeID(selectedEdgeID);
  };

  const topicFirst = 'ข้อมูลลูกค้า';
  const topicSec = 'ข้อมูลบ้าน';
  const topicMemberHouse = 'สมาชิกในบ้าน';
  const topicDevice = 'อุปกรณ์ภายในบ้าน';
  const topicFirstData = [
    {
      title: 'ชื่อลูกค้า',
      description: `${data?.title_name || ''} ${data?.first_name || ''} ${data?.last_name || ''}`,
    },
    {
      title: 'เบอร์โทรศัพท์',
      description: `${data?.telephone || '-'}`,
    },
    {
      title: 'Email',
      description: `${data?.email || '-'}`,
    },
    {
      title: 'SCG ID',
      description: `${data?.scg_id?.scg_id || '-'}`,
    },
    {
      title: 'ประเภทสมาชิกในบ้าน',
      description: data?.scg_id ? checkOwner() : '-',
    },
  ];

  const topicSecData = [
    {
      title: 'ที่อยู่',
      address: addresses,
    },
    {
      title: 'Edge ID',
      edgeId: edges,
    },
    {
      title: 'สถานะ Monitor',
      monitorStatus: checkMonitoringStatus(),
    },
    {
      title: 'Solution',
      solution: getSolution(),
    },
  ];

  useEffect(() => {
    if (!data?.scg_id) {
      return;
    }
    getAddress();
    return () => {};
  }, [data]);

  useEffect(() => {
    getEdgeList();
    return () => {};
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedEdgeID) {
      getSelectedEdgeInfo();
      checkMonitoringStatus();
    }

    return () => {};
  }, [selectedEdgeID]);

  return (
    <>
      {!data ? (
        <Loading />
      ) : data?.scg_id ? (
        <Spin spinning={spinning} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
          <Container>
            {!data ? (
              <Loading />
            ) : (
              // <Customer data={data} />
              <>
                <TextCardNcc title={topicFirst} className="mt-10" dataCard={topicFirstData} />
                <TextCardNcc
                  title={topicSec}
                  className="mt-6"
                  dataCard={topicSecData}
                  topicMemberHouse={topicMemberHouse}
                  color="$gray"
                  topicDevice={topicDevice}
                  memberHouse={selectedEdgeMembers}
                  deviceHome={selectedEdgeDevices}
                  onChangeAddress={handleOnChangeAddress}
                  onChangeEdgeID={handleOnChangeEdgeID}
                />
              </>
            )}
          </Container>
        </Spin>
      ) : (
        <div>
          <TextCardNcc title={topicFirst} className="mt-10" dataCard={topicFirstData} />
        </div>
      )}
    </>
  );
}

const Container = styled.div`
  width: 100%;
  height: auto;
  max-width: 1126px;
  margin: 0 auto;
  .mt-10 {
    margin-top: 2.5rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
`;
