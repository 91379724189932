import React, { useContext, createContext, useState } from 'react';
import { message } from 'antd';
import { defaultState } from '@store/core';
import PropTypes from 'prop-types';
import { getEdgeWifiCount, getEdgeWifiPagination } from '@store/edgeWifi/api';

const edgeWifiContext = createContext();

const useListEdgeWifi = () => {
  return useContext(edgeWifiContext);
};

const useProvideEdgeWifi = () => {
  const [data, setData] = useState();
  const [countEdgeWifi, setCountEdgeWifi] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = async (params, cb = () => {}) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await getEdgeWifiPagination(params);
    if (res.status === '200') {
      setData(res.data);
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  const getCountEdgeWifi = async (params, cb = () => {}) => {
    setCountEdgeWifi((prev) => ({
      ...prev,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }));
    const res = await getEdgeWifiCount(params);
    if (res.status === '200') {
      setCountEdgeWifi((prev) => ({
        ...prev,
        data: res.data,
        isLoading: false,
        isSuccess: true,
        isError: false,
      }));
    } else {
      message.error(res.message || 'Something went wrong!');
      setCountEdgeWifi((prev) => ({
        ...prev,
        isLoading: false,
        isSuccess: false,
        isError: true,
      }));
    }
  };

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    getData,
    countEdgeWifi,
    getCountEdgeWifi,
  };
};

const ContextListEdgeWifi = ({ children }) => {
  const roof = useProvideEdgeWifi();

  return <edgeWifiContext.Provider value={roof}>{children}</edgeWifiContext.Provider>;
};

export { useListEdgeWifi, ContextListEdgeWifi };

ContextListEdgeWifi.propTypes = {
  children: PropTypes.node,
};
