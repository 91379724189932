import { Pie } from '@ant-design/charts';
import { PAGE_REFRESHING_ATOM, TRINITY_DASHBOARD_ATOM } from '@root/atoms/dashboard.atom';
import { fetchSignalStrength } from '@root/services/dashboard.service';
import { useAuth } from '@root/store/auth/hook';
import { Spin } from 'antd';
import { useAtom } from 'jotai';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { configSignalWifiChart, configSignalZigbeeChart } from '../../chartConfig';
import Styled, { CardContainer, Card } from './style';

const SignalStrength = () => {
  const { user } = useAuth();

  const [refresh] = useAtom(PAGE_REFRESHING_ATOM);
  const [params] = useAtom(TRINITY_DASHBOARD_ATOM);

  const {
    data: zigbee,
    isFetching: isFetchingZigbee,
    isLoading: isGettingZigbee,
  } = useQuery(
    ['zigbee', params, refresh.flag],
    () => fetchSignalStrength('zigbee', { ...params, tenant_id: get(user.tenants, '[0]', null) }),
    {
      refetchInterval: refresh.every,
    },
  );

  const {
    data: wifi,
    isFetching: isFetchingWifi,
    isLoading: isGettingWifi,
  } = useQuery(
    ['wifi', params, refresh],
    () => fetchSignalStrength('wifi', { ...params, tenant_id: get(user.tenants, '[0]', null) }),
    {
      refetchInterval: refresh,
    },
  );

  const dataZigbee = useMemo(() => {
    const result = zigbee?.data;
    if (result)
      return {
        total: result.total,
        data: [
          { type: 'normal', value: +result.normal },
          { type: 'weak', value: +result.weak },
        ],
      };
  }, [zigbee]);

  const dataWifi = useMemo(() => {
    const result = wifi?.data;
    if (result)
      return {
        total: result.total,
        data: [
          { type: 'normal', value: +result.normal },
          { type: 'weak', value: +result.weak },
        ],
      };
  }, [wifi]);

  return (
    <Spin spinning={isGettingZigbee || isGettingWifi || isFetchingZigbee || isFetchingWifi}>
      <CardContainer>
        <h2>Signal Strength</h2>
        <div style={{ display: 'flex', justifyContent: 'space-around', height: '100%' }}>
          <Card>
            {dataZigbee && <Pie animation={false} {...configSignalZigbeeChart(dataZigbee.data)} />}
            <h3>Zigbee - Total: {dataZigbee?.total}</h3>
          </Card>
          <Card>
            {dataWifi && <Pie animation={false} {...configSignalWifiChart(dataWifi.data)} />}
            <h3>Wifi - Total: {dataWifi?.total}</h3>
          </Card>
        </div>
      </CardContainer>
    </Spin>
  );
};

export default SignalStrength;
