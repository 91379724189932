import React from 'react';
import TextCard from './style';
import TextCardHeaderNcc from '../textcardHeader';
import TextCardBodyNcc from '../textcardBody';
import TextcardTableNcc from '../textcardTable';
import TextCardTableDeviceNcc from '../textcardTableDevice';

const TextCardNcc = (props) => {
  return (
    <TextCard className={props?.className}>
      <TextCardHeaderNcc title={props?.title} />
      <TextCardBodyNcc
        dataCard={props?.dataCard}
        onChangeAddress={props?.onChangeAddress}
        onChangeEdgeID={props?.onChangeEdgeID}
        refetchCustomer={props?.refetchCustomer || (() => {})}
      />

      {props?.topicDevice ? (
        <div>
          <TextCardHeaderNcc title={props?.topicDevice} color={props?.color} />
          <TextCardTableDeviceNcc deviceHome={props?.deviceHome} />
        </div>
      ) : (
        ''
      )}

      {props?.topicMemberHouse ? (
        <div>
          <TextCardHeaderNcc title={props?.topicMemberHouse} color={props?.color} />
          <TextcardTableNcc memberHouse={props?.memberHouse} />
        </div>
      ) : (
        ''
      )}
    </TextCard>
  );
};

export default TextCardNcc;
