import { SearchOutlined } from '@ant-design/icons';
import { TRINITY_DASHBOARD_ATOM } from '@root/atoms/dashboard.atom';
import { SelectField } from '@root/components/common';
import { fetchRegions, fetchProvinces } from '@root/services/dashboard.service';
import { Button, Form, Select, TreeSelect } from 'antd';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { useQuery } from 'react-query';
const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

const solutionFilterData = [
  {
    title: 'Select All',
    value: '',
    children: [
      {
        title: 'Solar Roof',
        value: 'solar',
        key: 'solar',
      },
      {
        title: 'Deebox',
        value: 'deebox',
        key: 'deebox',
      },
      {
        title: 'Active Air Flow',
        value: 'aaf',
        key: 'aaf',
      },
      {
        title: 'Active Air Quality',
        value: 'aaq',
        key: 'aaq',
      },
      {
        title: 'Active Air Flow & Quality',
        value: 'aafq',
        key: 'aafq',
      },
      {
        title: 'Tuya',
        value: 'tuya',
        key: 'tuya',
      }
    ],
  },
];

const useProvidesByRegionId = (id) => useQuery(['provinces', id], () => fetchProvinces(id), { enabled: !!id });

const ControlForm = () => {
  const [form] = Form.useForm();

  const [region, setRegion] = useState();
  const [, setParams] = useAtom(TRINITY_DASHBOARD_ATOM);

  const { isLoading: isGettingRegions, data: regions } = useQuery('regions', fetchRegions);
  const { isLoading: isGettingProvinces, data: provinces } = useProvidesByRegionId(region && region.id);

  const prepareFilters = (values) => {
    const { deviceStatus, region } = values;
    const parsed = JSON.parse(region);
    const filters = { ...values, region: parsed.NameThai };
    if (deviceStatus?.[0] === 'device-all') {
      filters.deviceStatus = null;
    }
    return filters;
  };

  const handleSubmit = (values) => {
    const filters = prepareFilters(values);
    setParams(filters);
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div style={{ display: 'grid', gap: '20px 0' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <label style={{ fontWeight: 'bold', width: '30%' }}>Solutions</label>
          <Form.Item name="solutions" noStyle>
            <TreeSelect
              showArrow
              treeData={solutionFilterData}
              treeCheckable
              treeDefaultExpandAll
              showCheckedStrategy={SHOW_PARENT}
              placeholder="Select Solution"
              style={{ width: '70%' }}
            />
          </Form.Item>
        </div>
        <Form.Item name="region" noStyle>
          <SelectField
            onChange={(e) => setRegion(JSON.parse(e))}
            placeholder="Region"
            style={{ width: '100%' }}
            loading={isGettingRegions}
            label="Region"
          >
            <Option value={undefined}>Select All</Option>
            {(regions?.data || []).map((region) => (
              <Option key={region.id} value={JSON.stringify(region)}>
                {region.NameEnglish}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item name="province" noStyle>
          <SelectField placeholder="Province" label="Province" style={{ width: '100%' }} loading={isGettingProvinces}>
            <Option value={undefined}>Select All</Option>
            {(provinces?.data || []).map((province) => (
              <Option key={province.id} value={province.NameThai}>
                {province.NameEnglish}
              </Option>
            ))}
          </SelectField>
        </Form.Item>

        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
          Search
        </Button>
      </div>
    </Form>
  );
};

export default ControlForm;
