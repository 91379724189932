import styled from "styled-components";

const ModalAddress = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  .wrapModalBox {
    width: 560px;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 24px;
  }
  h2 {
    font-family: 'Sarabun', sans-serif;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    color: #333333;
    margin: 0;
    margin-bottom: 8px;
  }
  p {
    font-family: 'Sarabun', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #666666;
    margin: 0;
  }
  .wrapBtnModal {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;
  }
  .btnModal {
    width: auto;
    height: auto;
    padding: 8px 24px;
    font-family: 'Sarabun', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .btnCancle {
    border: 1px solid #40647B;
    color: #333333;
    background-color: #FFFFFF;
  }
  .btnApply {
    border: 1px solid #40647B;
    color: #FFFFFF;
    background-color: #40647B;
  }
  .wrapRadio {
    width: 100%;
    height: auto;
    padding-top: 24px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #40647B;
  }
  .ant-radio-inner::after {
    background-color: #40647B;
  }
  .ant-radio-checked::after {
    border-color: #40647B;
  }
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner {
    border-color: #40647B !important;
  }
  .ant-space-vertical {
    gap: 20px !important;
  }
`;

export default ModalAddress;