import styled from 'styled-components';

const TextCardTableDeviceBody = styled.div`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  .wrapDeviceBox {
    width: 100%;
    height: auto;
  }
  .wrapDeviceBoxIn {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #d8d8d8;
  }
  .wrapDeviceBoxInBox {
    padding: 16px 20px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.2fr;
    gap: 16px;
    p {
      word-break: break-all;
    }
  }
  .wrapDeviceBoxInBoxB {
    padding: 16px 20px;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 0.9fr 1.25fr 0.5fr 1.1fr 1fr;
    gap: 16px;
  }
  .wrapDeviceBoxIn:last-child {
    border-bottom: none;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #333333;
    margin: 0;
    line-break: auto;
  }
  .pDate {
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    margin: 0;
  }
  .wrapStatus {
    width: 100%;
    height: auto;
    display: flex;
    gap: 7px;
    align-items: center;
    margin-bottom: 8px;
  }
  .statusActive {
    width: 10px;
    height: 10px;
    background-color: #409440;
    border-radius: 100%;
  }
  .statusOffline {
    width: 10px;
    height: 10px;
    background-color: #d93832;
    border-radius: 100%;
  }
  .wrapModel {
    width: 100%;
    height: auto;
    display: flex;
    gap: 8px;
  }
`;

export default TextCardTableDeviceBody;
