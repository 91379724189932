import React, { useEffect } from 'react';
import Table from '@components/atoms/table';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import { Typography } from 'antd';
const { Text } = Typography;

const EdgeComponentTable = () => {
  const { dataEdge, edgeComponents, getEdgeComponents } = useEdgeApplication();

  const [page, setPage] = React.useState(1);
  const items = edgeComponents.data || [];
  const edgeBarcode = dataEdge?.edge_barcode;

  useEffect(() => {
    if (!edgeBarcode) return;
    if (!edgeComponents.data) getEdgeComponents(edgeBarcode);
  }, [edgeBarcode]);

  const displayCell = (value, item) => {
    return item.lifecycle_state === 'BROKEN' ? <Text type="danger">{value}</Text> : value;
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'id',
      key: 'id',
      render: (value, item, index) => displayCell((page - 1) * 10 + index + 1 + '.', item),
    },
    {
      title: 'Name',
      dataIndex: 'component_name',
      key: 'component_name',
      render: (value, item) => displayCell(value, item),
    },
    {
      title: 'Version',
      dataIndex: 'component_version',
      key: 'component_version',
      render: (value, item) => displayCell(value, item),
    },
    {
      title: 'State',
      dataIndex: 'lifecycle_state',
      key: 'lifecycle_state',
      render: (value, item) => displayCell(value, item),
    },
  ];

  return (
    <Table
      loading={edgeComponents.isLoading}
      columns={columns}
      dataSource={items}
      rowKey="id"
      pagination={{ total: items.length, pageSize: 10, onChange: setPage }}
    />
  );
};

export default EdgeComponentTable;
