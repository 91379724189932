import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 10px;
  border-radius: 10px;

  border: 1px solid #e9e9e9;
  position: relative;

  color: #FFFFFF;
  background-color:${(props) => props.backgroundColor};
`;