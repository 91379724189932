import Title from 'antd/lib/typography/Title';
import { Button, Form, Input, Modal, Divider, Select } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import NotFound from 'assets/NotFound.png';
import CheckCircle from 'assets/check-circle.png';
import XCircle from 'assets/x-circle.png';
import InfoItems from '@components/atoms/info/info';
import { bindEdgeIdWithScgId, getCustomerDetail, getEdgeDetail, searchEdgeIdNScgId } from '@root/services/scg-id';

import Style, { ModalStyle } from './BindSCGIDStyle';
const { Option } = Select;

export const BindSCGID = () => {
  const [form] = Form.useForm();
  const [formRole] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [search, setSearch] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingBind, setLoadingBind] = useState(false);
  const [dataBind, setDataBind] = useState(null);
  const [dataEdge, setDataEdge] = useState(null);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataStatus, setDataStatus] = useState(null);
  const [isModalBind, setIsModalBind] = useState(false);
  const [isModalStatusBind, setIsModalStatusBind] = useState(false);

  const onValuesChange = (_, allValue) => {
    if (allValue.edge_id && allValue.scg_id) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmitSearch = async (values) => {
    setLoadingSearch(true);
    setSearch(false);
    try {
      const queryBind = {
        phone_no: values.scg_id,
        edge_id: values.edge_id,
      };
      const resBind = await searchEdgeIdNScgId(queryBind);
      setDataBind(resBind);
      const queryC = {
        phone_no: values.scg_id,
      };
      const [resCustomer, resEdge] = await Promise.all([getCustomerDetail(queryC), getEdgeDetail(values.edge_id)]);
      setDataCustomer(resCustomer);
      setDataEdge(resEdge);
      if (resEdge && !resEdge?.owned_customer?.id) {
        formRole.setFieldsValue({ role: 'owner' });
      } else {
        formRole.setFieldsValue({ role: 'member' });
      }
    } catch (error) {}
    setSearch(true);
    setLoadingSearch(false);
  };

  const messageError = () => {
    if (!dataEdge && dataCustomer.length == 0) {
      return 'Edge info and Customer info not found';
    }
    if (!dataEdge) {
      return 'Edge info not found';
    }
    if (dataCustomer.length == 0) {
      return 'Customer info not found';
    }
    return '';
  };

  const onCancelBind = () => {
    form.resetFields();
    formRole.resetFields();
    setDisabled(true);
    setSearch(false);
  };

  const onBind = async () => {
    setLoadingBind(true);
    try {
      const body = {
        customer_record_id: dataCustomer[0].id,
        edge_id: dataEdge.edge_barcode,
        role: formRole.getFieldValue('role'),
      };
      const res = await bindEdgeIdWithScgId(body);
      setDataStatus(res);
    } catch (error) {}
    setLoadingBind(false);
    setIsModalStatusBind(true);
    setIsModalBind(false);
  };

  const messageBindError = useMemo(() => {
    if (dataStatus?.success === false && dataStatus?.message) {
      return dataStatus?.message;
    }
    return 'we have some problems with the binding process account. Please retry ';
  }, [dataStatus]);

  const showModalBind = () => {
    setIsModalBind(true);
  };

  const cancelModalBind = () => {
    setIsModalBind(false);
  };
  const closeModalStatus = () => {
    setIsModalStatusBind(false);
    onCancelBind();
  };

  const onSubmitFormRole = () => {
    showModalBind();
  };

  return (
    <Style>
      <div className="form-bind">
        <Title level={5}>Input Edge ID and SCG ID</Title>
        <Form form={form} onValuesChange={onValuesChange} onFinish={onSubmitSearch} layout="vertical">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
            <Form.Item label={<label style={{ fontWeight: 600 }}>Edge ID</label>} name="edge_id">
              <Input style={{ width: 330 }} />
            </Form.Item>
            <Form.Item label={<label style={{ fontWeight: 600 }}>SCG ID&#39;s Phone no.</label>} name="scg_id">
              <Input style={{ width: 330 }} />
            </Form.Item>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              style={{ fontWeight: 600 }}
              disabled={disabled}
              type="primary"
              htmlType="submit"
              loading={loadingSearch}
            >
              Search
            </Button>
          </div>
        </Form>
      </div>
      <Modal visible={isModalBind} footer={null} centered closable={false} maskClosable={false} width={400}>
        <ModalStyle>
          <div className="title-block">
            <div className="title">Are you sure to bind account Edge ID and SCG ID?</div>
          </div>
          <Button
            style={{ fontWeight: 600, marginBottom: '8px' }}
            type="primary"
            block
            size="large"
            onClick={onBind}
            loading={loadingBind}
          >
            Confirm
          </Button>
          <Button style={{ fontWeight: 600 }} block size="large" onClick={cancelModalBind} disabled={loadingBind}>
            Cancel
          </Button>
        </ModalStyle>
      </Modal>
      <Modal visible={isModalStatusBind} footer={null} centered closable={false} maskClosable={false} width={400}>
        <ModalStyle>
          {dataStatus?.success ? (
            <>
              <div className="title-block">
                <img src={CheckCircle} width={64} />
                <div className="title">Success</div>
              </div>
              <Button
                style={{ fontWeight: 600, marginBottom: '8px' }}
                type="primary"
                block
                size="large"
                onClick={closeModalStatus}
              >
                OK
              </Button>
            </>
          ) : (
            <>
              <div className="title-block">
                <img src={XCircle} width={64} />
                <div className="title">Failed</div>
                <div className="des">{messageBindError}</div>
              </div>
              <Button
                style={{ fontWeight: 600, marginBottom: '8px' }}
                type="primary"
                block
                size="large"
                onClick={onBind}
                loading={loadingBind}
              >
                Bind again with same account
              </Button>
              <Button style={{ fontWeight: 600 }} block size="large" onClick={closeModalStatus}>
                Search other accounts
              </Button>
            </>
          )}
        </ModalStyle>
      </Modal>
      {search ? (
        <>
          <div className="divide-card"></div>
          <div className="layout-card-data">
            {!dataBind?.status ? (
              <>
                <img src={NotFound} />
                <div className="text-not-found">
                  <div>{messageError()}</div>
                  <div>Please retry again</div>
                </div>
              </>
            ) : (
              <Form form={formRole} onFinish={onSubmitFormRole} layout="vertical">
                <div className="layout-info">
                  <div style={{ maxWidth: '780px' }}>
                    <Title level={5} style={{ fontSize: '18px', marginBottom: 0 }}>
                      Customer info
                    </Title>
                    <div className="customer-info-detail">
                      <InfoItems title="Name" value={dataCustomer[0]?.first_name || '-'} />
                      <InfoItems title="Last Name" value={dataCustomer[0]?.last_name || '-'} />
                    </div>
                    <div className="customer-info-detail">
                      <InfoItems title="Email" value={dataCustomer[0]?.email || '-'} />
                      {dataBind?.data?.binding === true && (
                        <InfoItems
                          title="Status"
                          value={
                            dataBind?.data.binding ? (
                              <div className="chip-status success">Bound</div>
                            ) : (
                              <div className="chip-status error">Not Bind</div>
                            )
                          }
                        />
                      )}
                    </div>
                    <div className="customer-info-detail">
                      {dataBind?.data?.binding === false && (
                        <>
                          <div className="info-item ">
                            <Form.Item
                              name="role"
                              label={<div style={{ fontWeight: 600 }}>Role ในบ้าน</div>}
                              rules={[
                                { required: true, message: 'โปรดระบุข้อมูล' },
                                {
                                  validator: async (_, value) => {
                                    if (dataEdge?.owned_customer?.id && value === 'owner') {
                                      throw new Error('ข้อมูลซ้ำกับข้อมูลที่มีอยู่ในระบบ');
                                    }
                                  },
                                },
                              ]}
                            >
                              <Select placeholder="โปรดระบุ">
                                <Option value="owner">เจ้าของบ้าน</Option>
                                <Option value="member">ผู้อยู่อาศัย</Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <InfoItems
                            title="Status"
                            value={
                              dataBind?.data.binding ? (
                                <div className="chip-status success">Bound</div>
                              ) : (
                                <div className="chip-status error">Not Bind</div>
                              )
                            }
                          />
                        </>
                      )}
                    </div>
                    <Divider style={{ margin: '40px 0' }} />
                    <Title level={5} style={{ fontSize: '18px', marginBottom: 0 }}>
                      Address info
                    </Title>
                    <div className="customer-info-detail">
                      <InfoItems title="House no. & Soi" value={dataEdge?.full_address?.street_address || '-'} />
                      <InfoItems title="Road" value={dataEdge?.full_address?.road || '-'} />
                    </div>
                    <div className="customer-info-detail">
                      <InfoItems title="Sub-district" value={dataEdge?.full_address?.sub_district || '-'} />
                      <InfoItems title="District" value={dataEdge?.full_address?.district || '-'} />
                    </div>
                    <div className="customer-info-detail">
                      <InfoItems title="Province" value={dataEdge?.full_address?.province || '-'} />
                      <InfoItems title="Postal code" value={dataEdge?.full_address?.postal_code || '-'} />
                    </div>
                    <div className="customer-info-detail">
                      <InfoItems title="Developer" value={dataEdge?.developer?.Developer_name || '-'} />
                      <InfoItems title="Project" value={dataEdge?.project?.name || '-'} />
                    </div>
                    <div className="customer-info-detail">
                      <InfoItems title="Notes" value={dataEdge?.note || '-'} />
                    </div>
                    {dataBind?.data.binding ? (
                      <></>
                    ) : (
                      <div className="layout-button">
                        <Button
                          style={{ fontWeight: 600, color: '#40a9ff', borderColor: '#40a9ff' }}
                          htmlType="reset"
                          onClick={onCancelBind}
                        >
                          Cancel
                        </Button>
                        <Button style={{ fontWeight: 600 }} type="primary" htmlType="submit">
                          Bind
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </Style>
  );
};
