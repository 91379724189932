import React from 'react';
import PropTypes from 'prop-types';
import Style from './style';

const StatCard = ({ title, value, bgColor, color, width, link = '', onSearch }) => {
  const style = { background: bgColor, color, width };
  if (link) {
    style.cursor = 'pointer';
  }
  const handleClick = () => {
    if (!link) return;
    const onlineStatus = link === 'all' ? '' : link;
    onSearch({ onlineStatus });
  };

  return (
    <a href={link ? `#status=${link}` : '#'} onClick={() => handleClick()}>
      <Style style={style}>
        <div className="title">{title}</div>
        <div className="value">{value}</div>
      </Style>
    </a>
  );
};

StatCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bgColor: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string,
  onSearch: PropTypes.func,
};

export default StatCard;
