import styled from "styled-components";

const TextcardTable = styled.div`
  width: 100%;
  height: auto;
  padding: 24px;
  .wrapTitleBar {
    width: 100%;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #D8D8D8;
  }
  .titleBar {
    width: 100%;
    height: 52px;
    background-color: #F0F0F0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: #333333;
    padding: 18px 16px;
  }
  h3 {
    font-size: 12px;
    margin: 0;
  }
  .wrapContentMemeber {
    width: 100%;
    height: auto;
  }
  .contentMemeber {
    width: 100%;
    height: 64px;
    background-color: #FFFFFF;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: #333333;
    padding: 20px 16px;
    border-bottom: 1px solid #D8D8D8;
  }
  .contentMemeber:last-child {
    border: none;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
`;

export default TextcardTable;