import React from 'react';
import { bool, arrayOf, object } from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Typography, Tag } from 'antd';

const CustomerTable = ({ items, loading }) => {
  const columns = [
    {
      title: 'Plan Date',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Re-Plan Date',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Reason',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Actual Date',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Process Status',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Remark',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Updated By',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Updated At',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Attachment',
      dataIndex: 'isActive',
      key: 'isActive',
    },
  ];

  return (
    <>
      <Typography.Title level={4}>History</Typography.Title>
      <Table
        columns={columns}
        dataSource={items}
        rowKey="id"
        loading={{
          spinning: loading,
          tip: 'Loading...',
        }}
      />
    </>
  );
};

CustomerTable.propTypes = {
  items: arrayOf(object),
  loading: bool,
};

CustomerTable.defaultProps = {
  items: [],
  loading: false,
};

export default CustomerTable;
