import config from '@config';
import { HttpAuth } from '@services/http';

const path = `${config.API_ENDPOINT}/user-groups`;

const getListUserGroups = async () => {
  return HttpAuth.get(path);
};

const newUserGroup = async (form) => {
  return HttpAuth.post(path, form);
};

const getOneUserGroup = async (id) => {
  return HttpAuth.get(`${path}/${id}`);
};

const editUserGroup = async (id, form) => {
  return HttpAuth.patch(`${path}/${id}`, form);
};

const getTenants = async (params = {}) => {
  return HttpAuth.get(`${config.API_ENDPOINT}/tenants`, {
    params: { ...params },
  });
};

export default {
  getListUserGroups,
  getOneUserGroup,
  newUserGroup,
  editUserGroup,
  getTenants,
};
