import { useCallback, useEffect, useMemo, useState } from 'react';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { TitleStyled } from './styled';

const SortIcons = ({ statusSort }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <CaretUpOutlined style={{ fontSize: '12px', color: statusSort === 1 ? '#d93832' : '#bfbfbf' }} />
      <CaretDownOutlined style={{ fontSize: '12px', color: statusSort === 2 ? '#d93832' : '#bfbfbf' }} />
    </div>
  );
};

export const TitleSort = ({ isResetSort, title, onChange }) => {
  const [statusSort, setStatusSort] = useState(0);
  const check = useMemo(() => <SortIcons statusSort={statusSort} />, [statusSort]);

  const handleClick = useCallback(() => {
    if (statusSort >= 2) {
      onChange(undefined);
    } else {
      onChange(statusSort === 1 ? 'desc' : 'asc');
    }
    setTimeout(() => {
      setStatusSort((prevState) => (prevState >= 2 ? 0 : prevState + 1));
    }, 300);
  }, [onChange, statusSort]);

  useEffect(() => {
    setStatusSort(0);
  }, [JSON.stringify(isResetSort)]);

  return (
    <TitleStyled onClick={handleClick}>
      {title}
      <div>{check}</div>
    </TitleStyled>
  );
};

TitleSort.propTypes = {
  isResetSort: PropTypes.bool,
  title: PropTypes.string,
  onChange: PropTypes.func,
};

SortIcons.propTypes = {
  statusSort: PropTypes.number,
};
