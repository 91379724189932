import styled from 'styled-components';

const Style = (ComponentName) => styled(ComponentName)`
  .ant-table-thead {
    margin-bottom: 10px;
    box-shadow: 0px 4px 4px rgba(220, 220, 220, 0.25);

    tr th {
      font-weight: bold;
      background: #E6ECF9;
      padding: 15px 10px;

      &:before {
        display: none;
      }

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    }
  }

  .ant-table-container table > thead > tr:first-child {
    th:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    th:last-child {
      border-radius: 0px 5px 5px 0px;
    }
  }

  .ant-table-tbody {
    /* display: block; */
    position: relative;

    &:before {
      box-shadow: 0px 0px 6px rgba(0,0,0,0.1);
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    tr > td {
      padding: 20px 10px;
      /* box-shadow: 0px 4px 4px rgba(220, 220, 220, 0.25); */
      a {
        text-decoration: underline;
      }

      &:first-child:not(:last-child) {
        padding-left: 20px;
      }

      &:last-child:not(:first-child) {
        padding-right: 20px;
      }
    }

    .ant-table-row:hover {
      td {
        background: unset;
      }
    }
  }
`;

export default Style;
