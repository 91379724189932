import { DateField, TextField } from '@root/components/common';
import { deleteEdgeData } from '@root/services/edge-applications.service';
import { Button, Form, Modal, notification } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { useState } from 'react';
import { useMutation } from 'react-query';

export const DeleteEdgeData = () => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const { isLoading, mutate } = useMutation({
    mutationFn: deleteEdgeData,
    onSuccess: () => {
      notification.success({ message: 'Data delete success' });
      setVisible(false);
    },
    onError: () => {
      notification.error({ message: 'Something went wrong. Please try again' });
    },
  });

  const handleSubmit = async () => {
    const res = await form.validateFields();
    const { aggregated_key, from_date, to_date } = res;
    const requestFormat = {
      aggregated_key: `${aggregated_key}-${aggregated_key}`,
      ...(from_date && { from_date: moment(from_date).format('YYYY-MM-DD') }),
      ...(to_date && { to_date: moment(to_date).format('YYYY-MM-DD') }),
    };
    mutate({ delete_list: [requestFormat] });
  };

  return (
    <Form form={form}>
      <Modal visible={visible} onCancel={() => setVisible(false)} footer={null} title="Confirmation">
        <Title style={{ textAlign: 'center' }} level={4}>
          Are you sure you want to delete this Edge Date
        </Title>
        <div style={{ display: 'flex', justifyContent: 'end', padding: '20px 0', gap: 10 }}>
          <Button onClick={() => setVisible(false)} type="default">
            Cancel
          </Button>
          <Button loading={isLoading} onClick={handleSubmit} htmlType="submit" danger type="primary">
            Delete
          </Button>
        </div>
      </Modal>
      <Title level={2}>Delete Edge Data</Title>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'end', marginTop: 80 }}>
          <div
            style={{
              width: 600,
              display: 'grid',
              gap: '0 40px',
              gridTemplateColumns: 'auto auto auto',
            }}
          >
            <div>
              <Form.Item required rules={[{ required: true }]} name="aggregated_key">
                <TextField label="From Edge" />
              </Form.Item>
              <Form.Item name="from_date">
                <DateField label="Begin Date" />
              </Form.Item>
            </div>
            <Form.Item style={{ alignItems: 'end' }} name="to_date">
              <DateField label="End Date" />
            </Form.Item>
          </div>
          <Button
            danger
            onClick={() => setVisible(true)}
            type="primary"
            style={{ margin: '0 40px 24px 64px', width: 140 }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Form>
  );
};
