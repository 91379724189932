import { number, oneOfType, string, bool } from 'prop-types';
import React  from 'react';
import { Row, Col } from 'antd';
import { AirInformationTitleEnum } from '@services/edgeApplication';
import { LoadingOutlined } from '@ant-design/icons';
import Style from './style';

const ComfortZoneCard = ({ title, value, unit, level, color, isLoading = false }) => {
  return (
    <Row>
      <Col span={16}><b>{AirInformationTitleEnum[title]}</b></Col>
      <Col span={8} style={{ textAlign: 'right', paddingRight: '10px' }}>
        <b style={{ color: '#3CB095' }}>{value}</b> <b style={{ color: '#808080' }}>{unit}</b>
      </Col>
      <Col span={24}>
        <Style backgroundColor={color}>
          {isLoading ? (
            <LoadingOutlined style={{ fontSize: 22 }} spin />
          ) : (
            <b>{level || 'No Data'}</b>
          )}
        </Style>
      </Col>
    </Row>
  );
};

ComfortZoneCard.propTypes = {
  title: string,
  value: oneOfType([string, number]),
  unit: oneOfType([string, number]),
  level: oneOfType([string, number]),
  color: oneOfType([string, number]),
  isLoading: bool,
};

export default ComfortZoneCard;
