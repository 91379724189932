import styled from "styled-components";

const TextCardTableDevice = styled.div`
  width: 100%;
  height: auto;
  padding: 24px;
  .wrapTextCardTableDevice {
    width: 100%;
    height: auto;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #D8D8D8;
  }
  .titleTableDevice {
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #F0F0F0;
  }
  .titleTableDeviceL {
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.2fr;
    padding: 0 16px;
    align-items: center;
    gap: 16px;
  }
  .titleTableDeviceR {
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
  }
  .titleTableDeviceRL {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: column;
  }
  .titleTableDeviceInRL {
    width: 100%;
    height: 32px;
    background-color: #BBBBBB;
    padding: 7px 16px;
    border-left: 1px solid #D8D8D8;
  }
  .titleTableDeviceInRLDown {
    width: 100%;
    height: 32px;
    display: grid;
    grid-template-columns: 1fr 1.4fr 0.5fr;
    padding: 7px 16px;
    border-left: 1px solid #D8D8D8;
    gap: 38px;
  }
  .titleTableDeviceInRLDownB {
    width: 100%;
    height: 32px;
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    padding: 7px 16px;
    padding-left: 12px;
    border-left: 1px solid #D8D8D8;
    gap: 8px;
  }
  h4 {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #333333;
    margin: 0;
  }
  .h4Center {
    text-align: center;
  }
`;

export default TextCardTableDevice;