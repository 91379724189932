import { Button, DatePicker, Input, Modal, Select, TreeSelect, AutoComplete, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const { SHOW_PARENT } = TreeSelect;
/* eslint-disable react/prop-types */
const SelectField = ({ label, children, ...rest }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <label style={{ fontWeight: 'bold', width: '30%' }}>{label}</label>
      <Select {...rest} style={{ width: '70%' }}>
        {children}
      </Select>
    </div>
  );
};

const TextField = ({ label, children, ...rest }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <label style={{ fontWeight: 'bold', width: '30%', textAlign: 'left' }}>{label}</label>
      <Input {...rest} style={{ width: '70%' }} />
    </div>
  );
};

const DateField = ({ label, children, ...rest }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <label style={{ fontWeight: 'bold', width: '30%' }}>{label}</label>
      <DatePicker {...rest} style={{ width: '70%' }} />
    </div>
  );
};

const DateRangeField = ({ label, children, ...rest }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <label style={{ fontWeight: 'bold', width: '30%' }}>{label}</label>
      <RangePicker {...rest} style={{ width: '70%' }} />
    </div>
  );
};

const TreeSelectField = ({ label, treeData, placeholder }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <label style={{ fontWeight: 'bold', width: '30%' }}>{label}</label>
      <TreeSelect
        showArrow
        treeData={treeData}
        treeCheckable
        treeDefaultExpandAll
        showCheckedStrategy={SHOW_PARENT}
        placeholder="Select Solution"
        style={{ width: '70%' }}
      />
    </div>
  );
};

const AutoCompleteField = ({ label, children, loading = false, ...rest }) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <label style={{ fontWeight: 'bold', width: '30%' }}>{label}</label>
      <AutoComplete {...rest} style={{ width: '70%' }}></AutoComplete>
      {loading && <Spin indicator={<LoadingOutlined style={{ marginLeft: '10px' }} />} />}
    </div>
  );
};

const ConfirmModal = ({ visible, onClose, onOk }) => {
  return (
    <Modal style={{ top: '30%' }} visible={visible} onCancel={onClose} footer={null}>
      <div style={{ textAlign: 'center' }}>
        <h1>Are you sure?</h1>
        <p style={{ fontSize: 22 }}>Are you sure to delete this record? </p>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '0 20px' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onOk} htmlType="submit" type="primary">
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { SelectField, TreeSelectField, ConfirmModal, TextField, DateField, DateRangeField, AutoCompleteField };
