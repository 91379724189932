import * as React from "react"
const LogoMind = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={110}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M96.209 24.654c-3.767 0-6.409-2.448-6.409-6.108 0-3.661 2.618-6.11 6.409-6.11 3.79 0 6.408 2.449 6.408 6.11 0 3.66-2.617 6.108-6.409 6.108Zm0 3.346c5.674 0 10.076-4 10.076-9.479V4h-3.717v7.37c-1.566-1.406-3.84-2.28-6.482-2.28-5.576 0-9.954 4-9.954 9.456C86.132 24 90.534 28 96.208 28Zm-36.42-.364h3.718v-8.654c0-4 2.52-6.497 6.506-6.497 3.987 0 6.506 2.497 6.506 6.497v8.654h3.718v-8.945c0-5.697-4.158-9.6-10.224-9.6-6.065 0-10.223 3.879-10.223 9.6v8.945ZM50.497 28c2.079 0 3.717-1.503 3.717-3.467 0-1.26-.66-2.157-1.443-3.103-.734-.897-1.345-1.697-1.345-2.86 0-1.164.587-1.964 1.345-2.86.783-.946 1.443-1.843 1.443-3.104 0-1.964-1.638-3.467-3.717-3.467-2.08 0-3.718 1.503-3.718 3.467 0 1.26.66 2.158 1.443 3.103.734.897 1.345 1.697 1.345 2.86 0 1.164-.587 1.964-1.345 2.861-.783.946-1.443 1.843-1.443 3.103 0 1.964 1.639 3.467 3.718 3.467ZM4 27.636h3.718v-8.654c0-4 2.519-6.497 6.506-6.497 3.986 0 6.506 2.497 6.506 6.497v8.654h3.717v-8.654c0-4 2.52-6.497 6.506-6.497 3.987 0 6.506 2.497 6.506 6.497v8.654h3.718v-8.945c0-5.697-4.134-9.6-10.2-9.6-3.741 0-6.725 1.479-8.413 3.976-1.663-2.497-4.672-3.976-8.414-3.976C8.133 9.09 4 12.97 4 18.69v8.945Z"
    />
  </svg>
)
export default LogoMind
