import config from '@config';
import { Http, HttpAuth } from '@services/http';

const login = async (form) => {
  return Http.post(`${config.API_ENDPOINT}/auth/login`, form);
};

const loginCognito = async (form) => {
  return Http.post(`${config.API_ENDPOINT}/auth/cognito`, form);
};

const logout = async () => {
  return HttpAuth.post(`${config.API_ENDPOINT}/auth/logout`);
};

const getProfile = async () => {
  return HttpAuth.get(`${config.API_ENDPOINT}/me`);
};

const updateFirstPassword = async (form) => {
  return HttpAuth.patch(`${config.API_ENDPOINT}/me/first-password`, form);
};

export default {
  login,
  logout,
  getProfile,
  updateFirstPassword,
  loginCognito,
};
