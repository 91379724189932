import { DateField, AutoCompleteField } from '@root/components/common';
import { transferEdgeData, fetchRawEdgeApplicationsPagination } from '@root/services/edge-applications.service';
import { Button, Form, Modal, notification, Upload, message } from 'antd';
import { validateDifferenceValue } from '@root/utils/validator';
import Title from 'antd/lib/typography/Title';
import { useState, useReducer } from 'react';
import { useMutation } from 'react-query';
import moment from 'moment';
import { debounce } from 'lodash';

const initialAggregateValue = {
  isLoading: false,
  selectedValue: null,
  options: [],
};

const initialAggregateState = {
  from_aggregate_key: initialAggregateValue,
  to_aggregate_key: initialAggregateValue,
};

const aggregateReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED':
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          selectedValue: action.selectedValue,
          options: [],
        },
      };
    case 'SET_OPTIONS':
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          options: action.options,
        },
      };
    case 'SET_LOADING':
      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          isLoading: action.loading,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export const TransferEdgePage = () => {
  const [aggregateState, aggregateDispatch] = useReducer(aggregateReducer, initialAggregateState);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: transferEdgeData,
    onSuccess: () => {
      notification.success({ message: 'Data transfer success' });
    },
    onError: () => {
      notification.error({ message: 'Something went wrong. Please try again' });
    },
  });

  const handleSubmit = async () => {
    const res = await form.validateFields();
    const { from_aggregate_key, to_aggregate_key, from_date, to_date } = res;
    const requestFormat = {
      from_aggregated_key: `${from_aggregate_key}-${from_aggregate_key}`,
      to_aggregated_key: `${to_aggregate_key}-${to_aggregate_key}`,
      ...(from_date && { from_date: moment(from_date).format('YYYY-MM-DD') }),
      ...(to_date && { to_date: moment(to_date).format('YYYY-MM-DD') }),
    };
    mutate({ transfer_list: [requestFormat] });
  };

  const formSetField = (name, value) => {
    let setOject = {};
    setOject[name] = value;
    form.setFieldsValue(setOject);
  };

  const handleSelect = (name, value) => {
    aggregateDispatch({ type: 'SET_SELECTED', name, selectedValue: value });
    formSetField(name, value);
  };

  const handleBlur = (name) => {
    form.validateFields();
    const enteredValue = form.getFieldValue(name);
    if (!enteredValue) {
      aggregateDispatch({ type: 'SET_SELECTED', name, selectedValue: null });
      formSetField(name, null);
    } else {
      const { options } = aggregateState[name];
      if (!options.includes(enteredValue)) {
        formSetField(name, aggregateState[name].selectedValue);
      }
    }
    aggregateDispatch({ type: 'SET_OPTIONS', name, options: [] });
  };

  const fetchAggregateOption = debounce(async (name, searchText) => {
    await fetchRawEdgeApplicationsPagination({
      page: 1,
      pageSize: 100,
      edgeId: searchText,
    })
      .then((result) => {
        aggregateDispatch({
          type: 'SET_OPTIONS',
          name,
          options: result.map((record) => {
            return {
              label: record.edge_barcode,
              value: record.edge_barcode,
            };
          }),
        });
      })
      .catch((_) => {
        message.error('Something went wrong');
      })
      .finally(() => {
        aggregateDispatch({ type: 'SET_LOADING', name, loading: false });
      });
  }, 500);

  const handleSearch = (name, searchText) => {
    if (searchText.length >= 4) {
      aggregateDispatch({ type: 'SET_LOADING', name, loading: true });
      fetchAggregateOption(name, searchText);
    } else {
      aggregateDispatch({ type: 'SET_LOADING', name, loading: false });
      aggregateDispatch({ type: 'SET_OPTIONS', name, options: [] });
    }
  };

  return (
    <Form form={form}>
      <Modal visible={visible} onCancel={() => setVisible(false)} footer={null} title="Confirmation">
        <Title style={{ textAlign: 'center' }} level={4}>
          Are you sure you want to copy this Edge Date
        </Title>
        <div style={{ display: 'flex', justifyContent: 'end', padding: '20px 0', gap: 10 }}>
          <Button onClick={() => setVisible(false)} type="default">
            Cancel
          </Button>
          <Button loading={isLoading} onClick={handleSubmit} htmlType="submit" type="primary">
            Transfer
          </Button>
        </div>
      </Modal>
      <Title level={2}>Copy Edge Data</Title>
      <Upload />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'end', marginTop: 80 }}>
          <div
            style={{
              width: 700,
              display: 'grid',
              gap: '0 40px',
              gridTemplateColumns: 'auto auto',
            }}
          >
            <Form.Item
              required
              rules={[
                { required: true, message: 'Please select edge barcode' },
                { validator: (_, value) => validateDifferenceValue(form, value, 'to_aggregate_key') },
              ]}
              name="from_aggregate_key"
            >
              <AutoCompleteField
                label="From Edge"
                options={aggregateState.from_aggregate_key.options}
                onSearch={(value) => handleSearch('from_aggregate_key', value)}
                onSelect={(value) => handleSelect('from_aggregate_key', value)}
                onBlur={() => handleBlur('from_aggregate_key')}
                placeholder="ค้นหา Edge Barcode"
                loading={aggregateState.from_aggregate_key.isLoading}
              />
            </Form.Item>

            <Form.Item
              required
              rules={[
                { required: true, message: 'Please select edge barcode' },
                { validator: (_, value) => validateDifferenceValue(form, value, 'from_aggregate_key') },
              ]}
              name="to_aggregate_key"
            >
              <AutoCompleteField
                label="To Edge"
                options={aggregateState.to_aggregate_key.options}
                onSearch={(value) => handleSearch('to_aggregate_key', value)}
                onSelect={(value) => handleSelect('to_aggregate_key', value)}
                onBlur={() => handleBlur('to_aggregate_key')}
                placeholder="ค้นหา Edge Barcode"
              />
            </Form.Item>

            <Form.Item name="from_date">
              <DateField label="Begin Date" />
            </Form.Item>

            <Form.Item name="to_date">
              <DateField label="End Date" />
            </Form.Item>
          </div>
          <Button
            onClick={() => {
              form
                .validateFields()
                .then(() => {
                  setVisible(true);
                })
                .catch((_) => {
                  message.error('Form Validation Failed');
                });
            }}
            type="primary"
            style={{ margin: '0 40px 24px 64px', width: 140 }}
          >
            Transfer
          </Button>
        </div>
      </div>
    </Form>
  );
};
