import { number, oneOfType, string } from 'prop-types';
import React from 'react';

import Style from './style';

const TemperatureCard = ({ title, value }) => {
  const color = '#5888ED';
  return (
    <Style color={color}>
      {title}
      <span style={{ margin: 5 }}>-</span>
      <b>{value}</b>
    </Style>
  );
};

TemperatureCard.propTypes = {
  title: string,
  value: oneOfType([string, number]),
};

export default TemperatureCard;
