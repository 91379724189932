import styled from 'styled-components';

export const TitleStyled = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 7px;
    margin-bottom: -2px;
  }
`;
