import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, DatePicker, Row, Col, Space } from 'antd';
import { useParams } from 'react-router-dom';
import CustomerMAPlanTable from '@components/organisms/tables/customerMAPlanTable';
import { useNewMaPlan } from '@store/edgeApplication/hooks/useNewMaPlan';
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';
const EditMAPlanModal = (props) => {
  const { edge_barcode } = useParams();
  const { isLoading, isSuccess, setData } = useNewMaPlan();
  const { onSubmit, onCancel, btnName, dataEdge, getOneEdgeApplication } = props;

  const [form] = Form.useForm();
  const [isVisible, setIsVisible] = useState(false);
  const [nextMAPlan, setNextMAPlan] = useState(moment(dataEdge.ma_plan_date));
  const [disableMAPlan, setDisableMAPlan] = useState(true);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    setNextMAPlan(moment(dataEdge.ma_plan_date));
  }, [dataEdge]);

  const onFinish = async (values) => {
    const form = {
      date: nextMAPlan.format(dateFormat),
    };
    await setData(edge_barcode, form);
    await getOneEdgeApplication(edge_barcode);
  };

  const onShowModal = () => {
    setIsVisible(true);
    setDisableMAPlan(true);
  };

  const onHideModal = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      onHideModal();
    }
  }, [isSuccess]);

  const changeNextMAPlan = (date, dateString) => {
    setNextMAPlan(date);
  };

  const enableNextMAPlan = () => {
    setDisableMAPlan(false);
  };

  const maStatusLabel = () => {
    const maPlanDate = moment(nextMAPlan);
    const today = moment().subtract(1, 'days');
    const daysDiff = maPlanDate.diff(today, 'days');
    if (daysDiff > 30) {
      return 'Normal';
    }
    if (daysDiff <= 30 && daysDiff >= 0) {
      return 'Time to clean';
    }
    return 'Delayed';
  };

  return (
    <>
      <Modal title={btnName} visible={isVisible} onOk={onSubmit} onCancel={onHideModal} footer={null}>
        <Form form={form} onFinish={onFinish} scrollToFirstError layout="horizontal" {...layout}>
          <Row gutter={[12, 24]} align="middle">
            <Col span={12}>
              <Row gutter={8} align="middle">
                <Col span={10}>Next MA Plan</Col>
                <Col span={14}>
                  <DatePicker
                    disabled={disableMAPlan}
                    value={nextMAPlan}
                    onChange={changeNextMAPlan}
                    disabledDate={(d) => d.isBefore(moment().subtract(1, 'days'))}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={8}>Day Left</Col>
                <Col span={16}>{nextMAPlan && nextMAPlan.diff(moment().subtract(1, 'days'), 'days')}</Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={10}>MA Status</Col>
                <Col span={14}>
                  {maStatusLabel()}
                </Col>
              </Row>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Row gutter={8}>
                <Col span={10}>Actions</Col>
                <Col span={14}>
                  <Space>
                    <Button onClick={enableNextMAPlan}>Re-Plan</Button>
                    <Button htmlType="submit" type="primary">
                      Confirm
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <CustomerMAPlanTable />
            </Col>
          </Row>

          <Row></Row>
        </Form>
      </Modal>
      <Button type="primary" onClick={onShowModal}>
        {btnName}
      </Button>
    </>
  );
};

EditMAPlanModal.propTypes = {
  btnName: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  dataEdge: PropTypes.object,
  getOneEdgeApplication: PropTypes.func,
};

EditMAPlanModal.defaultProps = {
  btnName: null,
  onSubmit: () => {},
  onCancel: () => {},
  dataEdge: {
    ma_plan_date: moment().format(dateFormat),
  },
  getOneEdgeApplication: () => {},
};

export default EditMAPlanModal;
