import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@store/global/globalContext';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { defaultEdgeApplicationFilters } from '@root/config/edgeApplicationConfig';

export const useGlobalHook = () => useContext(GlobalContext);

const useProvideEdgeApplication = () => {
  const { pathname } = useLocation();

  const [edgeApplicationFilters, setEdgeApplicationFilters] = useState(defaultEdgeApplicationFilters);

  useEffect(() => {
    // reset filters when page scope is changed
    if (!pathname.includes('/dashboard/edge-applications')) {
      setEdgeApplicationFilters(defaultEdgeApplicationFilters);
    }
  }, [pathname]);

  return {
    edgeApplicationFilters,
    setEdgeApplicationFilters,
  };
};

export const GlobalHook = ({ children }) => {
  const roof = useProvideEdgeApplication();

  return <GlobalContext.Provider value={roof}>{children}</GlobalContext.Provider>;
};

GlobalHook.propTypes = {
  children: PropTypes.node,
};
