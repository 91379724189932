import Customer from '@pages/dashboard/customer';
import CustomerEdit from '@pages/dashboard/customerEdit';
import EdgeApplication from '@pages/dashboard/edgeApplication';
import EdgeApplicationEdit from '@pages/dashboard/edgeApplicationEdit';
import EdgeAssociateDevice from '@pages/dashboard/edgeAssociateDevice';
import EdgeWifi from '@pages/dashboard/edgeWifi';
import EditEdgeWifi from '@pages/dashboard/editEdgeWifi';
import User from '@pages/dashboard/user';
import UserEdit from '@pages/dashboard/userEdit';
import UserGroupEdit from '@pages/dashboard/userGroupEdit';
import UserGroups from '@pages/dashboard/userGroups';
import CallCenterPortalPage from '@root/pages/dashboard/callCenterPortal';
import CallCenterPortalCustomerDetail from '@root/pages/dashboard/callCenterPortalCustomerDetail';
import { DeleteEdgeData } from '@root/pages/dashboard/delete-edge-data';
import LogManagement from '@root/pages/dashboard/log-management';
import { ManageSCGID } from '@root/pages/dashboard/scg-id';
import { TransferEdgePage } from '@root/pages/dashboard/transfer-edge-data';
import TrinityDashboard from '@root/pages/dashboard/trinity-dashboard';
import { ProvideRole } from '@root/store/role/hook';
import { ProvideUserGroup } from '@root/store/userGroup/hook';
import { hasAccess, hasReadAccess } from '@root/utils/permission';
import { useAuth } from '@store/auth/hook';
import { ContextEditCustomer } from '@store/customer/hooks/useEditCustomer';
import { ContextListCustomer } from '@store/customer/hooks/useListCustomer';
import { ContextNewCustomer } from '@store/customer/hooks/useNewCustomer';
import { ProviderDashBoard } from '@store/dashboard/hook';
import { ProvideEdgeApplication } from '@store/edgeApplication/hook';
import { ContextNewMaPlan } from '@store/edgeApplication/hooks/useNewMaPlan';
import { ProvideAssociateDevice } from '@store/edgeAssociateDevice/hook';
import { ContextEditEdgeWifi } from '@store/edgeWifi/hooks/useEditEdgeWifi';
import { ContextListEdgeWifi } from '@store/edgeWifi/hooks/useListEdgeWifi';
import { ProvideUser } from '@store/user/hook';
import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const AdminRoute = () => {
  const { user } = useAuth();
  const { path } = useRouteMatch();

  const routeItems = useMemo(
    () =>
      [
        {
          path: '',
          scope: '',
          component: (
            <ProviderDashBoard>
              {/* <ProvideEdgeApplication> */}
              {/* <Dashboard /> */}
              <TrinityDashboard />
              {/* </ProvideEdgeApplication> */}
            </ProviderDashBoard>
          ),
        },
        {
          path: '/users',
          scope: 'users',
          component: (
            <ProvideUser>
              <User />
            </ProvideUser>
          ),
        },
        {
          path: '/users/:id',
          scope: 'users',
          component: (
            <ProvideUser>
              <UserEdit />
            </ProvideUser>
          ),
        },
        {
          path: '/edge-applications',
          scope: 'edge-applications',
          component: (
            <ProvideUserGroup>
              <ProvideEdgeApplication>
                  <EdgeApplication />
              </ProvideEdgeApplication>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/transfer-edge',
          scope: 'edge-data',
          component: (
            <ProvideUserGroup>
              <ProvideEdgeApplication>
                <TransferEdgePage />
              </ProvideEdgeApplication>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/scg-id',
          scope: 'scg-id',
          action: 'read-child',

          component: (
            <ProvideUserGroup>
              <ProvideEdgeApplication>
                <ManageSCGID roles={user?.userRoleKeys || []} />
              </ProvideEdgeApplication>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/log-management',
          scope: 'log-management',
          component: (
            <ProvideUserGroup>
              <ProvideEdgeApplication>
                <LogManagement />
              </ProvideEdgeApplication>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/delete-edge',
          scope: 'edge-data/delete',
          component: (
            <ProvideUserGroup>
              <ProvideEdgeApplication>
                <DeleteEdgeData />
              </ProvideEdgeApplication>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/edge-applications/:edge_barcode',
          scope: 'edge-applications',
          action: 'read-child',
          component: (
            <ProvideEdgeApplication>
              <ContextNewMaPlan>
                <EdgeApplicationEdit />
              </ContextNewMaPlan>
            </ProvideEdgeApplication>
          ),
        },
        {
          path: '/edge-associate-devices',
          scope: 'edge-associate-devices',
          component: (
            <ProvideUserGroup>
              <ProvideAssociateDevice>
                <EdgeAssociateDevice />
              </ProvideAssociateDevice>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/customers',
          scope: 'customers',
          component: (
            <ContextListCustomer>
              <ContextNewCustomer>
                <Customer />
              </ContextNewCustomer>
            </ContextListCustomer>
          ),
        },
        {
          path: '/customers/:id',
          scope: 'customers',
          component: (
            <ContextEditCustomer>
              <CustomerEdit />
            </ContextEditCustomer>
          ),
        },
        {
          path: '/edge-wifi',
          scope: 'edge-wifi',
          component: (
            <ProvideUserGroup>
              <ContextListEdgeWifi>
                <EdgeWifi />
              </ContextListEdgeWifi>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/edge-wifi/:id',
          scope: 'edge-wifi',
          component: (
            <ContextEditEdgeWifi>
              <EditEdgeWifi />
            </ContextEditEdgeWifi>
          ),
        },
        {
          path: '/user-groups',
          scope: 'user-groups',
          component: (
            <ProvideUserGroup>
              <ProvideRole>
                <ProvideUser>
                  <UserGroups />
                </ProvideUser>
              </ProvideRole>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/user-groups/:id',
          scope: 'user-groups',
          component: (
            <ProvideUserGroup>
              <ProvideRole>
                <ProvideUser>
                  <UserGroupEdit />
                </ProvideUser>
              </ProvideRole>
            </ProvideUserGroup>
          ),
        },
        {
          path: '/call-center-portal',
          scope: '',
          component: <CallCenterPortalPage />,
        },
        {
          path: '/call-center-portal/:id',
          scope: '',
          component: <CallCenterPortalCustomerDetail />,
        },
      ].filter((item) => {
        if (item.path.includes('/:')) {
          return hasAccess(user, item.scope, item.action || 'update');
        }
        return hasReadAccess(user, item.scope);
      }),
    [user],
  );

  return (
    <Switch>
      {routeItems.map((item, index) => (
        <Route exact path={`${path}${item.path}`} key={index}>
          {item.component}
        </Route>
      ))}
    </Switch>
  );
};

export default AdminRoute;
