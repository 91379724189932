import React, { useContext, createContext, useState } from 'react';
import { message } from 'antd';
import { newCustomer } from '../api';

const customerContext = createContext();

const useNewCustomer = () => {
  return useContext(customerContext);
};

const useProvideCustomer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const setData = async (form, cb = () => {}) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await newCustomer(form);
    if (res.status === '200') {
      message.success('Success to create customer');
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return {
    isLoading,
    isSuccess,
    isError,
    setData,
  };
};

const ContextNewCustomer = ({ children }) => {
  const roof = useProvideCustomer();

  return <customerContext.Provider value={roof}>{children}</customerContext.Provider>;
};

export { useNewCustomer, ContextNewCustomer };
