import { Breadcrumb, Col, Row, Select, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Loading from '@components/atoms/loading';
import EdgeCustomerInfo from '@components/molecules/edgeCustomerInfo';
import EdgeDeviceInfo from '@components/molecules/edgeDeviceInfo';
import GoogleMapContainer from '@components/organisms/googleMapContainer';
import EdgeApplicationTokenModal from '@components/organisms/modals/edgeApplicationTokenModal';
import EditMAPlanModal from '@components/organisms/modals/newMAPlanModal/editMAPlanModal';
import AbnormalStatusHistoryTable from '@components/organisms/tables/abnormalStatusHistoryTable';
import ActuatorTable from '@components/organisms/tables/actuatorTable';
import AssociateDeviceTable from '@components/organisms/tables/associateDeviceTable';
import EdgeComponentTable from '@components/organisms/tables/edgeComponentTable';
import ActiveAirFlowTab from '@components/organisms/tabs/activeAirFlowTab';
import SolarRoofTab from '@components/organisms/tabs/solarRoofTab';
import { ConfirmModal } from '@root/components/common';
import ActiveAirQualityTab from '@root/components/organisms/tabs/activeAirQualityTab';
import { useQuery } from '@root/utils';
import { capitalize } from '@root/utils/capitalize';
import { canViewEdgeAppSolution } from '@root/utils/permission';
import { useAuth } from '@store/auth/hook';
import { useEdgeApplication } from '@store/edgeApplication/hook';
import { Option } from 'antd/lib/mentions';
import { useHistory } from 'react-router-dom';
import { CheckInHistoryTab } from './components/CheckInHistoryTab';
import { SCGIDTab } from './components/SCGIDTab';

const { TabPane } = Tabs;

const EdgeApplicationEdit = () => {
  const query = useQuery();
  const { user } = useAuth();
  const { deleteEdgeApplication } = useEdgeApplication();
  const activeTab = query.get('activeTab');
  const history = useHistory();

  const canViewSCGID = useMemo(() => {
    return user.userRoleKeys.includes('scg-id/read');
  }, [user.userRoleKeys]);

  const { edge_barcode } = useParams();
  const { dataEdge, getOneEdgeApplication, getEdgeDeviceLocations } = useEdgeApplication();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState();
  const [visbleTabList, setVisibleTabList] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [actions, setAction] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const onChangeTab = (val) => {
    setCurrentTab(val);
  };

  useEffect(() => {
    // getSolarOverview(edge_barcode); // dataSolarOverview is fetched directly from edge device API
    getOneEdgeApplication(edge_barcode);
    getEdgeDeviceLocations(edge_barcode);
  }, []);

  useEffect(() => {
    const { userRoleKeys } = user;
    const edgePermissions = userRoleKeys?.filter(
      (permission) => permission.startsWith('edge-applications/') && !permission.includes('/read'),
    );
    const actions = edgePermissions.map((permission) => permission.split('/')[1]);
    setAction(actions);
  }, [user]);

  useEffect(() => {
    if (!dataEdge) {
      setIsLoading(true);
      return;
    }

    const applicationNameList = dataEdge.application_devices.map((val) => val.application_name);
    if (applicationNameList.length) {
      let tab = activeTab || applicationNameList[0];
      if (tab === 'aafq') {
        tab = 'aaq';
      }
      setCurrentTab(tab);
    }
    setVisibleTabList(applicationNameList);
    setIsLoading(false);
  }, [dataEdge]);

  useMemo(() => {
    if (!dataEdge) {
      return;
    }
    const [customer] = dataEdge.customers;
    if (!customer || !customer.addresses.length) {
      setMarkers([]);
      return;
    }
    const markerItems = customer.addresses
      .filter((address) => address.latitude && address.longitude)
      .map((address) => ({
        name: customer.first_name + ' ' + customer.last_name,
        lat: address.latitude,
        lng: address.longitude,
        status: dataEdge.solar_status,
      }));
    setMarkers(markerItems);
  }, [dataEdge]);

  const handleSelectChange = async (value) => {
    switch (value) {
      case 'delete':
        setIsOpenModal(true);
        break;
    }
  };

  const handleDeleteEdgeApplication = async () => {
    await deleteEdgeApplication(edge_barcode);
    setIsOpenModal(false);
    history.push('/dashboard/edge-applications');
  };

  return (
    <div>
      <ConfirmModal visible={isOpenModal} onClose={() => setIsOpenModal(false)} onOk={handleDeleteEdgeApplication} />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 24 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard/edge-applications">List view</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Detail: {edge_barcode}</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          Action:{' '}
          <Select style={{ width: 120 }} onChange={handleSelectChange}>
            {actions.map((action) => (
              <Option key={action} value={action}>
                {capitalize(action)}
              </Option>
            ))}
          </Select>
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Row gutter={[16, 32]}>
            <Col span={12}>
              <EdgeCustomerInfo currentTab={currentTab} />
            </Col>
            <Col span={12}>
              <EdgeDeviceInfo currentTab={currentTab} />
            </Col>
          </Row>
          <Tabs
            activeKey={currentTab}
            onChange={onChangeTab}
            tabBarExtraContent={
              currentTab === 'solar' && (
                <EditMAPlanModal
                  getOneEdgeApplication={getOneEdgeApplication}
                  btnName="Register MA Plan"
                  dataEdge={dataEdge}
                />
              )
            }
          >
            {canViewEdgeAppSolution(user, 'solar') && visbleTabList.includes('solar') && (
              <TabPane tab="Solar roof" key="solar">
                <SolarRoofTab />
              </TabPane>
            )}

            {visbleTabList.includes('deebox') && (
              <TabPane tab="Deebox" key="deebox">
                <SolarRoofTab />
              </TabPane>
            )}

            {canViewEdgeAppSolution(user, 'aaq', 'aafq') &&
              ['aaq', 'aafq'].some((scope) => visbleTabList.includes(scope)) && (
                <TabPane tab="Active air quality" key="aaq">
                  <ActiveAirQualityTab />
                </TabPane>
              )}

            {canViewEdgeAppSolution(user, 'aaf', 'aafq') &&
              ['aaf', 'aafq'].some((scope) => visbleTabList.includes(scope)) && (
                <TabPane tab="Active air flow" key="aaf">
                  <ActiveAirFlowTab />
                </TabPane>
              )}

            {visbleTabList.length === 0 && (
              <TabPane tab="Empty" key="empty">
                No information
              </TabPane>
            )}

            <TabPane tab="Associate Devices" key="associate_devices">
              <AssociateDeviceTable />
            </TabPane>

            <TabPane tab="Components" key="components">
              <EdgeComponentTable />
            </TabPane>

            <TabPane tab="Map" key="google_map">
              <div style={{ width: '100%', height: 700 }}>
                <GoogleMapContainer markers={markers} />
              </div>
            </TabPane>

            <TabPane tab="CheckIn History" key="checkin_history">
              <CheckInHistoryTab />
            </TabPane>

            <TabPane tab="Actuator" key="actuator">
              <ActuatorTable />
            </TabPane>

            {canViewSCGID && (
              <TabPane tab="SCG ID" key="scg_id">
                <SCGIDTab />
              </TabPane>
            )}

            {!1 && (
              <TabPane tab="Abnormal status" key="abnormal_status">
                <AbnormalStatusHistoryTable items={dataEdge?.abnormalStatusHistory} />
              </TabPane>
            )}
          </Tabs>
          <EdgeApplicationTokenModal
            visible={isShowModal}
            onSubmit={() => setIsShowModal(false)}
            onCancel={() => setIsShowModal(false)}
            value={dataEdge?.token}
          />
        </>
      )}
    </div>
  );
};

export default EdgeApplicationEdit;
