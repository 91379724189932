import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 20px 10px;
  border-radius: 5px;

  border: 1px solid #e9e9e9;
  position: relative;

  &::before {
    position: absolute;
    left: 0px;
    top: 2px;
    bottom: 2px;
    content: ' ';
    border: 3px solid ${(props) => props.color};
    border-radius: 10px;
  }
`;
