import React, { useState, useEffect } from 'react';
import { Typography, Breadcrumb } from 'antd';
import { useParams, Link, useHistory } from 'react-router-dom';

import EditUserForm from '@components/organisms/forms/editUserForm';

import { useUser } from '@store/user/hook';

const { Title } = Typography;

const UserEdit = () => {
  const { id } = useParams();
  const { stateUser, stateEditUser, getOneUser, editUser } = useUser();
  const [data, setData] = useState(null);
  const history = useHistory();

  const onFinish = async (values) => {
    await editUser(id, values);
    history.push('/dashboard/users');
  };

  useEffect(() => {
    getOneUser(id);
  }, [id]);

  useEffect(() => {
    if (stateUser?.isSuccess) {
      setData(stateUser.data);
    }
  }, [stateUser?.isSuccess]);

  useEffect(() => {
    if (stateEditUser?.isSuccess) {
      setData(stateEditUser.data);
    }
  }, [stateEditUser?.isSuccess]);

  return (
    <div>
      <Title level={2}>User Edit</Title>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item>
          <Link to="/dashboard/users">Users</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ID: {id}</Breadcrumb.Item>
      </Breadcrumb>
      <EditUserForm data={data} onFinish={onFinish} isLoading={stateEditUser?.isLoading} btnCancel={false} />
    </div>
  );
};

export default UserEdit;
