import React from 'react';
import { Form, Input, Button, Typography, notification, Space } from 'antd';
import { useAuth } from '@root/store/auth/hook';
import { useMutation } from 'react-query';
import { resetMFA } from '@root/services/cognito.service';
const { Title, Text } = Typography;

const formRules = {
  password: [{ required: true, message: 'Please enter your password' }],
};

const SetupMFAForm = ({ handleSuccessFormResetMFA }) => {
  const [form] = Form.useForm();
  const auth = useAuth();

  const { mutate, isLoading } = useMutation({
    mutationFn: resetMFA,
    onSuccess: (res) => {
      handleSuccessFormResetMFA({
        secretCode: res.data.SecretCode,
        accessToken: res.data.AccessToken,
      });
    },
    onError: () => {
      notification.error({ message: 'กรุณาตรวจสอบรหัสผ่าน' });
    },
  });

  const onFinish = async (values) => {
    const payload = {
      username: auth.user.username,
      password: values.password,
    };
    mutate(payload);
  };

  return (
    <div>
      <Title level={4}>ตั้งค่า Multi Factor Authentication</Title>
      <Space direction="vertical" style={{ padding: '15px 0' }} size={0}>
        <Text>ใส่รหัสผ่านของคุณ เพื่อเริ่มการตั้งค่า</Text>
        <Text>Multi Factor Authentication</Text>
      </Space>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="password" label="รหัสผ่าน" rules={formRules.password}>
          <Input.Password disabled={isLoading} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={isLoading}>
            ยืนยัน
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SetupMFAForm;
