import styled from "styled-components";

const TextCard = styled.div`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
`;

export default TextCard;