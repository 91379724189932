import config from '@config';
import QueryString from 'query-string';

import { HttpAuth } from '@services/http';
import { EdgeStatusEnum, MAStatusEnum, getMaStatus } from '@services/edgeApplication';
import { listEdge } from './mockData';
import { datePeriod } from '@root/utils';

const path = `${config.API_ENDPOINT}/edge-applications`;

const getListEdgeApplication = async (params = {}) => {
  return HttpAuth.get(path, { params });
};

const getEdgeApplicationsPagination = async (params = {}) => {
  return HttpAuth.get(`${path}/pagination`, { params });
};

const exportEdgeApplications = async (params = {}) => {
  return HttpAuth.get(`${path}/export`, { params });
};

const getCountEdgeApplications = async (params = {}) => {
  return HttpAuth.get(`${path}/count`, { params });
};

const getOneEdgeApplication = async (edge_barcode) => {
  return HttpAuth.get(`${path}/${edge_barcode}`);
};

const getThingGroupFilterOptions = async (params = {}) => {
  const thingGroup = HttpAuth.get(`${path}/list-thing-group`);
  return thingGroup
};

const getSummaryEdgeApplication = async () => {
  return new Promise((resolve) => {
    const all = listEdge.length;
    const online = listEdge.filter((val) => val.status === EdgeStatusEnum.online).length;
    const offline = listEdge.filter((val) => val.status === EdgeStatusEnum.offline).length;
    const dataLoss = listEdge.filter((val) => val.status === EdgeStatusEnum.dataLoss).length;
    const maPlan = listEdge.filter((val) => {
      const status = getMaStatus(val.ma_plan_date);
      return status === MAStatusEnum.delayed;
    }).length;

    const data = {
      all,
      online,
      offline,
      dataLoss,
      maPlan,
    };

    resolve({
      status: '200',
      data,
    });
  });
};

const getSolarOverview = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/solar-overview`);
};

const getSolarCurrentData = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/solar-data`);
};

const getSolarHistory = async (edgeBarcode, filter) => {
  const query = QueryString.stringify(filter, { skipEmptyString: true });

  return HttpAuth.get(`${path}/${edgeBarcode}/solar-history?${query}`);
};

const getSolarStatistic = async (edgeBarcode, filter) => {
  let query;
  if (filter?.period === datePeriod.day && filter?.date?.length === 2) {
    filter.from = filter.date[0];
    filter.to = filter.date[1];
    delete filter.date;
    query = QueryString.stringify(filter, { skipEmptyString: true });
    //console.log(`${path}/${edgeBarcode}/solar-statistic?${query}`);
    return HttpAuth.get(`${path}/${edgeBarcode}/solar-statistic?${query}`);
  }
  //console.log('a2');
  query = QueryString.stringify(filter, { skipEmptyString: true });
  //console.log(`${path}/${edgeBarcode}/solar-statistic?${query}`);
  return HttpAuth.get(`${path}/${edgeBarcode}/solar-statistic?${query}`);
};

const getTodayInformation = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/solar-info`);
};

const getActiveAirFlowTodayInformation = async (edgeBarcode, area) => {
  const query = QueryString.stringify(area, { skipEmptyString: true });
  return HttpAuth.get(`${path}/${edgeBarcode}/aaf-info?${query}`);
};

const getActiveAirFlowStatistic = async (edgeBarcode, filter) => {
  const query = QueryString.stringify(filter, { skipEmptyString: true });

  return HttpAuth.get(`${path}/${edgeBarcode}/aaf-statistic?${query}`);
};

const getActiveAirQualityLocations = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/aaq-locations`);
};

const getEdgeDeviceLocations = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/edge-locations`);
};

const getActiveAirQualityTodayInformation = async (edgeBarcode, area) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/${area}/aaq-info`);
};

const getActiveAirQualityStatistic = async (edgeBarcode, filter) => {
  const query = QueryString.stringify(filter, { skipEmptyString: true });

  return HttpAuth.get(`${path}/${edgeBarcode}/aaq-statistic?${query}`);
};

const updateTokenEdgeApplication = async (edgeBarcode, form) => {
  return HttpAuth.patch(`${path}/${edgeBarcode}/permission`, form);
};

const newMaPlan = async (edgeBarcode, form) => {
  return HttpAuth.post(`${path}/${edgeBarcode}/ma-plans`, form);
};

const getEdgeDashboard = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/data-dashboard`);
};

const getEdgeStatusDevices = async (params = {}) => {
  return HttpAuth.get(`${path}/edge-status-devices-2`, { params });
};

const getEdgeComponents = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/components`);
};

const getSolarInverter = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/solar/inverter`);
};

const getSolarPowerMeter = async (edgeBarcode) => {
  return HttpAuth.get(`${path}/${edgeBarcode}/solar/powerMeter`);
};

const getListActuator = async (params = {}) => {
  return HttpAuth.get(`${path}/actuator`, { params });
};

const getAssociateDevice = async (edgeBarcode, params = {}) => {
  return HttpAuth.get(`${config.API_ENDPOINT}/edge-associate-devices`, {
    params: { ...params, edgeId: edgeBarcode, page: 1, pageSize: -1 },
  });
};

const deleteEdgeApplication = async (edgeBarcode) => {
  return HttpAuth.delete(`${path}/${edgeBarcode}`);
};

const getEdgeFilterOptions = async (params) => {
  return HttpAuth.get(`${path}/getEdgeFilterOptions`, { params });
};

const newJiraCard = async (edgeBarcode, params) => {
  return HttpAuth.put(`${path}/${edgeBarcode}/jira-card-number`, params);
};


export default {
  getListEdgeApplication,
  getOneEdgeApplication,
  getSummaryEdgeApplication,
  getSolarOverview,
  getSolarCurrentData,
  getSolarHistory,
  getSolarStatistic,
  getTodayInformation,
  getActiveAirFlowTodayInformation,
  getActiveAirFlowStatistic,
  getActiveAirQualityLocations,
  getEdgeDeviceLocations,
  getActiveAirQualityTodayInformation,
  getActiveAirQualityStatistic,
  updateTokenEdgeApplication,
  newMaPlan,
  getEdgeDashboard,
  getEdgeApplicationsPagination,
  exportEdgeApplications,
  getCountEdgeApplications,
  getEdgeStatusDevices,
  getEdgeComponents,
  getSolarInverter,
  getSolarPowerMeter,
  getListActuator,
  getAssociateDevice,
  deleteEdgeApplication,
  getEdgeFilterOptions,
  getThingGroupFilterOptions,
  newJiraCard
};
