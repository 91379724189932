export const validateNotOnlySpaces = (customErrorMessage) => (_, value) => {
  if (value && value.trim() === '') {
    return Promise.reject({ message: customErrorMessage });
  }
  return Promise.resolve();
};

export const validateDifferenceValue = (form, value, target) => {
  const targetValue = form.getFieldValue(target);
  if (value && value === targetValue) {
    return Promise.reject('The selected Edge Barcode must not be the same');
  }
  return Promise.resolve();
};
