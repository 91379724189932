import config from '@config';
import { HttpAuth } from '@services/http';

const path = `${config.API_ENDPOINT}/customers`;

const getListCustomer = () => {
  return HttpAuth.get(path);
};

const newCustomer = (form) => {
  return HttpAuth.post(path, form);
};

const getOneCustomer = async (id) => {
  return HttpAuth.get(`${path}/${id}`);
};

const editCustomer = async (id, form) => {
  return HttpAuth.patch(`${path}/${id}`, form);
};

const getListCustomerSearch = (key, value) => {
  return HttpAuth.get(`${path}?${key}=${value}`);
};

export { getListCustomer, newCustomer, getOneCustomer, editCustomer, getListCustomerSearch };
