import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, Space, Button, Divider } from 'antd';
import { useAuth } from '@store/auth/hook';
import EdgeWifiLogTable from '@components/organisms/tables/edgeWifiLogTable';

const formRules = {
  ssid: [
    {
      max: 70,
    },
  ],
  password: [
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(.{8,})$/,
      message:
        'Password must be 8 or more characters long and contain at least 1 uppercase, lowercase, 0-9 digit, and special character.',
    },
  ],
  note: [{ max: 150 }],
};

const EditEdgeWifiForm = (props) => {
  const { data, onFinish, onCancel, isLoading, btnCancel } = props;
  const [form] = Form.useForm();
  const { user } = useAuth();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFormFinish = (values) => {
    onFinish({
      ...values,
      userId: user.id,
      edge_application: data.edge_application?.id,
    });
  };

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue(data);
  }, [data?.id]);

  return (
    <Form form={form} onFinish={onFormFinish} scrollToFirstError layout="horizontal" {...layout}>
      <Col span={18} offset={2}>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="ssid" label="SSID" rules={formRules.ssid}>
              <Input maxLength={70} autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="password" label="Password">
              <Input.Password autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Form.Item name="note" label="Note" rules={formRules.note}>
              <Input maxLength={150} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={12} offset={12}>
            <Row justify="end">
              <Space>
                {btnCancel && <Button onClick={onCancel}>Cancel</Button>}
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  Edit
                </Button>
              </Space>
            </Row>
          </Col>
        </Row>

        <Col span={18} offset={3}>
          <Divider type="horizontal" style={{ width: '100%' }} />
        </Col>

        <Row gutter={[10, 10]}>
          <Col span={18} offset={3}>
            <EdgeWifiLogTable items={data?.edgeWifiLogs} />
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

EditEdgeWifiForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, null]),
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  btnCancel: PropTypes.bool,
};

EditEdgeWifiForm.defaultProps = {
  data: null,
  onFinish: () => {},
  onCancel: () => {},
  isLoading: false,
  btnCancel: true,
};

export default EditEdgeWifiForm;
