import React, { useEffect, useState } from 'react';
import { Typography, Row, Button } from 'antd';

import UserGroupTable from '@components/organisms/tables/userGroupTable';
import NewUserGroupModal from '@components/organisms/modals/newUserGroupModal';
import { useUserGroup } from '@root/store/userGroup/hook';
import { useUser } from '@root/store/user/hook';
import { useAuth } from '@store/auth/hook';
import { hasCreateAccess } from '@root/utils/permission';

const { Title } = Typography;

const UserGroups = () => {
  const { user } = useAuth();
  const { listUserGroups, getListUserGroups } = useUserGroup();
  const { listUser, getListUser } = useUser();
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const onOpenModal = () => {
    setIsVisibleModal(true);
  };

  const onSubmitModal = () => {
    setIsVisibleModal(false);
  };

  const onCancelModal = () => {
    setIsVisibleModal(false);
  };

  useEffect(() => {
    getListUserGroups();
    getListUser();
  }, []);

  return (
    <>
      <Title level={2}>User Groups</Title>
      {hasCreateAccess(user, 'user-groups') && (
        <Row justify="end">
          <Button type="primary" onClick={onOpenModal}>
            New User Group
          </Button>
        </Row>
      )}
      <UserGroupTable items={listUserGroups} />
      {hasCreateAccess(user, 'user-groups') && (
        <NewUserGroupModal
          listUser={listUser}
          data={user}
          visible={isVisibleModal}
          onSubmit={onSubmitModal}
          onCancel={onCancelModal}
        />
      )}
    </>
  );
};

export default UserGroups;
