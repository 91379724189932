import * as React from "react"
const EditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M.8 12.2v3h3l8.848-8.848-3-3L.8 12.2Zm14.728-8.728-3-3-2.024 2.032 3 3 2.024-2.032Z"
    />
  </svg>
)
export default EditIcon
