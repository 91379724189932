import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

const BlockedPage = () => {
  const history = useHistory();
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f0f0f0',
    },
  };
  return (
    <div style={styles.container}>
      <Result
        status="error"
        title="Access Temporarily Restricted"
        subTitle="Your IP address has been temporarily blocked due to excessive login attempts. Please try again later."
        extra={
          <Button type="primary" onClick={() => history.push('/login')}>
            Go to login
          </Button>
        }
      />
    </div>
  );
};

export default BlockedPage;
