import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 155px;
  padding: 20px 10px;
  border-radius: 5px;

  /* font-size: 14px; */
  line-height: 1.5;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.25);

  .title {}

  .value {
    font-weight: bold;
    font-size: 30px;
    line-height: 1.2;
  }
`;