import { transferSCGID } from '@root/services/scg-id';
import { useDialog } from '@root/utils/useModal';
import { Button, Form, Input, Modal, notification, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useMutation } from 'react-query';

const formRules = {
  edgeId: [
    { required: true, message: 'Please enter Edge ID' },
    {
      pattern: /^[a-zA-Z0-9_]{19}$/,
      message: 'EDGE ID is not valid format',
    },
  ],
  scgId: [
    { required: true, message: 'Please enter SCG ID' },
    {
      pattern: /^[0-9]+$/,
      message: 'SCG ID is not valid number',
    },
    { min: 10, message: 'SCG ID must at least 10 characters!' },
  ],
};

export const TransferSCGID = () => {
  const [form] = Form.useForm();
  const { isVisible, open, close } = useDialog();
  const { mutate, isLoading } = useMutation({
    mutationFn: transferSCGID,
    onSuccess: (res) => {
      notification.success({ message: 'Success!' });
      close();
    },
    onError: () => {
      notification.error({ message: 'Something went wrong. Please try again' });
      close();
    },
  });

  const onSubmit = async () => {
    const res = await form.validateFields();
    mutate(res);
  };

  const openModal = () => {
    form
      .validateFields()
      .then(() => {
        open();
      })
      .catch((_) => {
        message.error('Form Validation Failed');
      });
  };

  return (
    <div>
      <Title level={5}>Transfer SCG ID</Title>
      <Form form={form} style={{ display: 'flex', gap: 20 }} autoComplete="off">
        <Modal visible={isVisible} onCancel={close} footer={null} title="Confirmation">
          <Title style={{ textAlign: 'center' }} level={4}>
            Are you sure you want to transfer this Edge ID
          </Title>
          <div style={{ display: 'flex', justifyContent: 'end', padding: '20px 0', gap: 10 }}>
            <Button onClick={close} type="default">
              Cancel
            </Button>
            <Button loading={isLoading} onClick={onSubmit} htmlType="submit" type="primary">
              Add
            </Button>
          </div>
        </Modal>
        <div>
          <Form.Item label="Old Edge ID" name="old_edge_id" rules={formRules.edgeId}>
            <Input maxLength={19} />
          </Form.Item>
          <Form.Item label="New Edge ID" name="new_edge_id" rules={formRules.edgeId}>
            <Input maxLength={19} />
          </Form.Item>
        </div>

        <Form.Item label="SCG ID" name="scg_id" rules={formRules.scgId}>
          <Input />
        </Form.Item>

        <Form.Item>
          <Button onClick={openModal} style={{ width: 200 }} type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
