import React, { useContext, createContext, useState } from 'react';
import { message } from 'antd';
import api from '../api';

const edgeApplicationContext = createContext();

const useNewMaPlan = () => {
  return useContext(edgeApplicationContext);
};

const useProvideEdgeApplication = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const setData = async (edgeBarcode, form) => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);

    const res = await api.newMaPlan(edgeBarcode, form);
    if (res.status === '200') {
      message.success('Success to create MA Plan');
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
    } else {
      message.error(res.message || 'Something went wrong!');
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return {
    isLoading,
    isSuccess,
    isError,
    setData,
  };
};

const ContextNewMaPlan = ({ children }) => {
  const roof = useProvideEdgeApplication();

  return <edgeApplicationContext.Provider value={roof}>{children}</edgeApplicationContext.Provider>;
};

export { useNewMaPlan, ContextNewMaPlan };
