import styled from 'styled-components';
import { device } from '@root/utils';

export default styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fff;
  .side-container {
    flex: 0 0 500px;
  }
  @media ${device.tablet} {
    .side-container {
      display: none;
    }
  }
  .main-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login-container {
    width: 100%;
    max-width: 450px;
    padding: 25px;
  }
`;
