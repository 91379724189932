import * as React from "react"
const IconRefresh = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#5B5D60"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.352 7.79h4.16V7.79M2.488 16.37v-4.16m0 0h4.16m-4.16 0 2.65 2.652A6.875 6.875 0 0 0 16.64 11.78M3.36 8.221a6.875 6.875 0 0 1 11.503-3.083l2.65 2.65m0-4.158v4.159"
    />
  </svg>
)
export default IconRefresh
