import { Button, Modal, notification } from 'antd';
import React from 'react';
import { useMutation } from 'react-query';
import api from '@store/user/api';
import { useAuth } from '@root/store/auth/hook';
const UserResetMfa = ({ user }) => {
  const { user: userAuth } = useAuth();
  const { mutate, isLoading } = useMutation({
    mutationFn: api.resetUserMFA,
    onSuccess: () => {
      notification.success({ message: 'สำเร็จ!' });
    },
    onError: () => {
      notification.error({ message: 'Something went wrong. Please try again' });
    },
  });

  const handleResetMfa = () => {
    Modal.confirm({
      title: 'ยืนยัน Reset MFA',
      onOk() {
        mutate(user.id);
      },
    });
  };
  const isEmailScg = user.username.endsWith('@scg.com');
  const isSuperAdmin = userAuth?.adminGroup?.adminType === 'SuperAdmin';
  if (isEmailScg || !isSuperAdmin) {
    return null;
  }
  return (
    <Button type="link" onClick={handleResetMfa} loading={isLoading}>
      Reset MFA
    </Button>
  );
};

export default UserResetMfa;
