import React from 'react';
import TextCardTableDeviceBody from './style';
import dayjs from 'dayjs';

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const TextCardTableDeviceBodyNcc = (props) => {
  const formatWarrantyDuration = (installed_date, warranty_expiry_date) => {
    if (!installed_date && !warranty_expiry_date) {
      return '-';
    }
    const startDate = installed_date ? dayjs(installed_date).format('DD/MM/YYYY') : 'N/A';
    const endDate = warranty_expiry_date ? dayjs(warranty_expiry_date).format('DD/MM/YYYY') : 'N/A';
    return `${startDate} - ${endDate}`;
  };

  const getRemainingWarranty = (warranty_expiry_date) => {
    const now = dayjs();
    const endDate = dayjs(warranty_expiry_date);
    const duration = dayjs.duration(endDate.diff(now));
    return (
      (duration.years() > 0 ? `${duration.years()} ปี ` : '') +
      (duration.months() > 0 ? `${duration.months()} เดือน ` : '') +
      (duration.days() > 0 ? `${duration.days()} วัน` : '')
    );
  };

  return (
    <TextCardTableDeviceBody>
      <div className="wrapDeviceBox">
        {props?.deviceHome.map((data, key) => {
          return (
            <div className="wrapDeviceBoxIn" key={key}>
              <div className="wrapDeviceBoxInBox">
                <p>{data.name}</p>
                <div className="wrapModel">
                  <p>{data.model || '-'}</p>
                  {/* <a href="#"><img src="../mind-info.svg" alt="info"/></a> */}
                </div>
                <p>{data.device_type || '-'}</p>
                <p>{data.signal || '-'}</p>
                <div>
                  {data.online === true || data.online === false ? (
                    <>
                      <div className="wrapStatus">
                        <div className={data.online ? 'statusActive' : 'statusOffline'}></div>
                        <p>{data.online ? 'ออนไลน์' : 'ออฟไลน์'}</p>
                      </div>
                      <p className="pDate">
                        อัปเดตล่าสุด:
                        <br />
                        {data.device_status_update_at
                          ? dayjs(data.device_status_update_at).format('DD/MM/YYYY HH:mm')
                          : '-'}
                      </p>
                    </>
                  ) : (
                    <p>-</p>
                  )}
                </div>
                <p>{data.device_sensitivity || '-'}</p>
              </div>
              <div className="wrapDeviceBoxInBoxB">
                <p>{data.battery_type || '-'}</p>
                <div>
                  <p>{data.battery_remain || '-'}</p>
                </div>
                <p>{data.battery_amount || '-'}</p>
                <p>{formatWarrantyDuration(data.installed_date, data.warranty_expiry_date)}</p>
                <p>{data.warranty_expiry_date ? getRemainingWarranty(data.warranty_expiry_date) : '-'}</p>
              </div>
            </div>
          );
        })}
      </div>
    </TextCardTableDeviceBody>
  );
};

export default TextCardTableDeviceBodyNcc;
