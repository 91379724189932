import React from "react";
import HeaderBar from "./style";
import LogoMind from "../icon/logo";

const HeaderNcc = () => {
  return (
    <HeaderBar>
      <div className="container">
        <LogoMind className="logo" />
      </div>
    </HeaderBar>
  )
}

export default HeaderNcc;