import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Space, Button, Select, Tree } from 'antd';
import UserAssignUserGroupTable from '@components/organisms/tables/userAssignUserGroupTable';
import { definingRoles } from '@root/utils';
import { userRoleTree } from '@root/config/userRoleConfig';
import { useUserGroup } from '@store/userGroup/hook';
import Styled from './style';

const { Option } = Select;

const EditUserGroupForm = (props) => {
  const { data, onFinish, onCancel, isLoading, isSaving, btnCancel, listUser } = props;
  const { tenants, getTenants } = useUserGroup();
  const { data: tenantsData } = tenants;
  const [role, setRole] = useState('');
  const [form] = Form.useForm();

  const [expandedKeys, setExpandedKeys] = useState(userRoleTree.map((item) => item.key));
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      md: { span: 10, offset: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      md: { span: 10, offset: 6 },
    },
  };

  useEffect(() => {
    data.role && getTenants({ role: data.role });
    setRole(data.role);
  }, [data]);

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onFormFinish = (values) => {
    if (data?.channel != 'Trinity') return;
    delete values.username;
    values.roleKeys = checkedKeys;
    values.selectedUsers = selectedUsers;
    values.tenants = [values.tenant];
    values.isActive = data.isActive;
    onFinish(values);
  };

  const onSelectUserChange = (lstSelectedUsers) => {
    setSelectedUsers(lstSelectedUsers);
  };

  useEffect(() => {
    if (!data.id || isLoading) {
      form.setFieldsValue({});
      return;
    }
    const fullRole = data.fullRole.split(',');
    setCheckedKeys(data.roleKeys.split(','));
    setSelectedUsers(data.userGroupUser.map((user) => user.userId));
    form.setFieldsValue({ ...data, fullRole });
  }, [data.id, isLoading]);

  const handleChangeRole = (value) => {
    setRole(value);
    if (value !== 'scg') {
      getTenants({ role: value });
    }
  };

  return (
    <Styled>
      <Form form={form} onFinish={onFormFinish} scrollToFirstError layout="vertical" {...formItemLayout}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please input name' },
            { max: 70, message: 'Maximum length is 70 characters' },
            {
              pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
              message: 'The group name is not in a valid format.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className={role === 'tenant' && 'role ant-col-xs-24 ant-col-md-10 ant-col-md-offset-6'}>
          <div className="gutter-col">
            <Form.Item name="role" label="Role" rules={[{ required: true, message: 'Please select role' }]}>
              <Select style={{ width: '100%' }} onChange={handleChangeRole}>
                <Option value="scg">SCG</Option>
                <Option value="tenant">Tenant</Option>
              </Select>
            </Form.Item>
          </div>
          {role === 'tenant' && (
            <div className="gutter-col">
              {data.id && (
                <Form.Item name="tenant" label="Tenant">
                  <Select style={{ width: '100%' }} onChange={() => {}} defaultValue={+data.tenants || ''}>
                    {tenantsData &&
                      tenantsData.map((tenant) => (
                        <Option key={tenant.id} value={tenant.id}>
                          {tenant.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          )}
        </div>
        <Form.Item name="fullRole" label="Solutions" rules={[{ required: true, message: 'Please select solutions' }]}>
          <Select mode="multiple" allowClear placeholder="Please select">
            {definingRoles.map((role, index) => (
              <Option key={index} value={role.value}>
                {role.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={userRoleTree}
          />
        </Form.Item>
        <Form.Item>
          <UserAssignUserGroupTable
            selectedRowKeys={selectedUsers}
            items={listUser}
            onSelectUserChange={onSelectUserChange}
          />
        </Form.Item>
        <Form.Item wrapperCol={formItemLayout.wrapperCol}>
          <Row justify="end">
            <Space>
              {btnCancel && <Button onClick={onCancel}>Cancel</Button>}
              <Button htmlType="submit" type="primary" loading={isSaving} disabled={data?.channel != 'Trinity'}>
                Edit
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </Styled>
  );
};

EditUserGroupForm.propTypes = {
  data: PropTypes.object,
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  btnCancel: PropTypes.bool,
  listUser: PropTypes.array,
};

EditUserGroupForm.defaultProps = {
  data: null,
  onFinish: () => {},
  onCancel: () => {},
  isLoading: false,
  isSaving: false,
  btnCancel: true,
  listUser: [],
};

export default EditUserGroupForm;
