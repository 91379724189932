import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomerByID } from '@root/services/customers.service';
import NCCContentForCallCenter from './components/NCCContentForCallCenterPortal';

const CallCenterPortalCustomerDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  const fetchCustomerData = async () => {
    const res = await getCustomerByID(id);
    console.log(res.data[0]);
    setData(res.data[0]);
  };

  useEffect(() => {
    fetchCustomerData();
  }, [id]);

  return <NCCContentForCallCenter data={data} />;
};

export default CallCenterPortalCustomerDetail;
