export const datePeriod = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
  lifetime: 'lifetime',
};

export const formatByPeriod = (period) => {
  switch (period) {
    case datePeriod.week:
      return 'Wo-YYYY';
    case datePeriod.month:
      return 'MMMM-YYYY';
    case datePeriod.year:
      return 'YYYY';
    case datePeriod.day:
    case datePeriod.lifetime:
    default:
      return 'DD-MMMM-YYYY';
  }
};

export const dateByPeriod = (period, date) => {
  if (period === datePeriod.week) {
    return date.startOf('week').format('YYYY-MM-DD');
  }
  if (period === datePeriod.day && date.length > 1) {
    return date.map((d) => d.format('YYYY-MM-DD'));
  }
  return date.format('YYYY-MM-DD');
};
