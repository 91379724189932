import { FileTextOutlined } from '@ant-design/icons';
import { exportLogManagement, fetchLogManagement } from '@root/services/log-management';
import { Button, Input, Pagination, Space, Table, Select, Col, Row, DatePicker } from 'antd';
import Title from 'antd/lib/typography/Title';
import { debounce } from 'lodash';
import moment from 'moment';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';

const COLUMNS = [
  {
    title: 'Date',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (text) => <p>{moment(text).format('DD-MM-YYYY HH:mm')}</p>,
  },
  {
    title: 'Action by',
    key: 'userName',
    dataIndex: 'userName',
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
  },
  {
    title: 'Object',
    key: 'entityType',
    dataIndex: 'entityType',
  },
  {
    title: 'Object ID',
    key: 'id',
    dataIndex: 'entityId',
    render: (text) => ( text ? <p>{text}</p>: '-'),
  },
  {
    title: 'Payload',
    key: 'payload',
    dataIndex: 'payload',
    width: 200,
    render: (text) => ( text ? <p style={{wordBreak: 'break-word'}}>{text}</p>: '-'),
  },
  {
    title: 'Result',
    key: 'result',
    dataIndex: 'result',
    width: 200,
    render: (text) => ( text ? <p style={{wordBreak: 'break-word'}}>{text}</p>: '-'),
  },
];

const { RangePicker } = DatePicker;

const LogManagement = () => {
  const csvLink = useRef()
  const [queries, setQueries] = useState({
    pageNumber: 1,
    pageSize: 10,
    query: '',
    searchBy: ''
  });
  const [ dates, setDates] = useState(null);
  const [ searchText, setSearchText] = useState('');
  const [ searchBy, setSearchBy] = useState(null);
  const [ exportLogs, setExportlogs] = useState([]);

  const disableDate = (current) => {
    let tooLate = current && current > moment().endOf('day')
    let tooEarly = false
    if (!!dates) {
      tooEarly = dates[1] && dates[1].diff(current, 'days') >= 89

      if (dates[0] && current.diff(dates[0], 'days') >= 90) {
        tooLate = dates[0] && current.diff(dates[0], 'days') >= 90
      }
    }
    return !!tooEarly || !!tooLate
  }

  const logSearchByOptions = [
    { label: 'Search by', value: null },
    { label: 'Date', value: 'createdAt'},
    { label: 'Action By', value: 'userName'},
    { label: 'Action', value: 'action'},
    { label: 'Object ID', value: 'entityId'},
    { label: 'Payload', value: 'payload'},
    { label: 'Result', value: 'result'},
  ];

  const { data: logManagement, isLoading } = useQuery(['log-management', queries], () => fetchLogManagement(queries));

  const onExportLogs = async() => {
    const logs = await exportLogManagement(searchText, searchBy)
    setExportlogs(logs)
    csvLink.current.link.click()
  }

  const handleSearch = (search) => {
    setSearchText(search)
  }

  const onSearch = () => {
    setQueries((prev) => ({ ...prev, query: searchText, searchBy, pageNumber: 1 }));
  }

  const handleDateRange = (e) => {
    if (e.length < 2 || !e[1] || !e[0]) return
    const startDate = e[0].format('YYYY-MM-DD')
    const endDate = e[1].format('YYYY-MM-DD')
    setSearchText(`${startDate}:${endDate}`)
  }

  const handleChangeSearchBy = (type) => {
    setSearchText('')
    setDates(null)
    setSearchBy(type)
  }

  return (
    <>
      <Title level={4}>Log Management</Title>
      <Row style={{ margin: '20px 0' }}>
        <Col span={8} style={{ maxWidth: '500px', display: 'grid', gridAutoFlow: 'column', gap: '4px'}}>
          <Select defaultValue={null} options={logSearchByOptions} onChange={handleChangeSearchBy}/>
          {
            searchBy === 'createdAt' ?
            <div style={{ position: 'relative'}}>
              <RangePicker
                showTime
                disabledDate={disableDate}
                format="DD/MM/YYYY"
                onCalendarChange={val => setDates(val)}
                allowClear={false}
                onChange={handleDateRange}
              />
              <p style={{ color: '#FE0000', position: 'absolute'}}>* สามารถค้นหาได้สูงสุด 90 วัน</p>
            </div>:
            <Input
              value={searchText}
              onChange={(event) => handleSearch(event.target.value)}
              placeholder="Type something to search..."
            />
          }
          <Button type="primary" onClick={onSearch}>
            Search
          </Button>
        </Col>
        <Col span={8} offset={8} style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
          <Button onClick={onExportLogs}>
            <Space size={10}>
              <FileTextOutlined />
              Export
            </Space>
          </Button>
          <CSVLink
            ref={csvLink}
            target="_blank"
            filename="log_management.csv"
            data={exportLogs}
            headers={COLUMNS.map((it) => ({ label: it.title, key: it.dataIndex }))}
            separator=","
          />
        </Col>
      </Row>
      <Row>
        <div>Found {logManagement?.total} records</div>
      </Row>
      <Table loading={isLoading} columns={COLUMNS} dataSource={logManagement?.items || []} pagination={false} />
      <Pagination
        onChange={(pageNumber, pageSize) => setQueries((prev) => ({ ...prev, pageNumber, pageSize }))}
        style={{ textAlign: 'end', margin: '30px 0' }}
        defaultCurrent={1}
        showSizeChanger
        pageSize={queries.pageSize}
        current={queries.pageNumber}
        total={logManagement?.total}
      />
    </>
  );
};

export default LogManagement;
