import styled from 'styled-components';

const HeaderBar = styled.header`
  width: 100%;
  height: 64px;
  background-color: #B6B5AF;
  .container {
    width: 1126px;
    height: auto;
    margin: 0 auto;
  }
  .logo {
    width: 110px;
    height: 32px;
    margin-top: 16px;
  }
`;

export default HeaderBar