import styled from 'styled-components';
import { device } from '@root/utils';

export default styled.div`
  .gutter-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .ant-col {
      margin: 0;
      max-width: 66%;

      @media ${device.tablet} {
        max-width: 99%;
      }
    }
  }
  .role {
    display: flex;
    justify-content: space-between;

    .gutter-col {
      width: 49%;

      & .ant-col {
        margin: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }
`;
