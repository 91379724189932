import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Space, Button, Select } from 'antd';
import { validateNotOnlySpaces } from '@root/utils/validator';

const { Option } = Select;

const EditUserForm = (props) => {
  const { data, onFinish, onCancel, isLoading, btnCancel } = props;
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      md: { span: 10, offset: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      md: { span: 10, offset: 6 },
    },
  };

  const onFormFinish = (values) => {
    if (data?.channel != 'Trinity') return;
    delete values.username;
    onFinish(values);
  };

  useEffect(() => {
    if (!data) return;

    form.setFieldsValue(data);
  }, [data?.id]);

  return (
    <Form form={form} onFinish={onFormFinish} scrollToFirstError layout="vertical" {...formItemLayout}>
      <Form.Item name="username" label="Username">
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          { required: true, message: 'Please input your first name!' },
          { max: 70, message: 'Maximum length is 70 characters' },
          { validator: validateNotOnlySpaces('Please input your first name!') },
          {
            pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
            message: 'The first name is not in a valid format.',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          { required: true, message: 'Please input your last name!' },
          { max: 70, message: 'Maximum length is 70 characters' },
          { validator: validateNotOnlySpaces('Please input your last name!') },
          {
            pattern: new RegExp(/^(?!\s)(?!.*\s$)[ก-๙a-zA-Z\d\s\-\_\(\)\[\]\.]+$/),
            message: 'The last name is not in a valid format.',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Status" name="isActive">
        <Select>
          <Option value={true}>Active</Option>
          <Option value={false}>Inactive</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Lock Status" name="isLocked">
        <Select>
          <Option value={true}>Locked</Option>
          <Option value={false}>Unlocked</Option>
        </Select>
      </Form.Item>

      <Form.Item wrapperCol={formItemLayout.wrapperCol}>
        <Row justify="end">
          <Space>
            {btnCancel && <Button onClick={onCancel}>Cancel</Button>}
            <Button htmlType="submit" type="primary" loading={isLoading} disabled={data?.channel != 'Trinity'}>
              Edit
            </Button>
          </Space>
        </Row>
      </Form.Item>
    </Form>
  );
};

EditUserForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, null]),
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  btnCancel: PropTypes.bool,
};

EditUserForm.defaultProps = {
  data: null,
  onFinish: () => {},
  onCancel: () => {},
  isLoading: false,
  btnCancel: true,
};

export default EditUserForm;
